import {
  fetchApplicationEventAttendance,
  fetchApplicationPage,
  fetchApplicationSponsor,
  fetchApplicationTimeslot,
  fetchApplicationTimeslots,
  fetchBalance,
  fetchGameMap,
  findSubEvents,
  matchEventOnId,
  matchLocationOnId,
  mutateApplicationEventAttendance,
  registerApplicationTimeslot,
  registerGroupApplicationTimeslot,
  TactileEvent,
  TactileEventGuestState,
  TactileSponsor,
  TactileVideo,
  unregisterApplicationTimeslot,
} from '@introcloud/api-client';
import { BlockData, DEFAULT_BLOCK_DATA } from '@introcloud/blocks-interface';
import { FetchMediaError } from 'fetch-media';
import merge from 'lodash.merge';
import { useCallback, useLayoutEffect, useRef } from 'react';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useIsMounted } from 'use-is-mounted';
import { NothingToFetch } from '../core/errors/NothingToFetch';
import { NotReady } from '../core/errors/NotReady';
import { queryClient } from '../core/QueryCache';
import { NotFound } from '../errors/NotFound';
import { useMutableMemoryValue } from '../storage';
import { SHOULD_DEBUG_FETCH } from '../utils';
import { useEndpoint, useSafeAuthorization } from './useAuthentication';
import { PreparedEvent, useEvents } from './useEvents';
import { useForceUpdateCount } from './useForceUpdate';
import { PreparedLocation, useLocations } from './useLocations';
import { SPONSOR_RANDOMIZER, useSponsors } from './useSponsors';
import { useVideos } from './useVideos';

export function useRandomizerRef() {
  const [randomizer, setRandomizer] = useMutableMemoryValue(SPONSOR_RANDOMIZER);
  const randomizerRef = useRef(randomizer);
  const isMounted = useIsMounted();
  const [version, forceUpdate] = useForceUpdateCount();

  useLayoutEffect(() => {
    randomizerRef.current = randomizer;
    SPONSOR_RANDOMIZER.hydrate().then(() => isMounted.current && forceUpdate());
  }, [randomizer]);

  return { randomizerRef, setRandomizer, version };
}

function useEventsRef({
  enabled = true,
  ...options
}: UseQueryOptions<
  readonly PreparedEvent[] | null,
  FetchMediaError | Error
> = {}) {
  const {
    data: events,
    reload: reloadEvents,
    error: eventsError,
    ...others
  } = useEvents({
    enabled,
    ...options,
  });

  const eventsRef = useRef(events);

  useLayoutEffect(() => {
    eventsRef.current = events;
  }, [events]);

  return { eventsRef, reloadEvents, eventsError, ...others };
}

function useLocationsRef({
  enabled = true,
  ...options
}: UseQueryOptions<
  readonly PreparedLocation[] | null,
  FetchMediaError | Error
> = {}) {
  const {
    data: locations,
    reload: reloadLocations,
    error: locationsError,
    ...others
  } = useLocations({
    enabled,
    ...options,
  });

  const locationsRef = useRef(locations);

  useLayoutEffect(() => {
    locationsRef.current = locations;
  }, [locations]);

  return { locationsRef, reloadLocations, locationsError, ...others };
}

function useVideosRef({
  enabled = true,
  ...options
}: UseQueryOptions<
  readonly Omit<TactileVideo, 'url'>[] | null | undefined,
  FetchMediaError | Error
> = {}) {
  const {
    data: videos,
    reload: reloadVideos,
    error: videosError,
    ...others
  } = useVideos({
    enabled,
    ...options,
  });

  const videosRef = useRef(videos);

  useLayoutEffect(() => {
    videosRef.current = videos;
  }, [videos]);

  return { videosRef, reloadVideos, videosError, ...others };
}

function useSponsorsRef({
  enabled = true,
  ...options
}: UseQueryOptions<
  readonly TactileSponsor[] | null | undefined,
  FetchMediaError | Error
> = {}) {
  const {
    data: sponsors,
    reload: reloadSponsors,
    error: sponsorsError,
    ...others
  } = useSponsors({
    enabled,
    ...options,
  });

  const sponsorsRef = useRef(sponsors);

  useLayoutEffect(() => {
    sponsorsRef.current = sponsors;
  }, [sponsors]);

  return { sponsorsRef, reloadSponsors, sponsorsError, ...others };
}

export function useGetEventById(
  events: readonly PreparedEvent[] | null | undefined,
  reloadEvents: UseQueryResult<
    readonly PreparedEvent[] | null,
    FetchMediaError | Error
  >['refetch']
) {
  return useCallback(
    async (id: string) => {
      if (!id) {
        throw new NothingToFetch();
      }

      if (__DEV__) {
        console.debug('[data] get event by id', id);
      }

      const eventsSource =
        !!events && events.length ? events : (await reloadEvents()).data;

      if (!eventsSource) {
        throw new NotFound(
          `Event ${id} not found, because eventsSource is empty`
        );
      }

      const match = matchEventOnId(id, eventsSource)[0];
      if (!match) {
        throw new NotFound(
          `Event ${id} not found, because eventsSource doesn't contain event`
        );
      }

      return match;
    },
    [events]
  );
}

export function useGetFirstVisibleEvent(
  events: readonly PreparedEvent[] | null | undefined
): () => Promise<TactileEvent> {
  const fetcher = () => events?.find((event) => event.hierarchy.showInCalendar);

  const { data, refetch } = useQuery(
    ['blocks', 'event', 'first-visible'],
    fetcher,
    { enabled: !!events }
  );

  return useCallback(
    () => refetch().then((response) => response.data! as TactileEvent),
    [refetch]
  );
}

export function useGetInfoById() {
  const authorization = useSafeAuthorization();
  const endpoint = useEndpoint();

  return useCallback(
    async (pageId: string | null | undefined) => {
      if (!pageId) {
        throw new NothingToFetch();
      }

      if (!authorization || !endpoint) {
        throw new NotReady();
      }

      if (__DEV__) {
        console.debug('[data] get info / page by id', pageId);
      }

      return await fetchApplicationPage(
        pageId,
        endpoint,
        authorization,
        undefined,
        SHOULD_DEBUG_FETCH
      );
    },
    [endpoint, authorization]
  );
}

export function useProvideBlockData({
  enabled = true,
}: { enabled?: boolean } = {}): BlockData {
  const authorization = useSafeAuthorization();
  const endpoint = useEndpoint();

  const { randomizerRef, setRandomizer } = useRandomizerRef();
  const { data: events, reload: reloadEvents } = useEvents({
    enabled,
    notifyOnChangeProps: ['data'],
  });
  const { locationsRef, reloadLocations } = useLocationsRef({
    enabled,
    notifyOnChangeProps: ['data'],
  });
  const { sponsorsRef } = useSponsorsRef({
    enabled,
    notifyOnChangeProps: ['data'],
  });
  const { videosRef, reloadVideos } = useVideosRef({
    enabled,
    notifyOnChangeProps: ['data'],
  });

  const getBalance = useGetBalance();

  const getEventById = useGetEventById(events, reloadEvents);
  const getFirstVisibleEvent = useGetFirstVisibleEvent(events);
  const getInfoById = useGetInfoById();

  const getSubEventsById = useCallback(
    async (id: string) => {
      if (!id) {
        throw new NothingToFetch();
      }

      if (__DEV__) {
        console.debug('[data] get sub-events of id', id);
      }

      const eventsSource =
        !!events && events.length ? events : (await reloadEvents()).data;
      const event = await getEventById(id);

      return findSubEvents(event._id, eventsSource!).sort((a, b) => {
        const leftStart = a.duration?.start?.unix || 0;
        const rightStart = b.duration?.start?.unix || 0;

        if (leftStart === rightStart) {
          const leftEnd = a.duration?.end?.unix || 0;
          const rightEnd = b.duration?.end?.unix || 0;

          return leftEnd.toString().localeCompare(rightEnd.toString());
        }

        return leftStart.toString().localeCompare(rightStart.toString());
      });
    },
    [getEventById, events]
  );

  const getImageUrl = useCallback(
    (
      imageId: string,
      targetSize:
        | 'icon_32'
        | 'icon_64'
        | 'icon_128'
        | 'icon_256'
        | 'icon_512'
        | 'icon_720'
        | 'icon_1440'
    ) => {
      if (!imageId || imageId.trim().length === 0) {
        return null;
      }

      return endpoint + `/image/${imageId}/${targetSize}`;
    },
    [endpoint]
  );

  const getLocationById = useCallback(
    async (id: string) => {
      if (!id) {
        throw new NothingToFetch();
      }

      if (__DEV__) {
        console.debug('[data] get location by id', id);
      }

      const locationsSource =
        locationsRef.current && locationsRef.current.length
          ? locationsRef.current
          : (await reloadLocations()).data;

      if (!locationsSource) {
        throw new NotFound(
          `Location ${id} not found because locationsSource is empty`
        );
      }

      const match = matchLocationOnId(id, locationsSource)[0];
      if (!match) {
        throw new NotFound(
          `Location ${id} not found because locationsSource doesn't contain that location`
        );
      }

      return match;
    },
    [!!locationsRef.current]
  );

  const getGameMapById = useCallback(
    async (gameMapId: string) => {
      if (!gameMapId) {
        throw new NothingToFetch();
      }

      if (!authorization || !endpoint) {
        throw new NotReady();
      }

      if (__DEV__) {
        console.debug('[data] get game map by id', gameMapId);
      }

      return await fetchGameMap(
        gameMapId,
        endpoint,
        authorization,
        undefined,
        SHOULD_DEBUG_FETCH
      );
    },
    [endpoint, authorization]
  );

  const getLocationEventsById = useCallback(
    async (id: string) => {
      if (!id) {
        throw new NothingToFetch();
      }

      if (__DEV__) {
        console.debug('[data] get location events of id', id);
      }

      const eventsSource = events;

      if (!eventsSource) {
        throw new NotFound(
          `Events of location ${id} not found because eventSource is empty`
        );
      }

      return eventsSource.filter((event) => {
        return (
          event.locationRef &&
          event.locationRef.some(
            (locationRef) => locationRef && locationRef.locationId === id
          )
        );
      });
    },
    [events]
  );

  const getTimeslots = useCallback(
    async (eventId: string | null | undefined) => {
      if (!eventId) {
        throw new NothingToFetch();
      }

      if (!authorization || !endpoint) {
        throw new NotReady();
      }

      if (__DEV__) {
        console.debug('[data] get time slots by id', eventId);
      }

      return await fetchApplicationTimeslots(
        eventId,
        endpoint,
        authorization,
        undefined,
        SHOULD_DEBUG_FETCH
      );
    },
    [endpoint, authorization]
  );

  const getTimeslotsContent = useCallback(
    async (timeslotId: string | null | undefined) => {
      if (!timeslotId) {
        throw new NothingToFetch();
      }

      if (!authorization || !endpoint) {
        throw new NotReady();
      }

      if (__DEV__) {
        console.debug('[data] get time slot by id', timeslotId);
      }

      return await fetchApplicationTimeslot(
        timeslotId,
        endpoint,
        authorization,
        undefined,
        SHOULD_DEBUG_FETCH
      );
    },
    [endpoint, authorization]
  );

  const getCheckinStatus = useCallback(
    async (eventId: string | null | undefined) => {
      if (!eventId) {
        throw new NothingToFetch();
      }

      if (!authorization || !endpoint) {
        throw new NotReady();
      }

      if (__DEV__) {
        console.debug('[data] get check-in status', eventId);
      }
      try {
        const evResult = await fetchApplicationEventAttendance(
          eventId,
          endpoint,
          authorization,
          undefined,
          SHOULD_DEBUG_FETCH
        );

        return evResult.module.attendance.state as TactileEventGuestState;
      } catch {
        return null;
      }
    },
    []
  );

  const setCheckinStatus = useCallback(
    async (
      eventId: string | null | undefined,
      state: 'go' | 'arrive' | 'leave'
    ) => {
      if (!eventId) {
        throw new NothingToFetch();
      }

      if (!authorization || !endpoint) {
        throw new NotReady();
      }

      if (__DEV__) {
        console.debug('[data] set check-in status', eventId, state);
      }

      const evChangeResult = await mutateApplicationEventAttendance(
        eventId,
        state,
        endpoint,
        authorization,
        undefined,
        SHOULD_DEBUG_FETCH
      );

      return evChangeResult.eventGuest.module.attendance
        .state as TactileEventGuestState;
    },
    []
  );

  const joinTimeslotRegistration = useCallback(
    async (timeslotId: string | undefined | null) => {
      if (!timeslotId) {
        throw new NothingToFetch();
      }

      if (!authorization || !endpoint) {
        throw new NotReady();
      }

      if (__DEV__) {
        console.debug('[data] set timeslot', timeslotId);
      }

      const joinResult = await registerApplicationTimeslot(
        timeslotId,
        endpoint,
        authorization,
        undefined,
        SHOULD_DEBUG_FETCH
      );

      await queryClient.invalidateQueries([endpoint, 'application', 'events']);

      return joinResult;
    },
    []
  );

  const joinTimeslotRegistrationAsGroup = useCallback(
    async ({
      timeslotId,
      groupId,
      excludedUsers,
    }: {
      timeslotId: string;
      groupId: string;
      excludedUsers: string[];
    }) => {
      if (!timeslotId || !groupId) {
        throw new NothingToFetch();
      }

      if (!authorization || !endpoint) {
        throw new NotReady();
      }

      if (__DEV__) {
        console.debug('[data] set timeslot for group', timeslotId, groupId);
      }

      const joinAsGroupResult = await registerGroupApplicationTimeslot(
        timeslotId,
        groupId,
        endpoint,
        authorization,
        excludedUsers,
        undefined,
        SHOULD_DEBUG_FETCH
      );

      await queryClient.invalidateQueries([endpoint, 'application', 'events']);

      return joinAsGroupResult;
    },
    []
  );

  const leaveTimeslotRegistration = useCallback(
    async (timeslotId: string | undefined | null) => {
      if (!timeslotId) {
        throw new NothingToFetch();
      }

      if (!authorization || !endpoint) {
        throw new NotReady();
      }

      if (__DEV__) {
        console.debug('[data] set timeslot', timeslotId);
      }

      const leaveResult = await unregisterApplicationTimeslot(
        timeslotId,
        endpoint,
        authorization,
        undefined,
        SHOULD_DEBUG_FETCH
      );

      await queryClient.invalidateQueries([endpoint, 'application', 'events']);

      return leaveResult;
    },
    []
  );

  const getSponsorById = useCallback(
    async (sponsorId: string | null | undefined) => {
      if (!sponsorId) {
        throw new NothingToFetch();
      }

      if (!authorization || !endpoint) {
        throw new NotReady();
      }

      if (__DEV__) {
        console.debug('[data] get sponsor by id', sponsorId);
      }

      return await fetchApplicationSponsor(
        sponsorId,
        endpoint,
        authorization,
        undefined,
        SHOULD_DEBUG_FETCH
      );
    },
    [endpoint, authorization]
  );

  const getSponsor = useCallback(
    async (kind: 'splash' | 'news' | 'event' | 'page' | null | undefined) => {
      if (!kind) {
        throw new NothingToFetch();
      }

      if (!authorization || !endpoint) {
        throw new NotReady();
      }

      if (__DEV__) {
        console.debug('[data] get sponsor of kind', kind);
      }

      // TODO cache this
      const sponsorOptions = sponsorsRef.current;

      if (!sponsorOptions || sponsorOptions.length === 0) {
        throw new NotFound('No sponsors to show');
      }

      const kindly = sponsorOptions.filter(
        (sponsor) => sponsor.kind === kind && sponsor.weight !== 0
      );
      const length = kindly.reduce(
        (result, sponsor) => result + sponsor.weight,
        0
      );
      if (length === 0) {
        throw new NotFound('No sponsors to show');
      }

      const splashPrevious = randomizerRef.current
        ? randomizerRef.current[kind]
        : undefined;

      const previous =
        typeof splashPrevious === 'undefined'
          ? Math.floor(Math.random() * length)
          : splashPrevious;
      const now = (previous + 1) % length;

      setRandomizer((prev) => merge({}, prev || {}, { [kind]: now }));

      const items: TactileSponsor[] = [];
      kindly.forEach((o) => {
        items.push(...Array(o.weight).fill(o));
      });

      const item = items[now];
      if (item) {
        return item;
      }

      throw new Error('Something went wrong grabbing a sponsor');
    },
    [endpoint, authorization, !!sponsorsRef.current, setRandomizer]
  );

  const getVideoPreviewById = useCallback(
    async (id: string) => {
      if (!id) {
        throw new NothingToFetch();
      }

      if (__DEV__) {
        console.debug('[data] get video preview by id', id);
      }

      const videosSource =
        videosRef.current && videosRef.current.length
          ? videosRef.current
          : (await reloadVideos()).data;

      if (!videosSource) {
        throw new NotFound(
          `Video ${id} not found because videoSource is empty`
        );
      }

      const match = videosSource?.find((video) => video._id === id);
      if (!match) {
        throw new NotFound(
          `Video ${id} not found because videoSource doesn't contain that video`
        );
      }

      return match;
    },
    [!!videosRef.current]
  );
  return {
    ...DEFAULT_BLOCK_DATA,
    getEventById,
    getLocationEventsById,
    getInfoById,
    getLocationById,
    getSubEventsById,
    getImageUrl,
    getTimeslots,
    getCheckinStatus,
    setCheckinStatus,
    getTimeslotsContent,
    joinTimeslotRegistration,
    joinTimeslotRegistrationAsGroup,
    leaveTimeslotRegistration,
    getSponsor,
    getSponsorById,
    getVideoPreviewById,
    getFirstVisibleEvent,
    getGameMapById,
    getBalance,
  };
}

function useGetBalance() {
  const authorization = useSafeAuthorization();
  const endpoint = useEndpoint();
  const refetch = useQuery(
    [authorization, 'app', 'balance'],
    () => fetchBalance(endpoint, authorization || ''),
    {
      notifyOnChangeProps: ['refetch'],
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled: Boolean(endpoint) && Boolean(authorization),
    }
  ).refetch;

  return useCallback(
    () =>
      refetch().then((result) => {
        if (!result.data) {
          throw result.error;
        }

        return result.data!;
      }),
    [refetch]
  );
}
