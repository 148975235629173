import { useCurrentDomain } from '../hooks/useAuthentication';
import { useRemoteCompany } from '../hooks/useCompany';

export function useCurrencyDisplay(value: number | null): {
  display: string;
  tokens: boolean;
  symbol: string;
} {
  const currencyDisplayOptions = useCurrencyDisplayOptions();

  if (value === null) {
    return {
      display: ' - ',
      tokens: currencyDisplayOptions.tokens,
      symbol: currencyDisplayOptions.symbol,
    };
  }

  value = value / 100;

  if (!currencyDisplayOptions.tokens) {
    return {
      display: value.toFixed(2),
      tokens: false,
      symbol: currencyDisplayOptions.symbol,
    };
  }

  const amountValueExchanged = value / currencyDisplayOptions.rate;
  const amountValueOneOrZeroDecimals =
    amountValueExchanged % 1 === 0
      ? amountValueExchanged
      : amountValueExchanged.toFixed(1);
  return {
    symbol: currencyDisplayOptions.symbol,
    tokens: true,
    display: String(amountValueOneOrZeroDecimals),
  };
}

export function useCurrencyDisplayOptions() {
  const { data: companyData } = useRemoteCompany(useCurrentDomain());
  if (!companyData) {
    return { tokens: false, symbol: '', rate: 1 };
  }

  const { currencySymbol, currencyExchangeRate } = companyData?.settings
    .payment || { currencySymbol: '€', currencyExchangeRate: 1 };
  const currencyDefault = currencySymbol === '€';

  return {
    tokens: !currencyDefault,
    symbol: currencySymbol,
    rate: currencyExchangeRate,
  };
}
