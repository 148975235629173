import { useLayoutEffect, useRef } from 'react';
import { SharedValue } from 'react-native-reanimated';

export function ScrollableGrid({
  children,
  x,
}: {
  children: React.ReactNode;
  x: SharedValue<number>;
}) {
  const scrollRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      scrollRef.current?.scrollTo({ left: -x.value, behavior: 'smooth' });
    }, 1000 * 0.5);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div
      ref={scrollRef}
      style={{
        overflow: 'scroll',
        position: 'relative',
        flex: 1,
        width: '100%',
        maxWidth: '100%',
      }}
    >
      {children}
    </div>
  );
}

export function useScrollableContext() {
  return { translateX: undefined, translateY: undefined };
}
