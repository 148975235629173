import { useQuery } from 'react-query';
import { useChatProfileFetcher } from '../hooks/useChatProfileFetcher';

export function useChatUserProfile(user: undefined | null | { id: string }) {
  const infoFetcher = useChatProfileFetcher('user');
  const { data: profile } = useQuery(
    ['chat', 'user', user?.id, 'profile'],
    ({ signal }) => infoFetcher(user!.id, { signal }),
    {
      enabled: !!user?.id,
      staleTime: 15 * 60 * 1000,
    }
  );

  return profile;
}
