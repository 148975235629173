import { defineTranslations } from '@introcloud/blocks';
import React from 'react';

import { PaymentScreen } from './PaymentScreen';

defineTranslations({
  en: {
    app: {
      payment: {
        title: 'Your balance',
        'top-up': {
          title: 'Top-up',
          description: 'Remaining credit will be refunded automatically.',
          label: 'Select an amount',
          change: 'Different method',
          service_fee_percentage: '+{{percentage}}% service',
          service_fee_flat:
            'Service costs of €{{fee}} will be added to the total.',
        },
        transfer: {
          title: 'Transfer (send)',
          label: 'Amount to send',
          confirm: 'Press the send button to transfer {{amount}}',
          success: 'Transferred {{amount}}',
          error: 'Did not transfer {{amount}}: {{error}}',
        },
        showing: {
          title: 'Show to receive',
        },
        scanning: {
          title: 'Scan to share',
        },
        transactions: {
          title: 'Transactions',
        },
        wallet: {
          title: 'Your wallet',
          last_transaction_title: 'Last transaction',
        },
        share: {
          title: 'Share',
          share_via_qr_description:
            'Share cash easily with your personal QR code',
          share_via_group_description: 'Share cash easily within your group',
        },
        actions: {
          'top-up': 'Top-up',
          transfer: 'Send',
          close: 'Close',
          negative_action: 'Cancel',
          transactions: 'More transactions',
          other_top_up: 'Other',
          share_via_qr: 'Scan & Share',
          share_via_group: 'Group sharing',
        },
      },
    },
  },

  nl: {
    app: {
      payment: {
        title: 'Jouw balans',
        'top-up': {
          title: 'Bijvullen',
          description: 'Overgebleven tegoed wordt automatisch teruggestort.',
          label: 'Kies een waarde',
          change: 'Andere manier',
          service_fee_percentage: '+{{percentage}}% service',
          service_fee_flat:
            'Servicekosten van €{{fee}} worden toegevoegd aan het totaal.',
        },
        transfer: {
          title: 'Overdragen (geef)',
          label: 'Hoeveelheid om te geven',
          confirm: 'Klik nogmaals om {{amount}} over te dragen',
          success: 'Er is {{amount}} overgedragen',
          error: '{{amount}} is niet overgedragen: {{error}}',
        },
        showing: {
          title: 'Toon en ontvang',
        },
        scanning: {
          title: 'Scan om te delen',
        },
        transactions: {
          title: 'Transacties',
        },
        wallet: {
          title: 'Jouw balans',
          last_transaction_title: 'Laatste transactie',
        },
        share: {
          title: 'Delen',
          share_via_qr_description:
            'Deel snel geld met je persoonlijke QR-code',
          share_via_group_description: 'Deel onderling geld binnen je groep',
        },
        actions: {
          'top-up': 'Bijvullen',
          transfer: 'Draag over',
          close: 'Sluit',
          negative_action: 'Annuleer',
          transactions: 'Bekijk meer',
          other_top_up: 'Anders',
          share_via_qr: 'Scan & Deel',
          share_via_group: 'Groep delen',
        },
      },
    },
  },
});

export function PaymentTab() {
  return <PaymentScreen asTab />;
}
