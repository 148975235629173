import { submitUserImage } from '@introcloud/api-client';
import { PrimaryButton, i18n, useLocale } from '@introcloud/blocks';
import { useBlockImageUrl } from '@introcloud/blocks-interface';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { useCallback, useReducer, useState } from 'react';
import { Image, View } from 'react-native';
import {
  Card,
  Dialog,
  FAB,
  MD2Theme,
  Portal,
  Text,
  Title,
  useTheme,
} from 'react-native-paper';
import { useIsColorDark } from 'use-color-luminance';
import { useIsMounted } from 'use-is-mounted';
import { FileDialogContent } from '../goals/FileDialogContent';
import { useEndpoint, useSafeAuthorization } from '../hooks/useAuthentication';
import { useCompany } from '../hooks/useCompany';
import { useUser } from '../hooks/useUser';
import { SHOULD_DEBUG_FETCH } from '../utils';
import { useTab } from '../hooks/useTab';
import { StackNavigationProp } from '@react-navigation/stack';
import { RouteParamList } from '../core/Routes';

export function ProfileHeader() {
  useLocale();

  const enabled = useIsFocused();
  const endpoint = useEndpoint();
  const authorization = useSafeAuthorization();

  const company = useCompany({ enabled });
  const { data: user, isLoading, reload } = useUser({ enabled });
  const { data: url } = useBlockImageUrl(
    user?.image.profile || null,
    'icon_256'
  );

  const {
    colors: { accent },
  } = useTheme<MD2Theme>();
  const accentText = useIsColorDark(accent) ? '#FFF' : '#000';

  const isMountedRef = useIsMounted();
  const [submitting, setIsSubmitting] = useState(false);
  const [lastError, setLastError] = useState<Error | null>(null);
  const [editing, toggleEditImage] = useReducer((prev) => !prev, false);

  const submit = useCallback(
    (data: FormData) => {
      if (!user?._id || !authorization) {
        return;
      }

      setIsSubmitting(true);
      setLastError(null);

      if (typeof data.has === 'undefined') {
        data.has = (key: string) => key === 'filepond';
      }

      submitUserImage(
        user?._id,
        data,
        endpoint,
        authorization,
        undefined,
        SHOULD_DEBUG_FETCH
      )
        .then(() => {
          if (!isMountedRef.current) {
            return;
          }

          setIsSubmitting(false);
          toggleEditImage();
          reload();
        })
        .catch((err: Error) => {
          console.error(err);
          if (!isMountedRef.current) {
            return;
          }

          setIsSubmitting(false);
          setLastError(err);
        });
    },
    [user, endpoint, authorization]
  );

  return (
    <View
      style={{
        width: '100%',
        paddingVertical: 16,
        minHeight: 260,
        alignItems: 'center',
      }}
    >
      <Card
        style={{
          width: 200,
          height: 200,
          borderRadius: 100,
          position: 'relative',
        }}
        onPress={editing ? undefined : toggleEditImage}
      >
        <View
          style={{
            width: 200,
            height: 200,
            borderRadius: 100,
            overflow: 'hidden',
          }}
        >
          <Image
            source={{
              uri:
                url ||
                'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
              width: 256,
              height: 256,
            }}
            style={{
              width: 200,
              height: 200,
              borderRadius: 100,
            }}
            resizeMode="cover"
          />
        </View>
        <FAB
          style={{ position: 'absolute', right: 0, bottom: 0 }}
          icon="pencil-outline"
          color={accentText}
          onPress={toggleEditImage}
          accessibilityHint="Edit image"
          disabled={editing}
        />
      </Card>

      <Title>{user?.name.full ?? ''}</Title>
      <Text>{user?.email.value ?? ''}</Text>

      {company?.settings.profileShowPayment ? <PaymentButton /> : null}

      <Portal>
        <Dialog
          visible={editing}
          onDismiss={toggleEditImage}
          style={{
            maxWidth: 720,
            alignSelf: 'center',
            minWidth: 250,
            overflow: 'hidden',
          }}
        >
          <Dialog.Content>
            <FileDialogContent
              formDataKey="filepond"
              kind="image"
              loading={submitting || isLoading}
              onDismiss={
                editing
                  ? () => {
                      toggleEditImage();
                    }
                  : () => {}
              }
              onSubmit={(data: string | File | Blob | FormData) => {
                submit(data as FormData);
              }}
              error={lastError}
            />
          </Dialog.Content>
        </Dialog>
      </Portal>
    </View>
  );
}

function PaymentButton() {
  useLocale();

  const { navigate } = useNavigation<StackNavigationProp<RouteParamList>>();
  const tab = useTab('payment');
  const icon =
    ((!tab?.icon.family || tab?.icon.family === 'MaterialCommunityIcons') &&
      (tab?.icon.name as 'account-cash-outline')) ||
    'account-cash-outline';

  const title = i18n.t('app.actions.goto_balance');
  const description = i18n.t('app.profile.account.payment.description');

  return (
    <View
      style={{ marginTop: 12, alignItems: 'center', justifyContent: 'center' }}
    >
      <PrimaryButton
        icon={icon}
        onPress={() => navigate('Payment')}
        accessibilityHint={description}
      >
        {title}
      </PrimaryButton>
    </View>
  );
}
