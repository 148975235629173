import {
  PrimaryButton,
  TextButton,
  localeSmartTimeString,
} from '@introcloud/blocks';
import { FlashList, ListRenderItemInfo } from '@shopify/flash-list';
import * as ExpoApplication from 'expo-application';
import { setStringAsync } from 'expo-clipboard';
import Constants from 'expo-constants';
import * as Device from 'expo-device';
import React, {
  Fragment,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Platform, ScrollView, View } from 'react-native';
import {
  Appbar,
  DataTable,
  Dialog,
  MD2Theme,
  Paragraph,
  Portal,
  useTheme,
} from 'react-native-paper';
import { localeTimeString } from 'react-native-time-helpers';
import {
  DEFAULT_LOCALE,
  DOMAIN_INTROCLOUD,
  DOMAIN_TACTILE,
  DOMAIN_WHITELABEL,
} from '../config';
import { BlockProvision } from '../core/BlockProvision';
import { ThemeProvider } from '../core/FixedThemeProvider';
import { Header } from '../core/Header';
import {
  BLOCK_CUSTOM_EMBED,
  BLOCK_GELEGRAAF,
  BLOCK_INFO_MARKET,
  BLOCK_RIJKSMUSEUM,
  BLOCK_ZOO_AUDIO_TOUR,
  CHAT_BUTTONS_ENABLED,
  EXPERIENCE_ENABLED,
  GELEGRAAF_ENABLED,
  INITIAL_MAP_FILTER,
  MAP_SWIPER_ENABLED,
  MULTI_COMPANY_ENABLED,
  PAGE_COLORS_ENABLED,
  TAG_OVERRIDES_ENABLED,
} from '../features';
import { useAuthentication, useEndpoint } from '../hooks/useAuthentication';
import { useCompany } from '../hooks/useCompany';
import { PreparedEvent, useEvents } from '../hooks/useEvents';
import { useUser } from '../hooks/useUser';
import { ProfileAccount } from './ProfileAccount';
import { ProfileCommunication } from './ProfileCommunication';
import { ProfileHeader } from './ProfileHeader';
import { ProfileLocker } from './ProfileLocker';
import { ProfileMatching } from './ProfileMatching';
import { ProfileSessions } from './ProfileSessions';
import { ProfileTicket } from './ProfileTicket';

type ProfileData =
  | ProfileHeaderData
  | ProfileQRData
  | ProfileLockerData
  | ProfileCommunicationData
  | ProfileSessionsData
  | ProfileAccountData
  | ProfileMatchingData;

type ProfileHeaderData = { type: 'header' };
type ProfileSessionsData = { type: 'sessions' };
type ProfileQRData = { type: 'qr' };
type ProfileLockerData = { type: 'locker' };
type ProfileCommunicationData = { type: 'communication' };
type ProfileAccountData = { type: 'account' };
type ProfileMatchingData = { type: 'matching' };

export function ProfileScreen({ asTab }: { asTab?: boolean }) {
  // Allow pushing through any "memo/blocked" updates
  const theme = useTheme<MD2Theme>();
  const company = useCompany();

  const [debugDialogActive, setDebugDialogActive] = useState(false);
  const showDebugDialog = useCallback(() => setDebugDialogActive(true), []);
  const hideDebugDialog = useCallback(() => setDebugDialogActive(false), []);

  const [data, setData] = useState<ProfileData[]>([]);

  const listRef = useRef<FlashList<ProfileData>>(null);

  useEffect(() => {
    listRef.current?.prepareForLayoutAnimationRender();

    const nextData = [
      { type: 'header' } satisfies ProfileHeaderData,
      company?.settings.profileShowQr
        ? ({ type: 'qr' } satisfies ProfileQRData)
        : null,
      { type: 'locker' } satisfies ProfileLockerData,
      { type: 'communication' } satisfies ProfileCommunicationData,
      MULTI_COMPANY_ENABLED ? { type: 'sessions' } : null,
      company?.settings.profileShowMatching ? { type: 'matching' } : null,
      { type: 'account' } satisfies ProfileAccountData,
    ].filter((item): item is ProfileData => Boolean(item));

    setData(nextData);
  }, [company]);

  const { data: events } = useEvents();

  return (
    <BlockProvision screen="ProfileScreen">
      <ThemeProvider theme={theme}>
        <View
          style={{
            flex: 1,
            overflow: 'hidden',
            maxHeight: Platform.select({ web: '100vh', default: '100%' }),
          }}
        >
          <Header
            backFallback={{ screen: 'Tabs', params: { screen: 'Home' } }}
            hideProfile
            hideBack={asTab}
            title="Profile"
            subTitle={undefined}
            showTranslate
            colorOverride={theme.colors.primary}
          >
            <Appbar.Action
              icon="bug"
              accessibilityLabel="See debug information"
              onPress={() => showDebugDialog()}
            />
          </Header>
          <FlashList
            ref={listRef}
            nativeID="scroller"
            contentContainerStyle={{
              paddingBottom: 56,
            }}
            estimatedItemSize={212}
            data={data}
            getItemType={getItemType}
            renderItem={renderItem}
            keyExtractor={keyExtractor}
          />
        </View>
        <Portal>
          <Dialog
            visible={debugDialogActive}
            onDismiss={hideDebugDialog}
            style={{ alignSelf: 'center', maxWidth: 450, width: '100%' }}
          >
            <DebugDialogContent onDismiss={hideDebugDialog} events={events} />
          </Dialog>
        </Portal>
      </ThemeProvider>
    </BlockProvision>
  );
}

function keyExtractor({ type }: { type: string }) {
  return type;
}

function getItemType({ type }: { type: string }) {
  return keyExtractor({ type });
}

function renderItem({ item }: ListRenderItemInfo<ProfileData>) {
  return (
    <ItemWrapper>
      <Item item={item} />
    </ItemWrapper>
  );
}

function ItemWrapper({ children }: PropsWithChildren<object>) {
  return (
    <View
      style={{
        maxWidth: 720,
        alignSelf: 'center',
        width: '100%',
        overflow: 'hidden',
        paddingHorizontal: 16,
      }}
    >
      {children}
    </View>
  );
}

function Item({ item }: Pick<ListRenderItemInfo<ProfileData>, 'item'>) {
  switch (item.type) {
    case 'header': {
      return <ProfileHeader />;
    }
    case 'qr': {
      return <ProfileTicket />;
    }
    case 'locker': {
      return <ProfileLocker />;
    }
    case 'communication': {
      return <ProfileCommunication />;
    }
    case 'sessions': {
      return <ProfileSessions />;
    }
    case 'matching': {
      return <ProfileMatching />;
    }
    case 'account': {
      return <ProfileAccount />;
    }
  }
  return null;
}

function DebugDialogContent({
  onDismiss,
  events,
}: {
  onDismiss: () => void;
  events: readonly PreparedEvent[] | null | undefined;
}) {
  const { data: user } = useUser();
  const { authentication } = useAuthentication();
  const domain = useEndpoint();
  const [copied, setCopied] = useState(false);

  const event = events ? events[events.length / 2] ?? events[0] : undefined;

  const information = useMemo((): [string, string][] => {
    return [
      ['Device', Device.isDevice ? 'Yes' : 'No'],
      ['Device name', Device.deviceName || 'Unknown'],
      ['Device class', String(Device.deviceYearClass || '-')],
      ['Runtime version', Constants.expoRuntimeVersion || '-'],
      ['Expo version', Constants.expoVersion || '-'],
      ['App version', ExpoApplication.nativeApplicationVersion || '-'],
      ['App build', ExpoApplication.nativeBuildVersion || '-'],
      ['App owner', Constants.appOwnership || '-'],
      ['Platform', Platform.OS || '-'],
      ['Platform model', Device.modelName || '-'],
      ['Platform version', String(Platform.Version || '-')],
      ['System version', String(Constants.systemVersion || '-')],
      ['', ''],

      ['IntroCloud locale', DEFAULT_LOCALE],
      ['IntroCloud domain', DOMAIN_TACTILE || DOMAIN_INTROCLOUD || 'none'],
      [
        'Whitelabel domain',
        DOMAIN_WHITELABEL || DOMAIN_TACTILE || DOMAIN_INTROCLOUD || 'none',
      ],
      ['Initial map filter', INITIAL_MAP_FILTER || '<none>'],
      ['Custom Newspaper', GELEGRAAF_ENABLED ? 'yes' : 'no'],
      ['Custom Map', MAP_SWIPER_ENABLED ? 'yes' : 'no'],
      ['Custom Page colors', PAGE_COLORS_ENABLED ? 'yes' : 'no'],
      ['Custom Tag names', TAG_OVERRIDES_ENABLED ? 'yes' : 'no'],
      ['Custom Experience', EXPERIENCE_ENABLED ? 'yes' : 'no'],
      ['Extra Chat buttons', CHAT_BUTTONS_ENABLED ? 'yes' : 'no'],
      ['Block newspaper', BLOCK_GELEGRAAF ? 'yes' : 'no'],
      ['Block info market', BLOCK_INFO_MARKET ? 'yes' : 'no'],
      ['Block audio tour', BLOCK_ZOO_AUDIO_TOUR ? 'yes' : 'no'],
      ['Block custom embed', BLOCK_CUSTOM_EMBED ? 'yes' : 'no'],
      ['Block rijksmuseum', BLOCK_RIJKSMUSEUM ? 'yes' : 'no'],
      ['', ''],
      ['Authenticated user', user?.email?.value || '-'],
      [
        'Authenticated domain',
        authentication?.domainFull?.replace('https://', '') || '-',
      ],
      [
        'Active domain',
        domain?.replace('https://', '')?.replace('/api', '') || '-',
      ],
      ['', ''],
      ['Timestamp', String(new Date().getTime())],
      ['Time system locale', new Date().toLocaleString()],
      ['Time app locale', localeTimeString(new Date())],
      [
        'Event time',
        event
          ? event.name.full + ' starts at ' + event.duration.start.unix
          : '-',
      ],
      [
        'Event time in app locale',
        event
          ? localeSmartTimeString(
              new Date(event.duration.start.unix),
              new Date().getTime()
            )
          : '-',
      ],
    ];
  }, [user, authentication?.domainFull, domain]);

  const copyToClipboard = useCallback(() => {
    const leftSize = Math.max(...information.map((row) => row[0]?.length || 0));

    setStringAsync(
      information
        .map((row) =>
          row[0] === '' ? '' : `${row[0].padEnd(leftSize, ' ')}\t\t${row[1]}`
        )
        .join('\n')
    ).catch(() => {});

    setCopied(true);
  }, [information]);
  return (
    <Fragment>
      <Dialog.Title>Device and App information</Dialog.Title>
      <Dialog.Content>
        <Paragraph>
          In order to resolve any issues encountered, there is information that
          will really help us track down the root cause. Please copy this
          information and provide it when you report any issues.
        </Paragraph>
      </Dialog.Content>
      <Dialog.ScrollArea style={{ paddingHorizontal: 0 }}>
        <ScrollView style={{ maxHeight: 300 }}>
          <DataTable>
            {information.map((row, index, self) => (
              <DataTable.Row key={index}>
                {row.map((cell, i) => (
                  <DataTable.Cell
                    numeric={i > 0}
                    key={i}
                    style={{
                      flexShrink: i === 1 ? 0 : 1,
                      paddingHorizontal: 10,
                    }}
                  >
                    {cell}
                  </DataTable.Cell>
                ))}
              </DataTable.Row>
            ))}
          </DataTable>
        </ScrollView>
      </Dialog.ScrollArea>
      <Dialog.Actions
        style={{ paddingHorizontal: 16, paddingBottom: 12, paddingTop: 12 }}
      >
        <PrimaryButton
          icon={copied ? 'check' : 'content-copy'}
          onPress={copyToClipboard}
          style={{ marginRight: 'auto' }}
        >
          {copied ? 'Copied' : 'Copy to clipboard'}
        </PrimaryButton>
        <TextButton icon="close" onPress={onDismiss}>
          Close
        </TextButton>
      </Dialog.Actions>
    </Fragment>
  );
}
