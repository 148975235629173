import {
  fetchApplication,
  TactileCompany,
  TactileCompanyStoreSubmission,
} from '@introcloud/api-client';
import { CURRENT_LOCALE } from '@introcloud/blocks';
import { DEFAULT_TABS } from '@introcloud/tabs';
import { FetchMediaError } from 'fetch-media';
import merge from 'lodash.merge';
import {
  useCallback,
  useDebugValue,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { MD2LightTheme as DefaultTheme, MD2Theme } from 'react-native-paper';
import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { v4 } from 'uuid';
import {
  COLOR_ANDROID_ICON,
  COLOR_PRIMARY,
  COLOR_SPLASH,
  DOMAIN_TACTILE,
  EXPO_NAME,
  EXPO_SLUG,
  SEED_COMPANY,
  SPLASH_RESIZE_MODE,
} from '../config';
import { DarkTheme } from '../core/FixedThemeProvider';
import { MULTI_COMPANY_ENABLED } from '../features';
import { resetLocale } from '../localize';
import { StoredMemoryValue, useMutableMemoryValue } from '../storage';
import { SHOULD_DEBUG_FETCH } from '../utils';
import { useCurrentDomain, useEndpoint } from './useAuthentication';
export const COMPANY = new StoredMemoryValue<TactileCompany>(
  'application.company.v3'
);

type Theme = MD2Theme;

declare type Mutable<T extends Record<string, unknown>> = {
  -readonly [K in keyof T]: T[K] extends Record<string, any>
    ? Mutable<T[K]>
    : T[K];
};

export type LocalCompany = Mutable<
  Omit<TactileCompany, 'application'> & {
    application: Omit<TactileCompany['application'], 'themes'> & {
      themes: Omit<
        TactileCompany['application']['themes'],
        'light' | 'dark'
      > & {
        light: Theme;
        dark: Theme;
      };
    };
  }
>;

const DEFAULT_COMPANY: LocalCompany = {
  application: {
    splash: {
      backgroundColor: COLOR_SPLASH!,
      resizeMode: SPLASH_RESIZE_MODE as 'contain',
      imageId: null,
      image: null,
    },
    map: {
      center: {
        latitude: 0,
        longitude: 0,
      },
    },
    store: {} as TactileCompanyStoreSubmission,
    themes: {
      default: 'light',
      allowSwitching: false,
      light: {
        ...DefaultTheme,
        colors: {
          ...DefaultTheme.colors,
          primary: COLOR_PRIMARY || DefaultTheme.colors.primary,
        },
      },
      dark: {
        ...DarkTheme,
        colors: {
          ...DarkTheme.colors,
          primary: COLOR_PRIMARY || DarkTheme.colors.primary,
        },
      },
    },
    standalone: {
      ios: { icon: null },
      android: { icon: null },
      adaptive: {
        foregroundImage: null,
        backgroundColor: COLOR_ANDROID_ICON || null,
      },
      notification: {
        icon: null,
      },
    },
    advertisements: {
      defaultRatio: {
        x: '16',
        y: '9',
      },
    },
    events: {
      type: 'description-focus',
      tagsEnabled: true,
      imageEnabled: true,
      lines: 3,
    },
    tabs: {
      neutral: true,
      fallback: null,
      configuration: {
        'events-grid': {
          duration: {
            start: { unix: 0 },
            end: { unix: 1 },
            step: { hours: 1, minutes: 60, seconds: 3600 },
          },
          images: {},
          showDayTabs: false,
          useEventColours: false,
          useLocationColours: false,
          useLocationImages: false,
        },
        information: {
          destination: {
            kind: 'info',
            value: null,
          },
        },
        'event-days': {
          duration: {
            start: {
              unix: 0,
            },
            end: {
              unix: 0,
            },
          },
          images: {},
        },
        custom: {
          destination: {
            kind: 'external',
            value: null,
          },
          passAuth: false,
          immersive: false,
        },
      },
      values: DEFAULT_TABS.map((tab) => ({
        ...tab,
        _id: v4(),
      })) as any,
    },
  },
  settings: {
    application: {
      userCanAccess: null,
      googlePackageId: '',
      appleBundleId: '',
      webApp: '',
    },
    localization: [],
    pubnub: {
      active: null,
    },
    chatMessageNotification: false,
    payment: {
      amount: [],
      amountDefault: {
        euro: 0,
        cents: 0,
      },
      fee: {
        euro: 0,
        cents: 0,
      },
      feeTicket: {
        euro: 0,
        cents: 0,
      },
      currencySymbol: '',
      currencyExchangeRate: 1,
    },
    profileShowChat: false,
    profileShowGroup: false,
    profileShowMatching: false,
    profileShowPayment: false,
    userCanEditMobile: false,
    profileShowQr: false,
  },
  name: {
    full: EXPO_NAME || '',
    abbr: EXPO_NAME || '',
    id:
      (DOMAIN_TACTILE || '')
        .replace('app.', '')
        .replace('.tactile.events', '') || EXPO_SLUG,
  },
  image: {
    banner: null,
    profile: null,
  },
};

function useCompanyPlaceholder(domain: string) {
  const [placeHolder, setPlaceholder] = useState(() => COMPANY.current);

  useEffect(() => {
    let mounted = true;

    if (MULTI_COMPANY_ENABLED) {
      return;
    }

    try {
      const seed =
        typeof SEED_COMPANY === 'string'
          ? JSON.parse(SEED_COMPANY as unknown as string)
          : SEED_COMPANY;

      if (!mounted || !seed || Object.keys(seed).length === 0) {
        return;
      }

      setPlaceholder((prev) => prev ?? seed);
    } catch {}

    return () => {
      mounted = false;
    };
  }, []);

  if (!placeHolder?.name?.id) {
    return undefined;
  }

  if (!domain.includes(placeHolder.name.id)) {
    return undefined;
  }

  return placeHolder;
}

let lastUpdate: string | null = null;

export function useCompany({
  enabled = true,
  cacheTime = 1000 * 60,
  onSuccess,
  ...options
}: UseQueryOptions<TactileCompany, FetchMediaError> = {}):
  | LocalCompany
  | undefined
  | null {
  const domain = useCurrentDomain();
  const endpoint = useEndpoint();

  useDebugValue(domain);

  const {
    data: remoteCompany,
    error,
    isLoading,
  } = useQuery<TactileCompany, FetchMediaError>(
    ['api', 'company', domain],
    useCallback(
      ({ signal }) => {
        console.log('[useCompany] fetching', domain);
        // console.log({ endpoint, domain });
        // console.log('useCompany', endpoint, domain);
        return fetchCompany(endpoint, { signal });
      },
      [endpoint, domain]
    ),
    {
      enabled:
        Boolean(endpoint) && enabled && domain !== 'https://api.tactile.events',
      placeholderData: useCompanyPlaceholder(domain),
      staleTime: cacheTime,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      // isDataEqual: isEqual,
      notifyOnChangeProps: ['data', 'error', 'isLoading'],
      onSuccess: useCallback(async (result: TactileCompany) => {
        if (result && domain !== 'https://api.tactile.events') {
          // TODO: cleanup- this runs per useCompany hook, and not once
          if (lastUpdate === null || lastUpdate !== result.name.id) {
            lastUpdate = result.name.id;

            if (
              result.settings &&
              result.settings.localization &&
              result.settings.localization.length > 0
            ) {
              const nextLocale = resetLocale(result.settings.localization);
              await CURRENT_LOCALE.emit(nextLocale);

              console.log('[onSuccess] useCompany', {
                domain,
                allowed: result.settings.localization,
                next: nextLocale,
              });
            } else {
              const nextLocale = resetLocale();
              await CURRENT_LOCALE.emit(nextLocale);
            }

            // if (COMPANY.current?.name.id !== result.name.id)
            await COMPANY.emit(result);
          }
        }

        if (onSuccess) {
          onSuccess(result);
        }
      }, []),
      ...options,
    }
  );

  const fallback = isLoading
    ? undefined
    : !remoteCompany && error
    ? null
    : undefined;

  return useMemo(() => {
    if (remoteCompany === undefined) {
      if (domain === 'https://api.tactile.events') {
        // console.log('NOPE', domain);
        return DEFAULT_COMPANY;
      }

      return fallback;
    }

    const merged: LocalCompany = merge<LocalCompany, Partial<TactileCompany>>(
      { ...JSON.parse(JSON.stringify(DEFAULT_COMPANY)) },
      remoteCompany || ({} as Partial<TactileCompany>)
    );

    // If there are at least 2 tabs, undo the merge, which merged the arrays and
    // override the value instead.
    if ((remoteCompany?.application?.tabs?.values?.length || 0) > 1) {
      merged.application.tabs.values = remoteCompany!.application.tabs.values;
    }

    // If localization is valid, undo the merge, which merged the arrays and
    // override the value instead.
    if ((remoteCompany?.settings?.localization?.length ?? 0) !== 0) {
      merged.settings.localization = remoteCompany.settings.localization;
    }

    // console.log('merged', domain, merged.name.full, remoteCompany.name.full);

    return merged;
  }, [remoteCompany, domain, endpoint, 1, fallback]);
}

function fetchCompany(
  endpoint: string,
  { signal }: { signal?: AbortSignal }
): Promise<TactileCompany> {
  if (endpoint === 'https://app.tactile.events/api') {
    throw new Error("Can't grab the generic app.tactile.events.");
  }

  if (endpoint === 'https://api.tactile.events/api') {
    return Promise.resolve(COMPANY.current! || null);
  }

  return fetchApplication(endpoint, signal, SHOULD_DEBUG_FETCH);
}

export function useRemoteCompany(
  domainFull: string | undefined,
  {
    enabled = true,
    ...options
  }: UseQueryOptions<TactileCompany, FetchMediaError | Error> = {}
): UseQueryResult<TactileCompany, FetchMediaError | Error> {
  const endpoint = [domainFull || '', 'api'].join('/');

  return useQuery(
    ['api', 'company', endpoint] as QueryKey,
    ({ signal }) => {
      if (!domainFull) {
        throw new Error('Need a domain to grab the app');
      }

      if (domainFull === 'https://app.tactile.events') {
        throw new Error("Can't grab the generic app.tactile.events.");
      }

      if (domainFull === 'https://api.tactile.events') {
        return Promise.resolve(COMPANY.current! || null);
      }

      return fetchApplication(endpoint, signal, SHOULD_DEBUG_FETCH);
    },
    { enabled: !!domainFull && enabled, ...options }
  );
}

// Only update company when the app restarts
export function useMutableCompany() {
  return useMutableMemoryValue(COMPANY);
}
