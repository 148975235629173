import { fetchApplicationPoll, TactilePoll } from '@introcloud/api-client';
import { defineTranslations, i18n } from '@introcloud/blocks';
import { useIsFocused } from '@react-navigation/native';
import React, { Fragment, useState } from 'react';
import { Caption, Card, Portal } from 'react-native-paper';
import { useInaccurateTimestamp } from 'react-native-use-timestamp';
import { useQuery } from 'react-query';
import { useAuthorization, useEndpoint } from '../hooks/useAuthentication';
import { PollMessage, PollResultMessage } from '../hooks/useChats';
import { DayDivider, useChatTimeline } from './DayDivider';
import { StoredChatMessage } from './useChatHistory';

export type PollMessageProps = {
  item: StoredChatMessage;
  previousItem: StoredChatMessage | undefined;
  message: PollMessage;
};

export type PollResultMessageProps = {
  item: StoredChatMessage;
  previousItem: StoredChatMessage | undefined;
  message: PollResultMessage;
};

defineTranslations({
  en: {
    app: {
      polls: {
        request: "You've been asked to answer a poll.",
      },
    },
  },
  nl: {
    app: {
      polls: {
        request: 'Je bent gevraagd een poll te beantwoorden.',
      },
    },
  },
});

export function PollMessageItem_({
  item,
  previousItem,
  message,
}: PollMessageProps) {
  const timestamp = useInaccurateTimestamp({ every: 1000 });
  const pollEnd = new Date(message.e).getTime();
  const pollHasEnded = timestamp > pollEnd;
  const endpoint = useEndpoint();
  const authorization = useAuthorization();
  const isFocused = useIsFocused();
  const [active, setActive] = useState(true);

  const { data: poll, error } = useQuery(
    ['poll', message.i],
    () => fetchApplicationPoll(message.i, endpoint, authorization),
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: !pollHasEnded && isFocused,
    }
  );

  const { messageTime, isNewDay } = useChatTimeline(item, previousItem);

  if (error && !poll) {
    return null;
  }

  return (
    <Fragment>
      {isNewDay ? <DayDivider day={messageTime} /> : null}
      <Card style={{ marginBottom: 16, marginTop: 8 }}>
        <Card.Content>
          <Caption>{i18n.t('app.polls.request')}</Caption>
        </Card.Content>
      </Card>
      <Portal>
        {active && poll && !pollHasEnded ? (
          <Poll data={poll} onDismiss={() => setActive(false)} />
        ) : null}
      </Portal>
    </Fragment>
  );
}

function Poll({ data, onDismiss }: { data: TactilePoll; onDismiss(): void }) {
  return null;
}

function PollResultMessageItem_({
  item,
  previousItem,
  message,
}: PollResultMessageProps) {
  return null;
}

export const PollMessageItem = React.memo(PollMessageItem_);
export const PollResultMessageItem = React.memo(PollResultMessageItem_);
