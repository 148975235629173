import { defineTranslations } from '@introcloud/blocks';
import React from 'react';
import { ProfileScreen } from './ProfileScreen';

defineTranslations({
  en: {
    app: {
      actions: {
        goto_group_chat: 'Chat',
        goto_chats: 'Your chats',
        goto_balance: 'Balance',
        logout: 'Logout',
        change_image: 'Change image',
        change_about: 'Save about me',
        sessions_add: 'Add another',
        delete_account: 'Delete my account',
        delete_account_portal: 'Open portal',
        delete_account_cancel: 'Keep account',
        close_dialog: 'Close',
        negative_action: 'Cancel',
        save: 'Save',
        unlink: 'Disconnect',
        share: 'Share',
      },
      profile: {
        account: {
          title: 'Account',

          payment: {
            description: 'See your balance, top-up, share or receive.',
          },
          logout: {
            description:
              'Data is purged from your device and you need to sign in again.',
          },
          unlink: {
            title: 'Disconnect wristband',
            description:
              'Your account will be disconnected from your current wristband.',
          },
          delete: {
            title: 'Delete account',
            description:
              'Both device data and remote server data is made inaccessible.',
          },
        },
        locker: {
          title: 'Locker',
          fields: {
            number: 'Locker number',
            code: 'Pincode',
            zone: 'Zone',
            comment: 'Comment',
          },
          share_copied_to_clipboard: 'Copied to clipboard',
          share_failed_to_copy_to_clipboard: 'Failed to copy to clipboard',
        },
        communication: {
          title: 'Communication',
          notifications: {
            title: 'Push notifications',
            description: {
              unknown: 'Checking your push notification settings...',
              on: 'You are registered to receive push notifications.',
              off: 'You are not registered to receive push notifications.',
            },
          },
          chats: {
            description: 'Show the recent chat history of in-app communication',
          },
          groups: {
            chat: {
              title: 'Groupchat: {{name}}',
              description: {
                available: 'Open Whatsapp',
                editable: 'Add a Whatsapp link to enable group chat',
                unavailable: 'Not available, contact your group leader',
              },

              url: {
                title: 'Change Whatsapp link',
                description:
                  'Only links with the format https://chat.whatsapp.com/groupid are allowed',
                label: 'Groupschat Whatsapp link',
              },
            },
            contact: {
              title: 'Group: {{name}}',
              description: 'See or contact your group members',
            },
          },
        },

        ticket: {
          title: 'Ticket',
        },

        matching: {
          title: 'Your matching profile',
        },

        switcher: {
          title: 'Sessions',
        },

        delete: {
          title: 'Delete account',
          description:
            'It is possible to delete your account using the Tactile Web Portal. You can use the same credentials to login, after which you may request the removal of your account. Removing your account is permanent, and will revoke your access. Deleting your data is a manual process, but all deletion requests are honored.',
        },
      },
    },
  },

  nl: {
    app: {
      actions: {
        goto_group_chat: 'Chat',
        goto_chats: 'Jouw chats',
        goto_balance: 'Balans',
        logout: 'Uitloggen',
        change_image: 'Verander',
        change_about: 'Over mij opslaan',
        sessions_add: 'Toevoegen',
        delete_account: 'Verwijder mijn account',
        delete_account_portal: 'Open portaal',
        delete_account_cancel: ' Behoud account',
        close_dialog: 'Sluit',
        negative_action: 'Annuleren',
        save: 'Opslaan',
        unlink: 'Ontkoppel',
        share: 'Delen',
      },
      profile: {
        account: {
          title: 'Account',

          payment: {
            description: 'Bekijk je balans, opwaarderen, ontvang of deel.',
          },
          logout: {
            description:
              'Lokale gegevens worden gewist en je moet opnieuw inloggen.',
          },
          unlink: {
            title: 'Bandje ontkoppelen',
            description: 'Jouw account wordt ontkoppeld van je huidige bandje.',
          },
          delete: {
            title: 'Delete account',
            description:
              'Zowel lokale gegevens als servergegevens worden onbruikbaar gemaakt.',
          },
        },
        locker: {
          title: 'Kluisje',
          fields: {
            number: 'Nummer',
            code: 'Code',
            zone: 'Zone',
            comment: 'Opmerking',
          },
        },
        communication: {
          title: 'Communicatie',
          notifications: {
            title: 'Push notificaties',
            description: {
              unknown: 'Bezig met controleren van jou instellingen...',
              on: 'Je bent aangemeld om push notificaties te ontvangen.',
              off: 'Je bent niet aangemeld om push notificaties te ontvangen.',
            },
          },
          chats: {
            description:
              'Laat de recente berichtengeschiedenis zien van in-app communicatie',
          },
          groups: {
            chat: {
              title: 'Groepschat: {{name}}',
              description: {
                available: 'Open Whatsapp',
                editable: 'Voeg een Whatsapp link toe voor groepschat',
                unavailable:
                  'Niet beschikbaar, neem contact op met je groepsleider',
              },
              url: {
                title: 'Wijzigen Whatsapp link',
                description:
                  'Alleen links in de vorm https://chat.whatsapp.com/groupid zijn toegestaan',
                label: 'Groepschat Whatsapp link',
              },
            },
            contact: {
              title: 'Groep: {{name}}',
              description: 'Bekijk of neem contact op met je groepsgenoten',
            },
          },
        },

        ticket: {
          title: 'Ticket',
        },

        matching: {
          title: 'Jouw match profile',
        },

        switcher: {
          title: 'Sessies',
        },

        delete: {
          title: 'Verwijder account',
          description:
            'Het is mogelijk jouw account te verwijderen door gebruik te maken van ons Tactile Web Portal. Hier kan je dezelfde inloggegevens gebruiken. Je kan hier aanvragen je account te verwijderen. Het verwijderen is permanent, en we ontnemen je toegang. Het verwijderen is een handmatig process, maar alle aanvragen worden gehonoreerd.',
        },
      },
    },
  },
});

export function ProfileTab() {
  return <ProfileScreen asTab />;
}
