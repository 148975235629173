import {
  fetchApplicationNews,
  TactileNewsItem,
  TactileNewsItems,
} from '@introcloud/api-client';
import { useIsFocused } from '@react-navigation/core';
import { FetchMediaError } from 'fetch-media';
import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useIsMounted } from 'use-is-mounted';
import { NotReady } from '../core/errors/NotReady';
import {
  AnyMemoryValue,
  StoredMemoryValue,
  useMutableMemoryValue,
} from '../storage';
import { SHOULD_DEBUG_FETCH } from '../utils';
import {
  runOnLogout,
  useEndpoint,
  useSafeAuthorization,
} from './useAuthentication';

const NEWS: AnyMemoryValue<TactileNewsItems | null> =
  new StoredMemoryValue<TactileNewsItems>('application.news.v1');

// TODO: consider secure storage

runOnLogout(() => {
  NEWS.emit(null);
});

export function useNews({ enabled = true }: { enabled?: boolean } = {}) {
  const isFocused = useIsFocused();
  const authorization = useSafeAuthorization();
  const endpoint = useEndpoint();
  const isMounted = useIsMounted();
  const [storedNews, setStoredNews] = useMutableMemoryValue(NEWS);

  const fetcher = useCallback(
    async ({ signal }: { signal?: AbortSignal }) => {
      if (!authorization || !endpoint) {
        throw new NotReady();
      }

      const result = await fetchApplicationNews(
        endpoint,
        authorization!,
        signal,
        SHOULD_DEBUG_FETCH
      );

      const sorted = result.sort((a, b) =>
        b.meta.publish.at.dateISO.localeCompare(a.meta.publish.at.dateISO)
      );

      isMounted.current && setStoredNews(sorted);

      return sorted;
    },
    [authorization, endpoint, setStoredNews]
  );

  const {
    data: news,
    error,
    ...others
  } = useQuery<readonly TactileNewsItem[] | null, FetchMediaError | NotReady>(
    [endpoint, 'application', 'news'],
    fetcher,
    {
      placeholderData: storedNews,
      enabled: enabled && isFocused && !!(authorization && endpoint),
      staleTime: 5 * 60 * 1000,
    }
  );

  return {
    data: news,
    error,
    reload: others.refetch,
    loading: others.isLoading,
    refreshing: others.isFetching && !others.isLoading,
    ...others,
  };
}
