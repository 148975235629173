import {
  eventHasTimeslots,
  fetchApplicationEvents,
  TactileEvent,
  TactileEventRef,
  TactileLocationRef,
  TactileNoEventRef,
  TactileNoLocationRef,
  TactileNoPageRef,
  TactilePageRef,
} from '@introcloud/api-client';
import { useIsFocused } from '@react-navigation/core';
import { FetchMediaError } from 'fetch-media';
import { useCallback } from 'react';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { useIsMounted } from 'use-is-mounted';
import { EVENT_CACHE } from '../core/Cache';
import { NotReady } from '../core/errors/NotReady';
import { StoredMemoryValue, useMutableMemoryValue } from '../storage';
import { merge, SHOULD_DEBUG_FETCH } from '../utils';
import {
  runOnLogout,
  useEndpoint,
  useSafeAuthorization,
} from './useAuthentication';

const EVENTS = new StoredMemoryValue<readonly PreparedEvent[]>(
  'application.events.v1'
);

export type PreparedEvent = Omit<
  TactileEvent & {
    hierarchy: {
      isMain: boolean;
      isSub: boolean;
      parent: string | null;
      showInCalendar: boolean;
    };
    page: boolean;
    hasTimeslots?: boolean;
  },
  'pageRef' | 'locationRef' | 'eventRef'
> & {
  pageRef: TactileNoPageRef | TactilePageRef;
  locationRef: (TactileNoLocationRef | TactileLocationRef)[];
  eventRef: TactileNoEventRef | TactileEventRef;
};

runOnLogout(() => {
  EVENTS.emit(null);
});

export function useEvents({
  enabled = true,
  ...options
}: UseQueryOptions<
  readonly PreparedEvent[] | null,
  FetchMediaError | Error
> = {}) {
  const [storedEvents, setStoredEvents] = useMutableMemoryValue(EVENTS);
  const endpoint = useEndpoint();
  const authorization = useSafeAuthorization();
  const isMountedRef = useIsMounted();
  const isFocused = useIsFocused();

  const fetcher = useCallback(
    async ({ signal }: { signal?: AbortSignal }) => {
      if (!endpoint || !authorization) {
        throw new NotReady();
      }

      const result = await fetchApplicationEvents(
        endpoint,
        authorization!,
        signal,
        SHOULD_DEBUG_FETCH
      );
      const sorted = await sortEvents(result);
      const cached = await cacheEvents(sorted);
      const prepared = await prepareEvents(cached);

      isMountedRef.current && setStoredEvents(prepared);

      return prepared;
    },
    [endpoint, authorization, isMountedRef, setStoredEvents]
  );

  const {
    data: events,
    error,
    ...others
  } = useQuery([endpoint, 'application', 'events'] as QueryKey, fetcher, {
    placeholderData: (storedEvents || []).length > 0 ? storedEvents : undefined,
    enabled: enabled && !!(authorization && endpoint) && isFocused,
    staleTime: 5 * 60 * 1000,
    ...options,
  });

  return {
    data: events,
    loading: others.isLoading,
    error,
    reload: others.refetch,
    refreshing: others.isFetching && !others.isLoading,
    ...others,
  };
}

export async function sortEvents(
  events: readonly TactileEvent[]
): Promise<readonly TactileEvent[]> {
  return events.slice().sort((a, b) => {
    if (!a.duration || !a.duration.start || !b.duration || !b.duration.start) {
      return a._id.localeCompare(b._id);
    }

    const compareStart = a.duration.start.dateISO.localeCompare(
      b.duration.start.dateISO
    );
    if (compareStart !== 0) {
      return compareStart;
    }

    const compareEnd = a.duration.end.dateISO.localeCompare(
      b.duration.end.dateISO
    );
    if (compareEnd !== 0) {
      return compareEnd;
    }

    return a._id.localeCompare(b._id);
  });
}

export async function prepareEvents(
  events: readonly TactileEvent[]
): Promise<readonly PreparedEvent[]> {
  const mainEvents = events.filter(
    (event) => !event.eventRef || !event.eventRef.eventId
  );
  return events
    .map((event) => prepareEvent(event, mainEvents))
    .filter(Boolean) as readonly PreparedEvent[];
}

export function prepareEvent(
  event: TactileEvent,
  mainEvents: TactileEvent[]
): PreparedEvent | null {
  if (
    !__DEV__ &&
    event.duration.start.unix < new Date('2023-01-01T00:00:00Z').getTime()
  ) {
    return null;
  }

  const isMain = mainEvents.some((mainEvent) => mainEvent._id === event._id);
  const parentId = event.eventRef?.eventId;
  const isSub = !mainEvents.some((mainEvent) => mainEvent._id === parentId);

  return {
    ...event,
    hierarchy: {
      isMain,
      isSub,
      parent: parentId,
      showInCalendar: event.module?.application?.inOverview,
    },
    page: !!(event.pageRef && event.pageRef.pageId),
    hasTimeslots: eventHasTimeslots(event),
  };
}

function cacheEvents(result: readonly TactileEvent[]) {
  const currentValue = EVENT_CACHE.current;
  const nextValue = toMap(result);

  Object.keys(currentValue).forEach((key) => {
    if (!nextValue[key]) {
      delete currentValue[key];
    }
  });

  merge(currentValue, nextValue);
  return result;
}

function toMap<T extends { _id: string }>(
  items: readonly T[]
): Record<string, T> {
  return items.reduce((result, item) => {
    result[item._id] = item;
    return result;
  }, {} as Record<string, T>);
}
