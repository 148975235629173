import { useMemo } from 'react';
import { useGroupsDetached } from '../hooks/useGroup';

export function useChatMentorFlag(id: string | undefined): boolean {
  const { data: groups } = useGroupsDetached();
  return useMemo(
    () =>
      Boolean(
        groups?.some((group) =>
          group.users.some(
            (member) => member._id === id && member.role.id === 'leader'
          )
        )
      ),
    [id, groups]
  );
}
