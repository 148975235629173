import { TactileLocation } from '@introcloud/api-client';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useRef } from 'react';
import { View } from 'react-native';
import { SelectedMapMarker } from './SelectedMarker';

export function MapSelectedOverlay({
  selected,
}: {
  selected?: null | TactileLocation;
}) {
  const lastSelected = useRef(selected);

  lastSelected.current = selected || lastSelected.current;

  return (
    <AnimatePresence>
      {lastSelected.current && (
        <motion.div
          key={lastSelected.current._id}
          exit={{ opacity: 0, transition: { duration: 0.12, delay: 0.2 } }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.34 } }}
          style={{
            position: 'absolute',
            padding: 6,
            alignSelf: 'center',
            width: '100%',
            zIndex: 2,
            bottom: 0,
            maxWidth: 720,
          }}
        >
          <View pointerEvents="auto">
            <SelectedMapMarker item={lastSelected.current} />
          </View>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
