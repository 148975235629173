import React, { useEffect, useRef } from 'react';
import { Permit } from '../core/Authentication';

export function RegisterWebView({
  url,
  onLogin,
}: {
  url: string;
  onLogin(permit: Pick<Permit, 'expiresAt' | 'token'>): void;
}) {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const onMessage = (messageEvent: MessageEvent) => {
      if (!url.startsWith(messageEvent.origin)) {
        // ignore if different origin
        return;
      }

      const message = messageEvent.data;
      try {
        const wrapper = JSON.parse(message);
        console.log(wrapper.message);
        console.log(
          'In order to login I need a token. I expect that at data.token: { value: string, expiresAt: number }'
        );

        if ('data' in wrapper && typeof wrapper.data === 'object') {
          const data = wrapper.data;

          if ('token' in data && typeof data.token === 'object') {
            onLogin({
              token: String(data.token.value),
              expiresAt: Number(data.token.expiresAt),
            });
          } else {
            console.warn(
              'Expected data.token to be an object. Actual:',
              typeof data.token,
              data
            );
          }

          return;
        } else {
          console.warn(
            'Expected data to be present and to be an object. Actual:',
            typeof wrapper.data,
            wrapper
          );
        }
      } catch (e) {
        // ignore
        console.warn('Expected data to be an encoded JSON string', e);
      }
    };

    window.addEventListener('message', onMessage);
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [iframeRef, url]);

  return (
    <iframe
      ref={iframeRef}
      style={{
        width: '100%',
        height: '100%',
      }}
      frameBorder={0}
      src={url}
      allow="clipboard-write; encrypted-media;"
    />
  );
}
