import { defineTranslations } from '@introcloud/blocks';
import React from 'react';

import { HomeScreen } from './HomeScreen';

defineTranslations({
  en: {
    app: {
      actions: {
        view_timeslot: 'View',
      },
      home: {
        title: 'Home',
      },
      now: {
        your_timeslot: 'This is your personal timeslot',
      },
    },
  },

  nl: {
    app: {
      actions: {
        view_timeslot: 'Bekijk',
      },
      home: {
        title: 'Home',
      },
      now: {
        your_timeslot: 'Dit is jouw persoonlijke tijdslot',
      },
    },
  },
});

export function HomeTab() {
  return <HomeScreen asTab />;
}
