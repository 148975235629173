import { fetchMedia } from 'fetch-media';
import { AllHtmlEntities } from 'html-entities';
import React from 'react';
import { Image, StyleSheet } from 'react-native';
import { List, useTheme } from 'react-native-paper';
import { useQuery } from 'react-query';
import { GelegraafItem } from './useGelegraaf';
import { useSafe } from './useSafeHtmlContent';

const entities = new AllHtmlEntities();

const styles = StyleSheet.create({
  featured: {
    width: 72,
    height: 72,
    marginLeft: 16,
  },
});

export interface RegularHeadlineProps {
  article: GelegraafItem;
  onGotoArticle(): void;
}

export function RegularHeadline({
  article,
  onGotoArticle,
}: RegularHeadlineProps) {
  const { title, featured_media, content } = article || {};
  const safeDescription = useSafe((content || { rendered: '' }).rendered);

  if (!article) {
    return null;
  }

  return (
    <List.Item
      onPress={onGotoArticle}
      title={entities.decode((title || { rendered: '' }).rendered)}
      description={safeDescription}
      descriptionNumberOfLines={2}
      descriptionStyle={{ lineHeight: 19, marginTop: 4, fontSize: 13 }}
      right={() =>
        (featured_media && <RegularHeadlineImage source={featured_media} />) ||
        null
      }
    />
  );
}

function RegularHeadlineImage({
  source,
}: {
  source: GelegraafItem['featured_media'];
}) {
  const { roundness } = useTheme();
  const propData =
    typeof source === 'object' && 'media_details' in source
      ? source
      : undefined;

  const { data: remoteMedia } = useQuery(
    [source?.href],
    async () => {
      const result = await fetchMedia(source!.href!, {
        headers: { accept: 'application/json' },
        disableText: true,
        disableFormData: true,
        disableFormUrlEncoded: true,
      });

      if (result && 'media_details' in result) {
        return result;
      }

      return null;
    },
    {
      enabled: Boolean(source?.href),
      initialData:
        typeof source === 'object' && 'media_details' in source
          ? source
          : undefined,
      staleTime: 1000 * 60 * 60,
    }
  );

  const featuredMedia = remoteMedia || propData;

  if (!featuredMedia) {
    return null;
  }

  const { media_details } =
    (featuredMedia as GelegraafItem['featured_media']) || {};
  if (!media_details) {
    return null;
  }

  const { sizes } = media_details || {};

  if (!sizes) {
    return null;
  }

  const base = sizes['speaker-thumb'] || sizes['medium'];
  const image = base.source_url;

  if (!image) {
    return null;
  }

  return (
    <Image
      style={[
        styles.featured,
        { borderRadius: roundness, width: 112, height: 112 },
      ]}
      source={{ uri: image, width: 458, height: 458 }}
    />
  );
}
