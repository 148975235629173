import { EventData, EventListItemOptions } from '@introcloud/blocks';
import { BlockData } from '@introcloud/blocks-interface';
import { localize } from '@introcloud/blocks/dist/useLocale';
import { createURL, openURL } from 'expo-linking';
import cloneDeep from 'lodash.clonedeep';
import merge from 'lodash.merge';
import { Platform } from 'react-native';
import { INTERNAL_PREFIXES, IS_TEST_RELEASE } from './config';
import { PreparedEvent } from './hooks/useEvents';
import {
  LinkingOptions,
  NavigationContainerRef,
  getActionFromState,
  getStateFromPath,
} from '@react-navigation/native';
import { navigationConfigRef, navigationRef } from './core/RootNavigation';

export const SHOULD_ALLOW_DEBUG = __DEV__ || IS_TEST_RELEASE;
export const SHOULD_DEBUG_FETCH = false;

export function extractEventData(
  data: PreparedEvent | null,
  other: EventListItemOptions,
  utils: { getImageUrl: BlockData['getImageUrl'] },
  locale: string
): (EventData & EventListItemOptions) | null {
  if (data === null) {
    return null;
  }

  const imageId = data.image ? data.image.profile || data.image.banner : null;

  const localizedName = localize(
    data.nameLocalized?.full,
    data.name.full,
    locale
  );
  const localizedDescription = localize(
    data.nameLocalized?.description,
    data.name.description,
    locale
  );
  const localizedTag = localize(data.nameLocalized?.tag, data.name.tag, locale);

  return {
    id: data._id,
    title: localizedName,
    description: localizedDescription || '',
    duration: {
      start: data.duration?.start?.unix || 0,
      end: data.duration?.end?.unix || 0,
    },
    tags: localizedTag || [],
    locationIds: (data.locationRef || [])
      .map((ref) => ref.locationId)
      .filter(Boolean) as string[],
    fallbackLocation: undefined,
    illustration: imageId
      ? utils.getImageUrl(imageId, 'icon_128') || undefined
      : undefined,
    hasTimeslots: data.hasTimeslots,
    ...other,
    loading: false,
  };
}

const LISTENER_REF = { current: openURL };

export function setLocalDeeplinkListener(
  listener: null | ((url: string) => Promise<true>)
): void {
  if (!listener) {
    LISTENER_REF.current = openURL;
  } else {
    LISTENER_REF.current = listener;
  }
}

export function openLocalDeeplink(
  path: string,
  queryParams?: Parameters<typeof createURL>[1]
) {
  if (
    Platform.OS === 'web' &&
    (window.location.href === path || window.location.pathname === path)
  ) {
    return Promise.resolve(true);
  }

  const fullUrl = path.startsWith('http') ? path : createURL(path, queryParams);
  console.log('[deeplink] local:', fullUrl);

  return LISTENER_REF.current(fullUrl);
}

export function resetToPath(
  path: string,
  config?: undefined | LinkingOptions<ReactNavigation.RootParamList>['config'],
  navigation: NavigationContainerRef<ReactNavigation.RootParamList> = navigationRef
) {
  if (
    INTERNAL_PREFIXES.some(
      (prefix) =>
        path.startsWith(prefix) || path.startsWith(prefix.toLocaleLowerCase())
    )
  ) {
    path = INTERNAL_PREFIXES.reduce(
      (nextPath, prefix) =>
        nextPath.replace(prefix, '').replace(prefix.toLocaleLowerCase(), ''),
      path
    );
  }

  if (path[0] !== '/') {
    path = `/${path}`;
  }

  const state = getStateFromPath(
    path,
    config ?? navigationConfigRef.current ?? undefined
  );

  if (!state) {
    return false;
  }

  const action = getActionFromState(state);
  if (action === undefined) {
    return false;
  }

  console.log('[deeplink] internal', path, action);
  navigation.reset(state);
  return true;
}

export { merge, cloneDeep };
