import { useMemo } from 'react';
import { getMidDayId } from '../hooks/useDayBoundaries';

const DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;

export function useConfiguredDays<T extends object>({
  start,
  end,
  getData = () => ({} as Partial<T>),
}: {
  start: number;
  end: number;
  getData?: (midDay: string) => Partial<T>;
}): ({
  day: string;
  date: number;
} & Partial<T>)[] {
  return useMemo(() => {
    if (start > end) {
      return [];
    }

    // const move = new Date(start).getUTCHours();
    // console.log(move);

    // The calculations here are done so that 2020-01-01 00:00 is _likely_ to
    // be reported as 2020-01-01 for all locales. This works by moving the
    // date to 2020-01-01 12:00, and then performing all operations.

    const startDate = new Date(start + DAY_IN_MILLISECONDS / 2);
    const endDate = new Date(end + DAY_IN_MILLISECONDS / 2);
    const startMidDay = getMidDayId(startDate);
    const endMidDate = getMidDayId(endDate);

    // Loop through all the days and get the images
    let currentDate = new Date(startMidDay);
    const endDateUnix = new Date(endMidDate).getTime();

    const days: ({ day: string; date: number } & Partial<T>)[] = [];
    while (currentDate.getTime() <= endDateUnix) {
      const midDay = getMidDayId(currentDate).split('T').shift()!;
      if (
        midDay !== '2022-10-22' &&
        midDay !== '2022-10-23' &&
        midDay !== '2022-10-25'
      ) {
        days.push({
          date: currentDate.getTime(),
          day: midDay,
          ...getData(midDay),
        });
      }

      currentDate = new Date(currentDate.getTime() + DAY_IN_MILLISECONDS);
    }

    return days;
  }, [start, end, getData]);
}
