import { eventHasInterview } from '@introcloud/api-client';
import { i18n } from '@introcloud/blocks';
import React from 'react';
import { View } from 'react-native';
import { BlockProvision } from '../core/BlockProvision';
import { EmptyState } from '../core/EmptyState';
import { Header } from '../core/Header';
import { useCompanyTab } from '../hooks/useCompanyTabs';
import { useSwipeInterview } from '../hooks/useSwipeInterview';
import { SwipeEvent } from './SwipeEvent2';

const EMPTY_TEXT = {
  en: `
  During various moments you can match with others with similar interests. Check back later!
  `
    .trim()
    .replace(/\n/g, ''),
  nl: `
  Op verschillende momenten kan je hier anderen ontmoeten met soortgelijke interesses. Je bent dan welkom!
  `
    .trim()
    .replace(/\n/g, ''),
};

export function MatchingScreen({ asTab }: { asTab?: boolean }) {
  const { icon, title } = useCompanyTab('matching', 'gesture-swipe');

  const event = useSwipeInterview();
  const isReady =
    event &&
    eventHasInterview(event) &&
    event.module.interview.question.length > 0 &&
    event.module.interview.question[0].type === 'textarea';

  return (
    <BlockProvision screen="MatchingTab">
      <View style={{ position: 'relative', width: '100%', height: '100%' }}>
        <Header
          title={title || i18n.t('app.matching.title')}
          subTitle={undefined}
          hideBack={asTab}
          style={event ? {} : { elevation: 2, zIndex: 2 }}
          showTranslate
        />
        {isReady ? <SwipeEvent {...(event as any)} /> : null}
        <EmptyState
          hidden={!!isReady}
          icon={icon}
          title={title || i18n.t('app.matching.title')}
          texts={EMPTY_TEXT}
        />
      </View>
    </BlockProvision>
  );
}
