import { useCurrentDomain } from '../hooks/useAuthentication';
import { useRemoteCompany } from '../hooks/useCompany';

const DEFAULT_AMOUNT = [
  { euro: 15, cents: 1500 },
  { euro: 20, cents: 2000 },
  { euro: 25, cents: 2500 },
  { euro: 30, cents: 3000 },
  { euro: 40, cents: 4000 },
  { euro: 50, cents: 5000 },
  { euro: 60, cents: 6000 },
  { euro: 70, cents: 7000 },
  { euro: 80, cents: 8000 },
  { euro: 90, cents: 9000 },
  { euro: 100, cents: 10000 },
  { euro: 200, cents: 20000 },
];

export function useTopupAmounts() {
  const { data: company } = useRemoteCompany(useCurrentDomain());

  const amounts = company?.settings.payment.amount || DEFAULT_AMOUNT;
  const fee: { euro: number; cents: number } | undefined | null =
    company?.settings?.payment?.fee;

  return { amounts, fee };
}

export function useDefaultTopupAmount() {
  const { data: company } = useRemoteCompany(useCurrentDomain());
  return company?.settings.payment.amountDefault;
}
