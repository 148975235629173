import { AccentButton, Divider, i18n, useLocale } from '@introcloud/blocks';
import { useIsFocused } from '@react-navigation/native';
import { useTransactions } from '../payment/useTransactions';
import { useBlockDataBalance } from '@introcloud/blocks-interface';
import {
  Card,
  DataTable,
  List,
  Menu,
  Surface,
  useTheme,
} from 'react-native-paper';
import { Alert, Platform, Share, View } from 'react-native';
import { BalanceOverViewState } from '@introcloud/api-client';
import { Fragment, useCallback } from 'react';
import * as Clipboard from 'expo-clipboard';

export function ProfileLocker() {
  useLocale();

  const enabled = useIsFocused();

  const { data } = useBlockDataBalance({
    enabled,
    refetchOnWindowFocus: true,
  });

  const lockers = useLockers(data);

  const copy = useCallback((lockerData: string) => {
    return Clipboard.setStringAsync(lockerData)
      .then(() => {
        if (Platform.OS === 'web') {
          window.alert(i18n.t('app.profile.locker.share_copied_to_clipboard'));
        } else {
          Alert.alert(
            i18n.t('app.profile.locker.title'),
            i18n.t('app.profile.locker.share_copied_to_clipboard')
          );
        }
      })
      .catch((e) => {
        if (Platform.OS === 'web') {
          window.alert(
            i18n.t('app.profile.locker.share_failed_to_copy_to_clipboard')
          );
        } else {
          Alert.alert(
            i18n.t('app.profile.locker.title'),
            [
              i18n.t('app.profile.locker.share_failed_to_copy_to_clipboard'),
              'message' in e ? e.message : '',
            ].join('\n')
          );
        }
      });
  }, []);

  const share = useCallback(() => {
    const lockerData = `${i18n.t('app.profile.locker.title')}
-------------------------------------
${lockers
  .map((locker) =>
    [
      [i18n.t('app.profile.locker.fields.number'), locker.locker_number].join(
        ': '
      ),
      [i18n.t('app.profile.locker.fields.code'), locker.code].join(': '),
      locker.zone
        ? [i18n.t('app.profile.locker.fields.zone'), locker.zone].join(': ')
        : '',
    ]
      .join('\n')
      .trim()
  )
  .join('\n\n-------------------------------------\n')}
`;

    if (Platform.OS === 'web') {
      return copy(lockerData);
    } else {
      Share.share({
        message: lockerData,
        title: i18n.t('app.profile.locker.title'),
      }).catch(copy);
    }
  }, [lockers, copy]);

  if (lockers.length === 0) {
    return null;
  }

  return (
    <View style={{ width: '100%', position: 'relative', paddingVertical: 12 }}>
      <View style={{ position: 'relative' }}>
        <List.Subheader>{i18n.t('app.profile.locker.title')}</List.Subheader>
        <AccentButton
          icon="share"
          style={{ position: 'absolute', right: 0 }}
          onPress={share}
        >
          {i18n.t('app.actions.share')}
        </AccentButton>
      </View>
      {lockers.map((locker, i, self) => {
        return (
          <Fragment key={i}>
            <LockerCard
              key={i}
              number={locker.locker_number}
              code={locker.code}
              zone={locker.zone || undefined}
            />
            {i < self.length - 1 ? <View style={{ height: 8 }} /> : null}
          </Fragment>
        );
      })}
    </View>
  );
}

function useLockers(data: BalanceOverViewState | undefined) {
  if (!data || !data.ok) {
    return [];
  }

  return data.lockers;
}

function LockerCard({
  number,
  code,
  zone,
}: {
  number: string | undefined;
  code: string | undefined;
  zone: string | undefined;
}) {
  const { roundness } = useTheme();

  return (
    <Card
      style={{
        width: '100%',
        borderRadius: roundness,
        overflow: 'hidden',
        marginHorizontal: 'auto',
      }}
      onPress={() =>
        Share.share({
          title: i18n.t('app.profile.locker.title'),
          message: [
            [i18n.t('app.profile.locker.fields.number'), number].join(': '),
            [i18n.t('app.profile.locker.fields.code'), code].join(': '),
            zone
              ? [i18n.t('app.profile.locker.fields.zone'), zone].join(': ')
              : '',
          ]
            .join('\n')
            .trim(),
        })
      }
    >
      <DataTable>
        <DataTable.Row style={{ borderWidth: 0 }}>
          <DataTable.Cell>
            {i18n.t('app.profile.locker.fields.number')}
          </DataTable.Cell>
          <DataTable.Cell
            textStyle={{ fontSize: 36, fontWeight: '600' }}
            style={{ width: '100%', flex: 2 }}
          >
            {number ?? ''}
          </DataTable.Cell>
        </DataTable.Row>
        <DataTable.Row style={{ borderWidth: 0 }}>
          <DataTable.Cell>
            {i18n.t('app.profile.locker.fields.code')}
          </DataTable.Cell>
          <DataTable.Cell
            textStyle={{ fontSize: 36, fontWeight: '600' }}
            style={{ width: '100%', flex: 2 }}
          >
            {code ?? ''}
          </DataTable.Cell>
        </DataTable.Row>
        {zone ? (
          <DataTable.Row style={{ borderWidth: 0 }}>
            <DataTable.Cell>
              {i18n.t('app.profile.locker.fields.zone')}
            </DataTable.Cell>
            <DataTable.Cell
              style={{ width: '100%', flex: 2 }}
              textStyle={{ fontStyle: 'italic' }}
            >
              {zone ?? ''}&nbsp;
            </DataTable.Cell>
          </DataTable.Row>
        ) : null}
      </DataTable>
    </Card>
  );
}
