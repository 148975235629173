import {
  PrimaryButton,
  cleanTag,
  defineTranslations,
  i18n,
  locationTagIconFor,
  normalizeTag,
} from '@introcloud/blocks';
import React, { useCallback, useState } from 'react';
import { Platform } from 'react-native';
import { Divider, Menu } from 'react-native-paper';
import { TAG_OVERRIDES } from '../features';

defineTranslations({
  en: {
    app: {
      actions: {
        filter: 'Filter',
      },
      filter: {
        none: 'Show everything',
      },
    },
  },

  nl: {
    app: {
      actions: {
        filter: 'Filter',
      },
      filter: {
        none: 'Toon alles',
      },
    },
  },
});

export type FilterMenuProps = {
  onSelect: (tag: string | null) => void;
  selected: string | null;
  tags: string[];

  defaultIcon?: string;
  defaultTag?: string;
  normalizeTags?: typeof normalizeTag;
  tagToIcon?: typeof locationTagIconFor;
};

export function FilterMenu({
  onSelect,
  selected,
  tags,
  defaultIcon = 'map-marker',
  defaultTag = 'Events',
  normalizeTags = normalizeTag,
  tagToIcon = locationTagIconFor,
}: FilterMenuProps) {
  const [isActive, setIsActive] = useState(false);

  const hide = useCallback(() => setIsActive(false), []);
  const show = useCallback(() => {
    if (Platform.OS === 'web') {
      document.body.style['overflowX'] = 'hidden';
    }

    setIsActive(true);
  }, []);

  return (
    <Menu
      visible={isActive}
      onDismiss={hide}
      anchor={
        <PrimaryButton icon="filter" onPress={show} style={{ elevation: 1 }}>
          {typeof selected === 'string'
            ? (TAG_OVERRIDES ? TAG_OVERRIDES[selected] : undefined) ||
              normalizeTags(selected) ||
              defaultTag
            : i18n.t('app.actions.filter')}
        </PrimaryButton>
      }
    >
      <Menu.Item
        onPress={() => {
          onSelect(null);
          hide();
        }}
        title={i18n.t('app.filter.none')}
      />
      {tags.length > 0 ? <Divider /> : null}
      {tags.map((option) => {
        const tag = cleanTag(option);
        const icon = tagToIcon(tag) || defaultIcon;
        const title =
          (TAG_OVERRIDES ? TAG_OVERRIDES[tag] : undefined) ||
          normalizeTags(tag) ||
          defaultTag;

        return (
          <Menu.Item
            key={tag}
            onPress={() => {
              onSelect(option);
              hide();
            }}
            trailingIcon={icon}
            title={title}
          />
        );
      })}
    </Menu>
  );
}
