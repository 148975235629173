import { listUserPushTokens, registerPushToken } from '@introcloud/api-client';
import {
  PrimaryButton,
  Spacer,
  defineTranslations,
  i18n,
  useLocale,
} from '@introcloud/blocks';
import { useIsFocused } from '@react-navigation/native';
import Color from 'color';
import * as ExpoNotifications from 'expo-notifications';
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Platform } from 'react-native';
import {
  Avatar,
  Card,
  HelperText,
  Paragraph,
  Text,
  useTheme,
} from 'react-native-paper';
import { QueryKey, useMutation, useQuery, useQueryClient } from 'react-query';
import { useMediaQuery } from 'react-responsive';
import { useIsMounted } from 'use-is-mounted';
import { EXPERIENCE_SLUG, IS_TEST_RELEASE } from '../config';
import { useEndpoint, useSafeAuthorization } from '../hooks/useAuthentication';
import { useUser } from '../hooks/useUser';
import { SHOULD_ALLOW_DEBUG } from '../utils';
import { configureNotifications } from '../notifications';

defineTranslations({
  en: {
    app: {
      notifications: {
        permission: {
          title: 'Get important updates',
          subtitle: 'Enable push notifications',
          description:
            "Allow push notifications to get updates about last-minute changes to the programme, important personal information, or reminders for important events you're participating in",
          enable: 'Enable',
        },
      },
    },
  },

  nl: {
    app: {
      notifications: {
        permission: {
          title: 'Krijg belangrijke updates',
          subtitle: 'Schakel push notifications in',
          description:
            'Sta push notifications toe om updates te ontvangen over veranderingen van het laatste minuut, belangrijke persoonlijke informatie, of herinneringen voor evenementen waar je aan deelneemt',
          enable: 'Inschakelen',
        },
      },
    },
  },
});

const IS_SUPPORTED = Platform.select({
  web: false,
  default: true,
});

export const LAST_TOKEN_REF = React.createRef() as MutableRefObject<string>;

export function useKnownTokens(enabled: boolean) {
  const { data: currentUser } = useUser();
  const authorization = useSafeAuthorization();
  const endpoint = useEndpoint();

  const queryEnabled = enabled && Boolean(endpoint && authorization);

  return useQuery(
    ['user', currentUser?._id, 'push-notification-tokens'] as QueryKey,
    async ({ signal }) => {
      const tokenNow = authorization;
      if (!tokenNow) {
        throw new Error('Authorization required');
      }

      const data = await listUserPushTokens(
        endpoint,
        authorization,
        signal,
        false
      );
      return (data as { data: { expoSlug: string; token: string }[] }).data;
    },
    {
      enabled: queryEnabled,
      notifyOnChangeProps: ['data'],
    }
  );
}

export function NotificationsConsent() {
  const enabled = useIsFocused();
  const {
    colors: { primary },
  } = useTheme();
  const textColor = new Color(primary).isDark() ? 'white' : 'black';

  const { data: currentUser } = useUser();
  const { data: knownTokens } = useKnownTokens(enabled);

  const IS_PREVIEW =
    IS_TEST_RELEASE ||
    (!currentUser?.email.value.includes('store') &&
      currentUser?.email.value.endsWith('@tactile.events'));

  const experienceRegistered = knownTokens?.some(
    (token) =>
      token.expoSlug === EXPERIENCE_SLUG ||
      token.expoSlug === EXPERIENCE_SLUG.slice(1).replace('/', '') ||
      token.expoSlug === EXPERIENCE_SLUG.slice(1).replace('/', '--')
  );

  if (!IS_SUPPORTED) {
    return null;
  }

  if (knownTokens === undefined) {
    if (IS_PREVIEW) {
      return (
        <Card
          theme={{ roundness: 8 }}
          elevation={0}
          style={{
            backgroundColor: primary,
            marginBottom: 12,
            marginHorizontal: 12,
          }}
        >
          <Card.Content>
            <Text style={{ color: textColor, fontWeight: 'bold' }}>
              Debugging information
            </Text>
            <Paragraph style={{ color: textColor }}>
              Retrieving known push tokens...
            </Paragraph>
          </Card.Content>
        </Card>
      );
    }

    return null;
  }

  return (
    <RequestAndRegister hideUntilCertain={Boolean(experienceRegistered)} />
  );
}

function RequestAndRegister({
  hideUntilCertain,
}: {
  hideUntilCertain: boolean;
}) {
  useLocale();

  const mobileView = useMediaQuery({ query: '(max-width: 736px)' }); // 720 + 16

  const enabled = useIsFocused();
  const authorization = useSafeAuthorization();
  const endpoint = useEndpoint();

  const { data: currentUser } = useUser();
  const { data: knownTokens, error: knownTokensError } =
    useKnownTokens(enabled);

  const isMountedRef = useIsMounted();

  const {
    roundness,
    colors: { primary },
  } = useTheme();
  const textColor = new Color(primary).isDark() ? 'white' : 'black';

  const [pushToken, setPushToken] = useState(
    LAST_TOKEN_REF.current as string | null
  );

  const queryClient = useQueryClient();
  const [state, setState] = useState({
    permissionsChecked: false,
    tokenReceived: false,
    canAskAgain: false,
    isRegistering: false,
    isGranted: false,
  });

  const {
    mutateAsync: register,
    isLoading,
    error: registerError,
  } = useMutation(
    async (registerToken: string) => {
      await queryClient.cancelQueries([
        'user',
        currentUser?._id,
        'push-notification-tokens',
      ]);

      const tokenNow = authorization;

      if (!tokenNow) {
        throw new Error('Token required');
      }

      await registerPushToken(
        endpoint,
        registerToken,
        authorization,
        EXPERIENCE_SLUG,
        currentUser?._id,
        undefined,
        SHOULD_ALLOW_DEBUG
      );
    },
    {
      onSettled: async () => {
        await queryClient.invalidateQueries([
          'user',
          currentUser?._id,
          'push-notification-tokens',
        ]);
      },
    }
  );

  const enableAllow =
    Boolean(currentUser) &&
    Boolean(authorization) &&
    !isLoading &&
    !state.isRegistering &&
    state.permissionsChecked;

  const showAllow =
    state.permissionsChecked && (state.canAskAgain || state.isGranted);

  // Check initial state
  useEffect(() => {
    if (state.permissionsChecked) {
      return;
    }

    ExpoNotifications.getPermissionsAsync()
      .then(({ status, canAskAgain }) => {
        if (!isMountedRef.current) {
          return;
        }

        if (status === 'granted') {
          setState((prev) => ({
            ...prev,
            permissionsChecked: true,
            isGranted: true,
          }));
          return;
        }

        setState((prev) => ({
          ...prev,
          permissionsChecked: true,
          isGranted: false,
          canAskAgain,
        }));
      })
      .catch(() => {
        setState((prev) => ({
          ...prev,
          permissionsChecked: true,
          isGranted: false,
          canAskAgain: true,
        }));
      });
  }, []);

  useEffect(() => {
    if (!state.isGranted || state.tokenReceived) {
      return;
    }

    ExpoNotifications.getExpoPushTokenAsync({
      experienceId: EXPERIENCE_SLUG,
    })
      .then((token) => {
        if (!isMountedRef.current) {
          return;
        }

        LAST_TOKEN_REF.current = token.data;
        setPushToken(token.data);
        setState((prev) => ({
          ...prev,
          tokenReceived: true,
        }));
      })
      .catch((error) => {
        console.error(error);
      });
  }, [state.isGranted]);

  const allow = useCallback(() => {
    setState((prev) => ({ ...prev, isRegistering: true }));

    async function execute() {
      // If not yet granted, request now
      if (!state.isGranted) {
        const { status, canAskAgain } =
          await ExpoNotifications.requestPermissionsAsync();

        if (!isMountedRef.current) {
          return;
        }

        if (status !== 'granted') {
          setState((prev) => ({
            ...prev,
            isRegistering: false,
            isGranted: false,
            canAskAgain,
          }));
          return;
        }

        configureNotifications();

        setState((prev) => ({ ...prev, isGranted: true }));
      }

      // is granted now!
      const nextToken =
        pushToken ||
        (
          await ExpoNotifications.getExpoPushTokenAsync({
            experienceId: EXPERIENCE_SLUG,
          })
        ).data;

      LAST_TOKEN_REF.current = nextToken;
      if (!isMountedRef.current) {
        return;
      }

      await register(nextToken);

      if (!isMountedRef.current) {
        return;
      }

      setState((prev) => ({ ...prev, isRegistering: false }));
    }

    execute().catch((error) => {
      if (!isMountedRef.current) {
        return;
      }

      console.error(error);

      // Just ignore, for now
    });
  }, [state, pushToken]);

  const shouldHide =
    !showAllow ||
    (hideUntilCertain &&
      (!state.permissionsChecked ||
        !knownTokens ||
        (state.isGranted && !state.tokenReceived)));

  const IS_PREVIEW =
    IS_TEST_RELEASE ||
    (!currentUser?.email.value.includes('store') &&
      currentUser?.email.value.endsWith('@tactile.events'));

  if (shouldHide) {
    if (registerError) {
      return (
        <ErrorPanel
          error={registerError}
          fallbackMessage="Failed to enable notifications"
        />
      );
    }

    if (IS_PREVIEW) {
      return (
        <Card
          theme={{ roundness: 8 }}
          elevation={0}
          style={{
            backgroundColor: primary,
            marginBottom: 12,
            marginHorizontal: 12,
          }}
        >
          <Card.Content>
            <Text style={{ color: textColor, fontWeight: 'bold' }}>
              Debugging information
            </Text>
            <Paragraph style={{ color: textColor }}>
              {pushToken || '<unknown>'} token for experience {EXPERIENCE_SLUG}.
              Granted: {state.isGranted ? 'yes' : 'no'}. Can ask again:{' '}
              {state.canAskAgain ? 'yes' : 'no'}.
            </Paragraph>
          </Card.Content>
        </Card>
      );
    }

    return null;
  }

  console.log(pushToken);

  const tokenRegistered = knownTokens?.some(
    (token) =>
      token.token === pushToken &&
      (token.expoSlug === EXPERIENCE_SLUG ||
        token.expoSlug === EXPERIENCE_SLUG.slice(1).replace('/', '') ||
        token.expoSlug === EXPERIENCE_SLUG.slice(1).replace('/', '--'))
  );

  if (tokenRegistered) {
    if (IS_PREVIEW) {
      return (
        <Card
          theme={{ roundness: 8 }}
          elevation={0}
          style={{
            backgroundColor: primary,
            marginBottom: 12,
            marginHorizontal: 12,
          }}
        >
          <Card.Content>
            <Text style={{ color: textColor, fontWeight: 'bold' }}>
              Debugging information
            </Text>
            <Paragraph style={{ color: textColor }}>
              Registered{' '}
              <Text selectable style={{ color: textColor }}>
                {pushToken}
              </Text>{' '}
              for {EXPERIENCE_SLUG}
            </Paragraph>
          </Card.Content>
        </Card>
      );
    }

    return null;
  }

  return (
    <React.Fragment>
      {registerError ? (
        <ErrorPanel
          error={registerError}
          fallbackMessage={`Failed to enable notifications: ${
            (registerError as any).message
          }`}
        />
      ) : null}
      {IS_PREVIEW ? (
        <Card
          theme={{ roundness: 8 }}
          elevation={0}
          style={{
            backgroundColor: primary,
            marginBottom: 12,
            marginHorizontal: 12,
          }}
        >
          <Card.Content>
            <Text style={{ color: textColor, fontWeight: 'bold' }}>
              Debugging information
            </Text>
            <Paragraph style={{ color: textColor }}>
              Found {pushToken} for {EXPERIENCE_SLUG} (
              {tokenRegistered ? 'true' : 'false'}). Known:{'\n\n'}
              {knownTokens?.map(
                (token) => `- ${token.expoSlug}: ${token.token}\n`
              )}
            </Paragraph>
          </Card.Content>
        </Card>
      ) : null}
      <Card
        onPress={enableAllow ? allow : undefined}
        style={{
          borderRadius: mobileView ? 0 : roundness,
        }}
      >
        <Card.Title
          title={i18n.t('app.notifications.permission.title')}
          subtitle={i18n.t('app.notifications.permission.subtitle')}
          left={(props) => (
            <Avatar.Image
              {...props}
              size={48}
              style={{ padding: 0, backgroundColor: 'transparent' }}
              source={require('../../assets/notification.png')}
            />
          )}
        />
        <Card.Content>
          <Paragraph>
            {i18n.t('app.notifications.permission.description')}.
          </Paragraph>
        </Card.Content>

        <Card.Actions>
          <PrimaryButton
            onPress={allow}
            disabled={!enableAllow}
            loading={state.isRegistering}
            style={{ marginLeft: 'auto' }}
          >
            {i18n.t('app.notifications.permission.enable')}
          </PrimaryButton>
        </Card.Actions>
      </Card>
      <Spacer space={1} />
    </React.Fragment>
  );
}

function ErrorPanel({
  error,
  fallbackMessage,
}: {
  error: unknown;
  fallbackMessage: string;
}) {
  if (error instanceof Error) {
    return <HelperText type="error">{error.message}</HelperText>;
  }
  return <HelperText type="error">{fallbackMessage}</HelperText>;
}
