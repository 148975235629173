import { MaterialCommunityIcons } from '@expo/vector-icons';
import { i18n, OutlinedButton, TextButton } from '@introcloud/blocks';
import {
  ProvideBlockData,
  useBlockDataBalance,
} from '@introcloud/blocks-interface';
import { useIsFocused } from '@react-navigation/native';
import Color from 'color';
import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { Platform, ScrollView, View } from 'react-native';
import {
  Button,
  Dialog,
  Divider,
  List,
  MD2Theme,
  Portal,
  Surface,
  Text,
  useTheme,
} from 'react-native-paper';
import { Header } from '../core/Header';
import { ThemedSnackbar } from '../core/ThemedSnackbar';
import { useCompanyTab } from '../hooks/useCompanyTabs';
import { useProvideBlockData } from '../hooks/useProvideBlockData';
import { ScanDialogContent } from './ScanDialog';
import { SendDialogContent } from './SendDialog';
import { TopUpDialogContent } from './TopUpDialog';
import {
  TransactionItem,
  TransactionsDialogContent,
} from './TransactionsDialog';
import { useCurrencyDisplay } from './useCurrencyDisplay';
import { useDefaultTopupAmount } from './useTopupAmounts';
import { TypedTransaction, useTransactions } from './useTransactions';

export function PaymentScreen({ asTab }: { asTab?: boolean }) {
  const {
    colors: { primary },
  } = useTheme();

  const colorIsDark = new Color(primary).isDark();
  const onColor = colorIsDark ? 'rgba(255, 255, 255, .9)' : 'rgba(0, 0, 0, .9)';
  const { title } = useCompanyTab('payment', 'account-cash');

  const blockData = useProvideBlockData();
  const isFocused = useIsFocused();

  const defaultAmount = useRef<number | null>(null);
  const receiver = useRef<string | null>(null);

  const [toppingUp, toggleTopUp] = useReducer((prev) => !prev, false);
  const [sending, toggleSend] = useReducer((prev) => !prev, false);
  const [scanning, toggleScan] = useReducer((prev) => !prev, false);
  const [transactions, toggleTransactions] = useReducer((prev) => !prev, false);
  const { active, show, hide, ref } = useSnackbar();

  // Reset recipient
  useEffect(() => {
    if (sending) {
      return;
    }

    receiver.current = null;
  }, [sending]);

  const topUpWithAmount = useCallback(
    (amount: number | null) => {
      defaultAmount.current = amount;
      toggleTopUp();
    },
    [defaultAmount, toggleTopUp]
  );

  const walletKey = [
    'wallet',
    toppingUp ? '1' : '0',
    sending ? '1' : '0',
    scanning ? '1' : '0',
    transactions ? '1' : '0',
    active ? '1' : '0',
  ].join('-');

  return (
    <View
      style={{
        flex: 1,
        width: '100%',
        backgroundColor: primary,
        overflow: 'hidden',
        maxHeight: Platform.select({ web: '100vh', default: '100%' }),
      }}
    >
      <Header
        hideBack={asTab}
        title={title || i18n.t('app.payment.title')}
        subTitle={undefined}
        showTranslate
      />

      <ProvideBlockData provider={blockData}>
        <ScrollView
          nativeID="scroller"
          style={{ flex: 1, width: '100%', maxHeight: '100%' }}
          contentContainerStyle={{
            maxWidth: 540,
            width: '100%',
            alignSelf: 'center',
            paddingTop: 160,
            paddingHorizontal: 20,
            paddingBottom: 64,
            overflow: 'hidden',
          }}
        >
          <WalletCard key={walletKey} onShowTransactions={toggleTransactions} />
          <QuickTopUpCard
            primary={primary}
            onColor={onColor}
            onTopUpWithAmount={topUpWithAmount}
          />
          <ShareCard
            onColor={onColor}
            onSend={toggleSend}
            onScan={toggleScan}
          />
        </ScrollView>

        <Portal>
          <Dialog
            visible={toppingUp}
            onDismiss={toggleTopUp}
            style={{
              maxWidth: 720,
              alignSelf: 'center',
              minWidth: 300,
              width: '90%',
            }}
          >
            <TopUpDialogContent
              visible={toppingUp && isFocused}
              defaultAmount={defaultAmount.current}
            />
            <Dialog.Actions>
              <TextButton onPress={toggleTopUp}>
                {i18n.t('app.payment.actions.negative_action')}
              </TextButton>
            </Dialog.Actions>
          </Dialog>

          <ProvideBlockData provider={blockData}>
            <Dialog
              visible={sending}
              onDismiss={toggleSend}
              style={{
                maxWidth: 720,
                alignSelf: 'center',
                minWidth: 300,
                width: '90%',
              }}
            >
              <SendDialogContent
                showMessage={show}
                visible={sending && isFocused}
                receiver={receiver.current}
              />
              <Dialog.Actions>
                <TextButton onPress={toggleSend}>
                  {i18n.t('app.payment.actions.negative_action')}
                </TextButton>
              </Dialog.Actions>
            </Dialog>
          </ProvideBlockData>

          <ProvideBlockData provider={blockData}>
            <Dialog
              visible={scanning}
              onDismiss={toggleScan}
              style={{
                maxWidth: 400,
                alignSelf: 'center',
                minWidth: 300,
                width: '90%',
                backgroundColor: 'transparent',
                elevation: 0,
                position: 'relative',
              }}
            >
              <ScanDialogContent
                showMessage={show}
                onDismiss={toggleScan}
                onScanned={(data: string) => {
                  if (scanning) {
                    toggleScan();
                    receiver.current = data;
                    toggleSend();
                  }
                }}
                visible={scanning && isFocused}
              />
            </Dialog>
          </ProvideBlockData>

          <ProvideBlockData provider={blockData}>
            <Dialog
              visible={transactions}
              onDismiss={toggleTransactions}
              style={{
                maxWidth: 720,
                alignSelf: 'center',
                minWidth: 300,
                width: '90%',
              }}
            >
              <TransactionsDialogContent visible={transactions && isFocused} />
              <Dialog.Actions>
                <TextButton onPress={toggleTransactions}>
                  {i18n.t('app.payment.actions.close')}
                </TextButton>
              </Dialog.Actions>
            </Dialog>
          </ProvideBlockData>
        </Portal>
        <Portal>
          <ThemedSnackbar
            active={active}
            onDismiss={hide}
            content={ref.current}
            action={{ label: 'Ok', onPress: hide }}
          />
        </Portal>
      </ProvideBlockData>
    </View>
  );
}

function useSnackbar() {
  const ref = useRef<string>('');
  const [active, setActive] = useState(false);

  const hide = useCallback(() => setActive(false), [setActive]);
  const show = useCallback(
    (next: string) => {
      ref.current = next;
      setActive(true);
    },
    [ref, setActive]
  );

  return { ref, active, hide, show };
}

function WalletCard({ onShowTransactions }: { onShowTransactions(): void }) {
  const {
    roundness,
    colors: { text },
  } = useTheme<MD2Theme>();

  const isFocused = useIsFocused();

  const { data } = useBlockDataBalance({
    enabled: isFocused,
    refetchOnWindowFocus: true,
  });

  const { display, tokens, symbol } = useCurrencyDisplay(
    data?.ok ? data.value : null
  );

  const transactions = useTransactions(data?.ok ? data : null);

  return (
    <Surface style={{ borderRadius: roundness, padding: 4, minHeight: 212 }}>
      <List.Subheader style={{ paddingBottom: 0 }}>
        {i18n.t('app.payment.wallet.title')}
      </List.Subheader>
      <View
        style={{
          paddingHorizontal: 16,
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 16,
        }}
      >
        {tokens ? (
          <View
            style={{
              width: 36,
              height: 36,
              marginLeft: 2,
              marginRight: 10,
              alignItems: 'center',
            }}
          >
            <Text
              style={{
                fontWeight: '700',
                fontSize: 40,
                color: text,
                includeFontPadding: false,
                lineHeight: 36,
              }}
            >
              {symbol}
            </Text>
          </View>
        ) : (
          <MaterialCommunityIcons
            name="wallet-outline"
            size={36}
            color={text}
            style={{ opacity: 0.8, marginLeft: -4, marginRight: 20 }}
          />
        )}

        <View style={{ flex: 1 }}>
          <Text
            style={{
              fontWeight: '700',
              fontSize: tokens ? 72 : 48,
              lineHeight: tokens ? 72 : 58,
              color: text,
              maxWidth: '100%',
            }}
            adjustsFontSizeToFit
          >
            {tokens ? `${display}` : `${symbol} ${display}`}
          </Text>
        </View>
      </View>

      <Divider
        style={{ marginHorizontal: 16, marginTop: 12, marginBottom: 4 }}
      />

      <View style={{ marginTop: 0 }}>
        <List.Subheader style={{ paddingBottom: 0 }}>
          {i18n.t('app.payment.wallet.last_transaction_title')}
        </List.Subheader>
      </View>

      {transactions[0] ? (
        <LastTransaction
          transaction={transactions[0]}
          onShowTransactions={onShowTransactions}
        />
      ) : null}
    </Surface>
  );
}

function LastTransaction({
  transaction,
  onShowTransactions,
}: {
  transaction: TypedTransaction;
  onShowTransactions: () => void;
}) {
  return (
    <View>
      <TransactionItem
        style={{ paddingVertical: 16, paddingHorizontal: 16 }}
        transaction={transaction}
        timestamp={new Date().getTime()}
      />

      <View>
        <OutlinedButton
          onPress={onShowTransactions}
          style={{ marginRight: 'auto', marginLeft: 16, marginBottom: 16 }}
        >
          {i18n.t('app.payment.actions.transactions')}
        </OutlinedButton>
      </View>
    </View>
  );
}

function QuickTopUpCard({
  primary,
  onColor,
  onTopUpWithAmount,
}: {
  primary: string;
  onColor: string;
  onTopUpWithAmount: (value: number | null) => void;
}) {
  const { roundness } = useTheme<MD2Theme>();
  const defaults = useDefaultTopupAmount();

  return (
    <View style={{ marginTop: 32 - 12, minHeight: 84 }}>
      <List.Subheader style={{ color: onColor }}>
        {i18n.t('app.payment.top-up.title')}
      </List.Subheader>

      <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
        {defaults ? (
          <TopUp
            amount={defaults}
            roundness={roundness}
            primary={primary}
            onColor={onColor}
            onTopUp={() => onTopUpWithAmount(defaults.cents)}
          />
        ) : null}

        <Button
          onPress={() => onTopUpWithAmount(null)}
          mode="contained"
          labelStyle={{ color: onColor, includeFontPadding: false }}
          textColor={onColor}
          buttonColor={primary}
          style={{
            borderRadius: roundness,

            minWidth: 85,

            marginRight: 4,
            marginBottom: 4,
          }}
          contentStyle={{
            borderRadius: roundness,

            borderStyle: 'solid',
            borderWidth: 2,
            borderColor: onColor,
          }}
        >
          {i18n.t('app.payment.actions.other_top_up')}
        </Button>
      </View>
    </View>
  );
}

function TopUp({
  amount,
  primary,
  roundness,
  onColor,
  onTopUp,
}: {
  primary: string;
  onColor: string;
  roundness: number;
  amount: { cents: number };
  onTopUp(): void;
}) {
  const display = useCurrencyDisplay(amount.cents);

  return (
    <Button
      onPress={onTopUp}
      mode="contained"
      labelStyle={{ color: primary, includeFontPadding: false }}
      textColor={primary}
      buttonColor={onColor}
      style={{
        borderRadius: roundness,

        minWidth: 85,

        marginRight: 4,
        marginBottom: 4,
      }}
      contentStyle={{
        borderRadius: roundness,

        borderStyle: 'solid',
        borderWidth: 2,
        borderColor: onColor,
      }}
    >
      {display.symbol} {display.display}
    </Button>
  );
}

function ShareCard({
  onColor,
  onSend,
  onScan,
}: {
  onColor: string;
  onSend: () => void;
  onScan: () => void;
}) {
  const { roundness } = useTheme<MD2Theme>();

  return (
    <View style={{ marginTop: 32 - 12 }}>
      <List.Subheader style={{ color: onColor }}>
        {i18n.t('app.payment.share.title')}
      </List.Subheader>

      <Surface style={{ borderRadius: roundness, marginTop: 0 }}>
        <List.Item
          title={i18n.t('app.payment.actions.share_via_qr')}
          titleStyle={{ includeFontPadding: false }}
          description={i18n.t('app.payment.share.share_via_qr_description')}
          descriptionStyle={{ includeFontPadding: false, marginTop: 4 }}
          right={({ color }) => (
            <List.Icon color={color} icon={(props) => <QrIcon {...props} />} />
          )}
          onPress={onScan}
        />
        <Divider />
        <List.Item
          title={i18n.t('app.payment.actions.share_via_group')}
          titleStyle={{ includeFontPadding: false }}
          description={i18n.t('app.payment.share.share_via_group_description')}
          descriptionStyle={{ includeFontPadding: false, marginTop: 4 }}
          right={({ color }) => (
            <List.Icon
              color={color}
              icon={(props) => <GroupIcon {...props} />}
            />
          )}
          onPress={onSend}
        />
      </Surface>
    </View>
  );
}

function QrIcon({
  color,
  size,
}: {
  color: string;
  size: number;
  allowFontScaling?: boolean;
}) {
  return (
    <MaterialCommunityIcons name="qrcode-scan" size={size} color={color} />
  );
}

function GroupIcon({
  color,
  size,
}: {
  color: string;
  size: number;
  allowFontScaling?: boolean;
}) {
  return (
    <MaterialCommunityIcons name="account-group" size={size} color={color} />
  );
}
