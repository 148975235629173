import {
  fetchApplicationUser,
  fetchUser,
  TactileUser,
} from '@introcloud/api-client';
import { FetchMediaError } from 'fetch-media';
import merge from 'lodash.merge';
import { useCallback, useRef } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { NotReady } from '../core/errors/NotReady';
import { SHOULD_DEBUG_FETCH } from '../utils';
import { useEndpoint, useSafeAuthorization } from './useAuthentication';

type Result = TactileUser & {
  security: { qr: { value: string } };
  bracelet: { state: { id: boolean } };
};

export function useUser({
  enabled = true,
  ...options
}: UseQueryOptions<Result | null, FetchMediaError | NotReady> = {}) {
  const authorization = useSafeAuthorization();
  const endpoint = useEndpoint();

  const fetcher = useCallback(
    async ({ signal }: { signal?: AbortSignal }) => {
      if (!endpoint || !authorization) {
        throw new NotReady();
      }

      const result = await fetchApplicationUser(
        endpoint,
        authorization!,
        signal,
        SHOULD_DEBUG_FETCH
      );

      const {
        _id,
        name: { full, first, middle, last },
        image: { profile },
        email: { value, anonymous },
        isAdmin,
        accessRef,
        chatId,
        security,
        bracelet,
      } = merge({ name: {}, image: {}, email: {} }, result as Result);

      const finalUser: Result = {
        _id,
        name: { full, first, middle, last },
        image: { profile },
        email: { value, anonymous },
        isAdmin,
        accessRef,
        chatId,
        security,
        bracelet,
      };

      return finalUser;
    },
    [endpoint, authorization]
  );

  const lastEndpoint = useRef(endpoint);
  lastEndpoint.current = endpoint;

  const {
    data: user,
    error,
    refetch,
    isFetching,
    ...others
  } = useQuery<Result | null, FetchMediaError | NotReady>(
    [endpoint, 'application', 'user', authorization],
    fetcher,
    {
      enabled: enabled && !!(authorization && endpoint),
      staleTime: 60 * 1000,
      ...options,
    }
  );

  return {
    data: user,
    error,
    reload: refetch,
    loading: others.isLoading,
    refreshing: isFetching,
    ...others,
  };
}

export function useUserSettings({
  enabled = true,
}: { enabled?: boolean } = {}) {
  const authorization = useSafeAuthorization();
  const endpoint = useEndpoint();

  const fetcher = useCallback(
    async ({ signal }: { signal?: AbortSignal }) => {
      if (!endpoint || !authorization) {
        throw new NotReady();
      }
      const result = await fetchUser(
        endpoint,
        authorization!,
        signal,
        SHOULD_DEBUG_FETCH
      );

      return (result as unknown as { settings: { chatNotification?: boolean } })
        .settings;
    },
    [endpoint, authorization]
  );

  const lastEndpoint = useRef(endpoint);
  lastEndpoint.current = endpoint;

  const {
    data: user,
    error,
    refetch,
    isFetching,
    ...others
  } = useQuery<
    { chatNotification?: boolean } | null,
    FetchMediaError | NotReady
  >([endpoint, 'application', 'user', authorization, 'settings'], fetcher, {
    enabled: enabled && !!(authorization && endpoint),
    staleTime: 60 * 1000,
  });

  return {
    data: user,
    error,
    reload: refetch,
    loading: others.isLoading,
    refreshing: isFetching,
    ...others,
  };
}
