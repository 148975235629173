import { defineTranslations } from '@introcloud/blocks';
import React from 'react';

import { NewsScreen } from './NewsScreen';

defineTranslations({
  en: {
    app: {
      news: {
        title: 'News',
        latest_news: 'Latest news',
        older_news: 'Older news',
        search: 'Search news items...',
      },
    },
  },

  nl: {
    app: {
      news: {
        title: 'Nieuws',
        latest_news: 'Het laatste nieuws',
        older_news: 'Ouder nieuws',
        search: 'Zoek in het nieuws...',
      },
    },
  },
});

export function NewsTab() {
  return <NewsScreen asTab />;
}
