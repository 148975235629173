import { TactilePage } from '@introcloud/api-client';
import { FetchMediaError } from 'fetch-media';
import { useCallback } from 'react';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { SEED_PAGES } from '../config';
import { PAGE_CACHE } from '../core/Cache';
import { useIsAuthenticated } from './useAuthentication';

function getPlaceholderPage(pageId: string): TactilePage | null {
  const result = PAGE_CACHE.get(pageId);
  if (result && result.content) {
    return result as TactilePage;
  }

  try {
    const bundled = SEED_PAGES[pageId];
    if (bundled) {
      const parsed = JSON.parse(bundled);
      if (parsed && parsed.content) {
        return parsed;
      }
    }
  } catch {}

  return null;
}

export function usePage(
  pageId: string | null | undefined,
  getInfoById: (
    pageId: string,
    opts: { signal?: AbortSignal }
  ) => Promise<TactilePage>,
  {
    enabled = true,
    ...options
  }: UseQueryOptions<TactilePage | null, FetchMediaError | Error> = {}
) {
  const isAuthenticated = useIsAuthenticated();
  const initialPage = pageId ? getPlaceholderPage(pageId) : null;

  const fetcher = useCallback(
    ({ signal }: { signal?: AbortSignal }) => getInfoById(pageId!, { signal }),
    [getInfoById, pageId]
  );

  const {
    data: page,
    error,
    ...others
  } = useQuery(['application', 'page', pageId] as QueryKey, fetcher, {
    placeholderData: initialPage || undefined,
    enabled: enabled && !!pageId && isAuthenticated,
    staleTime: 30 * 1000,
    ...options,
  });

  return {
    page,
    revalidate: others.refetch,
    error,
    loading: others.isLoading,
    ...others,
  };
}
