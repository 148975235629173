import { useBlockData, useBlockImageUrl } from '@introcloud/blocks-interface';
import React from 'react';
import { Image } from 'react-native';
import Animated from 'react-native-reanimated';
import { useViewport } from './ViewPort';

export function Background({ image }: { image: string }) {
  const { transform } = useViewport();
  const source = useBlockImageUrl(image, 'icon_1440').data || '';
  const {
    displayHeight,
    displayWidth,
    shape: { ratio: imageRatio },
  } = useViewport();

  if (!source) {
    return null;
  }

  return (
    <Animated.View style={{ transform }}>
      <Image
        style={{
          width: displayWidth,
          height: displayHeight,
        }}
        source={{ uri: source, width: imageRatio * 1440, height: 1440 }}
      />
    </Animated.View>
  );
}
