import {
  AndroidImportance,
  getPermissionsAsync,
  setNotificationChannelAsync,
} from 'expo-notifications';
import { Platform } from 'react-native';

export function configureNotifications() {
  if (Platform.OS === 'android') {
    getPermissionsAsync()
      .then((status) => {
        if (!status.granted) {
          return;
        }

        setNotificationChannelAsync('priority', {
          name: 'Priority Pushes',
          description:
            'Pushes when there are calendar changes last-minute or other important notifications',
          sound: 'defaultCritical',
          vibrationPattern: [0, 250, 250, 250],
          importance: AndroidImportance.MAX,
        });

        setNotificationChannelAsync('partners', {
          name: 'Partner Notifications',
          sound: null,
          importance: AndroidImportance.DEFAULT,
        });
      })
      .catch(() => {});
  }
}
