import {
  EventList,
  ResponsiveImage,
  TextBlock,
  i18n,
  useLocale,
} from '@introcloud/blocks';
import { useBlockData, useBlockImageUrl } from '@introcloud/blocks-interface';
import { useRoute } from '@react-navigation/native';
import React, { useMemo } from 'react';
import { Platform, ScrollView, View } from 'react-native';
import { localeLongWeekDayString } from 'react-native-time-helpers';
import { BlockProvision } from '../core/BlockProvision';
import { Header } from '../core/Header';
import { RouteProp } from '../core/Routes';
import { STATIC_SPONSORS_ENABLED } from '../features';
import { useCompanyEventDays } from '../hooks/useCompanyEventDays';
import { useDayBoundaries } from '../hooks/useDayBoundaries';
import { useEvents } from '../hooks/useEvents';
import { useTabTitle } from '../hooks/useTab';
import { extractEventData } from '../utils';
import { RandomSponsor, StaticSponsor } from './Sponsor';
import { isDuringDay } from './isDuringDay';

const RATIO: [number, number] = [16, 10];

export function EventDayScreen() {
  const route = useRoute<RouteProp<'EventDay'>>();
  const dayId = route.params['day'];
  const date = new Date(`${dayId}T11:00:00Z`);
  const day = localeLongWeekDayString(date);
  const title = useTabTitle('event-days') ?? i18n.t('app.calendar.title');

  return (
    <BlockProvision screen="EventDayScreen">
      <View
        style={{
          flex: 1,
          overflow: 'hidden',
          maxHeight: Platform.select({
            web: '100vh',
            default: '100%',
          }),
        }}
      >
        <Header
          title={title}
          subTitle={day}
          backFallback={{
            screen: 'Tabs',
            params: { screen: 'EventDays' },
          }}
          showTranslate
        />
        <ScrollView
          nativeID="scroller"
          style={{
            flex: 1,
            maxHeight: '100%',
          }}
          contentContainerStyle={{
            maxWidth: 720,
            alignSelf: 'center',
            paddingBottom: 56,
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <View style={{ width: '100%' }}>
            <EventDayImage day={dayId} />
            <TextBlock text={day} options={{ size: 'title' }} />
            <EventDayList date={date} />
            <Sponsor day={dayId} />
          </View>
        </ScrollView>
      </View>
    </BlockProvision>
  );
}

function EventDayList({ date }: { date: Date | number | string }) {
  const { getImageUrl } = useBlockData();
  const [dayBoundaryStart, dayBoundaryEnd] = useDayBoundaries(date);
  const locale = useLocale();

  const { data: items } = useEvents();
  const events = useMemo(
    () =>
      (items || [])
        .filter((event) => {
          return (
            event.hierarchy.showInCalendar &&
            isDuringDay(
              dayBoundaryStart,
              dayBoundaryEnd,
              event.duration.start.unix,
              event.duration.end.unix
            )
          );
        })
        .map(
          (event) =>
            extractEventData(
              event,
              { loading: false },
              { getImageUrl },
              locale
            ) || event._id
        ),
    [items, dayBoundaryStart, dayBoundaryEnd, locale]
  );

  return <EventList events={events} />;
}

function EventDayImage({ day }: { day: string }) {
  const days = useCompanyEventDays();
  const image = (days?.images || {})[day];
  const { data: imageSource } = useBlockImageUrl(image || null, 'icon_1440');

  if (!imageSource) {
    return null;
  }

  return (
    <ResponsiveImage
      src={imageSource || ''}
      maxWidth={720}
      ratio={RATIO[0] / RATIO[1]}
      mode="cover"
    />
  );
}

function Sponsor({ day }: { day: string }) {
  if (STATIC_SPONSORS_ENABLED) {
    return <StaticSponsor day={day} />;
  }
  return <RandomSponsor day={day} />;
}
