import { i18n } from '@introcloud/blocks';
import * as Localization from 'expo-localization';
import { DEFAULT_LOCALE } from './config';

i18n.defaultLocale = DEFAULT_LOCALE;
i18n.enableFallback = true;
// { nl: 'en', en: 'nl' };

export const deviceLocale =
  ((Localization.locale || '').split('-') || [i18n.defaultLocale])[0] ??
  i18n.defaultLocale;

export function resetLocale(
  options: string[] = ['en', 'nl'],
  wanted = deviceLocale
): string {
  i18n.locale =
    options.find((value) => value === wanted) ||
    options.find((value) => value === i18n.defaultLocale) ||
    options[0];
  i18n.defaultLocale = i18n.locale;

  return i18n.locale;
}

resetLocale();
