import { fetchApplicationGroups, TactileGroup } from '@introcloud/api-client';
import { useIsFocused } from '@react-navigation/core';
import { FetchMediaError } from 'fetch-media';
import { useCallback } from 'react';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { useIsMounted } from 'use-is-mounted';
import { NotReady } from '../core/errors/NotReady';
import {
  AnyMemoryValue,
  StoredMemoryValue,
  useMutableMemoryValue,
} from '../storage';
import { SHOULD_DEBUG_FETCH } from '../utils';
import {
  runOnLogout,
  useEndpoint,
  useSafeAuthorization,
} from './useAuthentication';

const GROUPS: AnyMemoryValue<readonly TactileGroup[] | null> =
  new StoredMemoryValue<TactileGroup[]>('application.groups.v1');

// TODO: consider secure storage

runOnLogout(() => {
  GROUPS.emit(null);
});

export function useGroupsDetached({
  enabled = true,
  ...options
}: UseQueryOptions<
  readonly TactileGroup[] | null,
  FetchMediaError | Error
> = {}) {
  const authorization = useSafeAuthorization();
  const endpoint = useEndpoint();
  const isMounted = useIsMounted();
  const [storedGroups, setStoredGroups] = useMutableMemoryValue(GROUPS);

  const fetcher = useCallback(
    async ({ signal }: { signal?: AbortSignal }) => {
      if (!authorization || !endpoint) {
        throw new NotReady();
      }

      const result = await fetchApplicationGroups(
        endpoint,
        authorization,
        signal,
        SHOULD_DEBUG_FETCH
      );

      isMounted.current && setStoredGroups(result);

      return result;
    },
    [authorization, endpoint, setStoredGroups]
  );

  return useQuery([endpoint, 'application', 'groups'] as QueryKey, fetcher, {
    placeholderData: storedGroups,
    enabled: !!(authorization && endpoint) && enabled,
    staleTime: 10 * 60 * 1000,
    ...options,
  });
}

export function useGroups({ enabled = true }: { enabled?: boolean } = {}) {
  const isFocused = useIsFocused();
  return useGroupsDetached({ enabled: enabled && isFocused });
}
