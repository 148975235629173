import { fetchChatInfo } from '@introcloud/api-client';
import { useCallback } from 'react';
import { NotReady } from '../core/errors/NotReady';
import { SHOULD_DEBUG_FETCH } from '../utils';
import { useEndpoint, useSafeAuthorization } from './useAuthentication';

type ModelName = Parameters<typeof fetchChatInfo>[0];

export function useChatInfoFetcher(modelName: ModelName) {
  const authorization = useSafeAuthorization();
  const endpoint = useEndpoint();

  return useCallback(
    (id: string, { signal }: { signal?: AbortSignal }) => {
      if (!endpoint || !authorization) {
        throw new NotReady();
      }

      return fetchChatInfo(
        modelName,
        id,
        endpoint,
        authorization,
        signal,
        SHOULD_DEBUG_FETCH
      );
    },
    [authorization, endpoint, modelName]
  );
}
