import { OutlinedButton, TextButton, i18n } from '@introcloud/blocks';
import {
  BarCodeEvent,
  BarCodeScanner,
  requestPermissionsAsync,
} from 'expo-barcode-scanner';
import { Fragment, useEffect, useReducer, useState } from 'react';
import {
  ActivityIndicator,
  Image,
  Platform,
  StyleSheet,
  View,
} from 'react-native';
import {
  Dialog,
  HelperText,
  IconButton,
  Surface,
  useTheme,
} from 'react-native-paper';
import { useUser } from '../hooks/useUser';

export function ScanDialogContent({
  visible,
  showMessage,
  onDismiss,
  onScanned,
}: {
  visible: boolean;
  showMessage: (next: string) => void;
  onDismiss: () => void;
  onScanned: (id: string) => void;
}) {
  const [hasPermission, setHasPermission] = useState<boolean | null>(null);

  const { data: user, isLoading } = useUser();
  const {
    roundness,
    dark,
    colors: { surface },
  } = useTheme();

  const [mode, toggleMode] = useReducer(
    (mode) => (mode === 'showing' ? 'scanning' : 'showing'),
    'showing'
  );

  useEffect(() => {
    (async () => {
      const { status } = await requestPermissionsAsync();
      setHasPermission(status === 'granted');
    })();
  }, [setHasPermission]);

  if (isLoading) {
    return (
      <Fragment>
        <Dialog.Title>
          {mode === 'showing'
            ? i18n.t('app.payment.showing.title')
            : i18n.t('app.payment.scanning.title')}
        </Dialog.Title>
        <View style={{ justifyContent: 'center', minHeight: 200 }}>
          <ActivityIndicator />
        </View>
      </Fragment>
    );
  }

  return (
    <Surface
      style={{
        position: 'relative',
        borderRadius: roundness,
        paddingBottom: 12,
      }}
    >
      <Dialog.Title>
        {mode === 'showing'
          ? i18n.t('app.payment.showing.title')
          : i18n.t('app.payment.scanning.title')}
      </Dialog.Title>
      <IconButton
        icon="close"
        style={{ position: 'absolute', right: 8, top: 12 }}
        onPress={onDismiss}
      />

      {mode === 'showing' ? (
        <Surface
          style={{
            width: 250 + 0,
            height: 250 + 0,
            marginTop: 0,
            marginBottom: 16,
            borderRadius: 8,
            overflow: 'hidden',
            marginHorizontal: 'auto',
            elevation: 1,
            backgroundColor: '#f0f0f0',
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
          }}
        >
          <Image
            source={{
              uri: `https://api.tactile.events/api/image/qr/${user?._id}/png`,
              width: 150,
              height: 150,
            }}
            resizeMode="contain"
            style={{
              width: 250,
              height: 250,
              marginHorizontal: 'auto',
            }}
          />
        </Surface>
      ) : null}

      {mode === 'scanning' ? (
        <Surface
          style={{
            width: 250 + 0,
            height: 250 + 0,
            marginTop: 0,
            marginBottom: 16,
            borderRadius: 8,
            overflow: 'hidden',
            marginHorizontal: 'auto',
            elevation: 1,
            backgroundColor: '#FFF',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            alignSelf: 'center',
          }}
        >
          <Camera visible={visible} onScanned={onScanned} />
        </Surface>
      ) : null}

      <View
        style={{
          flexDirection: 'row',
          height: 44,
          width: 250,
          justifyContent: 'center',
          alignItems: 'center',
          alignSelf: 'center',
          marginHorizontal: 'auto',
        }}
      >
        <OutlinedButton
          icon="qrcode"
          onPress={mode === 'scanning' ? toggleMode : undefined}
          textColor={mode === 'scanning' ? 'black' : 'white'}
          style={{
            paddingRight: mode === 'scanning' ? 24 : undefined,
            marginRight: mode === 'scanning' ? -26 : -2,
            paddingLeft: 6,
            zIndex: mode === 'scanning' ? 0 : 1,
            backgroundColor: mode === 'scanning' ? 'white' : 'black',
            borderTopRightRadius: mode === 'scanning' ? 0 : undefined,
            borderBottomRightRadius: mode === 'scanning' ? 0 : undefined,
          }}
        >
          QR-Code
        </OutlinedButton>
        <OutlinedButton
          icon="camera"
          onPress={mode === 'showing' ? toggleMode : undefined}
          textColor={mode === 'showing' ? 'black' : 'white'}
          style={{
            paddingLeft: mode === 'showing' ? 24 : undefined,
            marginLeft: mode === 'showing' ? -26 : -2,
            paddingRight: 6,
            zIndex: mode === 'showing' ? 0 : 1,
            backgroundColor: mode === 'showing' ? 'white' : 'black',
            borderTopLeftRadius: mode === 'showing' ? 0 : undefined,
            borderBottomLeftRadius: mode === 'showing' ? 0 : undefined,
          }}
        >
          Camera
        </OutlinedButton>
      </View>
    </Surface>
  );
}

const ACCEPTED_TYPES = [BarCodeScanner.Constants.BarCodeType.qr];

function Camera({
  visible,
  onScanned,
}: {
  visible: boolean;
  onScanned(id: string): void;
}) {
  const handleBarCodeScanned = (data: BarCodeEvent) => {
    console.log(data);

    if (data.data.length >= 24 && data.data.length <= 39) {
      if (/^[a-f0-9]+$/.test(data.data)) {
        onScanned(data.data);
      }
    }
  };

  if (Platform.OS === 'web') {
    return (
      <Surface
        style={{
          width: 250 + 0,
          height: 250 + 0,
          borderRadius: 8,
          overflow: 'hidden',
          marginHorizontal: 'auto',
          elevation: 0,
          backgroundColor: '#f0f0f0',
          justifyContent: 'center',
          alignItems: 'center',
          alignSelf: 'center',
        }}
      >
        <HelperText type="error">Not available on web</HelperText>
      </Surface>
    );
  }

  return (
    <BarCodeScanner
      onBarCodeScanned={visible ? handleBarCodeScanned : () => {}}
      style={StyleSheet.absoluteFillObject}
      barCodeTypes={ACCEPTED_TYPES}
    />
  );
}
