import { Platform, View } from 'react-native';

export function StickyRow({
  height,
  children,
}: {
  height: number;
  children: React.ReactNode;
}) {
  return (
    <View
      style={{
        height,
        position: Platform.select({
          web: 'sticky',
          default: 'relative',
        }) as 'relative',
        left: 0,
        width: 600,
      }}
    >
      {children}
    </View>
  );
}
