import {
  fetchApplicationLocations,
  TactileLocation,
  TactileNoPageRef,
  TactilePageRef,
} from '@introcloud/api-client';
import { cleanTag, locationTagIconFor, useLocale } from '@introcloud/blocks';
import { localize } from '@introcloud/blocks/dist/useLocale';
import { useIsFocused } from '@react-navigation/core';
import { FetchMediaError } from 'fetch-media';
import { useCallback } from 'react';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { useIsMounted } from 'use-is-mounted';
import { LOCATION_CACHE } from '../core/Cache';
import { NotReady } from '../core/errors/NotReady';
import { StoredMemoryValue, useMutableMemoryValue } from '../storage';
import { merge, SHOULD_DEBUG_FETCH } from '../utils';
import {
  runOnLogout,
  useEndpoint,
  useSafeAuthorization,
} from './useAuthentication';

const LOCATIONS = new StoredMemoryValue<readonly PreparedLocation[]>(
  'application.locations.v1'
);

runOnLogout(() => {
  LOCATIONS.emit(null);
});

export type PreparedLocation = Omit<
  TactileLocation & {
    page: boolean;
  },
  'pageRef'
> & { pageRef: TactileNoPageRef | TactilePageRef };

export function useLocations({
  enabled = true,
  ...options
}: UseQueryOptions<
  readonly PreparedLocation[] | null,
  FetchMediaError | Error
> = {}) {
  const isFocused = useIsFocused();
  const endpoint = useEndpoint();
  const authorization = useSafeAuthorization();
  const [storedLocations, setStoredLocations] =
    useMutableMemoryValue(LOCATIONS);
  const isMountedRef = useIsMounted();
  const locale = useLocale();

  const fetcher = useCallback(
    async ({ signal }: { signal?: AbortSignal }) => {
      if (!endpoint || !authorization) {
        throw new NotReady();
      }

      const result = await fetchApplicationLocations(
        endpoint,
        authorization!,
        signal,
        SHOULD_DEBUG_FETCH
      );

      const sorted = await sortLocations(result);
      const cached = await cacheLocations(sorted);
      const prepared = await prepareLocations(cached, locale);

      isMountedRef.current && setStoredLocations(prepared);

      return prepared;
    },
    [endpoint, authorization, setStoredLocations, locale]
  );

  const {
    data: locations,
    error,
    ...others
  } = useQuery([endpoint, 'application', 'locations'] as QueryKey, fetcher, {
    placeholderData: storedLocations,
    enabled: enabled && isFocused && !!(authorization && endpoint),
    staleTime: 5 * 60 * 1000,
    ...options,
  });

  return {
    data: locations,
    loading: others.isLoading,
    error,
    reload: others.refetch,
    refreshing: others.isFetching && !others.isLoading,
    ...others,
  };
}

export async function sortLocations(
  locations: readonly TactileLocation[]
): Promise<readonly TactileLocation[]> {
  return locations.slice().sort((a, b) => {
    const compareName = (a.name?.full || '').localeCompare(b.name?.full || '');
    if (compareName !== 0) {
      return compareName;
    }

    return a._id.localeCompare(b._id);
  });
}

export async function prepareLocations(
  locations: readonly TactileLocation[],
  locale: string
): Promise<readonly PreparedLocation[]> {
  return locations.map((location) => prepareLocation(location, locale));
}

export function prepareLocation(
  location: TactileLocation,
  locale: string
): PreparedLocation {
  const localizedName = localize(
    location.nameLocalized?.full,
    location.name.full,
    locale
  );
  const localizedDescription = localize(
    location.nameLocalized?.description,
    location.name.description,
    locale
  );
  const localizedTags =
    localize(location.nameLocalized?.tag, location.name.tag) || [];

  return {
    ...location,
    page: !!(location.pageRef && location.pageRef.pageId),
    name: {
      ...location.name,
      full: localizedName,
      description: localizedDescription,
      tag: localizedTags.map(cleanTag).filter((tag) => locationTagIconFor(tag)),
    },
  };
}

function cacheLocations(result: readonly TactileLocation[]) {
  const currentValue = LOCATION_CACHE.current;
  const nextValue = toMap(result);

  Object.keys(currentValue).forEach((key) => {
    if (!nextValue[key]) {
      delete currentValue[key];
    }
  });

  merge(currentValue, nextValue);
  return result;
}

function toMap<T extends { _id: string }>(
  items: readonly T[]
): Record<string, T> {
  return items.reduce((result, item) => {
    result[item._id] = item;
    return result;
  }, {} as Record<string, T>);
}
