import { TactileLocation } from '@introcloud/api-client';
import React from 'react';
import { MAP_SWIPER_ENABLED } from '../features';
import { PreparedLocation } from '../hooks/useLocations';
import { useTabConfiguration } from '../hooks/useTab';
import { MapSelectedOverlay } from './MapSelectedOverlay';
import { MapSwiperOverlay } from './MapSwiperOverlay';

export function ContextOverlay({
  selected,
  locations,
  filter,
}: {
  selected?: TactileLocation | null;
  locations?: readonly PreparedLocation[];
  filter?: string | null;
}) {
  const configuration = useTabConfiguration('locations');

  if (MAP_SWIPER_ENABLED || configuration?.swiper) {
    return (
      <MapSwiperOverlay
        selected={selected}
        locations={locations}
        filter={filter}
      />
    );
  }

  return <MapSelectedOverlay selected={selected} />;
}
