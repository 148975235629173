import { i18n, localeSmartTimeString } from '@introcloud/blocks';
import { useBlockDataBalance } from '@introcloud/blocks-interface';
import Color from 'color';
import { Platform, ScrollView, StyleProp, View, ViewStyle } from 'react-native';
import { Dialog, MD2Theme, Text, useTheme } from 'react-native-paper';
import { useInaccurateTimestamp } from 'react-native-use-timestamp';
import { useCurrencyDisplay } from './useCurrencyDisplay';
import { TypedTransaction, useTransactions } from './useTransactions';

export function TransactionsDialogContent({ visible }: { visible: boolean }) {
  const { data } = useBlockDataBalance({
    enabled: visible,
    refetchOnWindowFocus: true,
  });
  const transactions = useTransactions(data?.ok ? data : null);

  const timestamp = useInaccurateTimestamp({ every: 60 * 1000 });
  const last50 = transactions.slice(0, 50);

  return (
    <View style={{ position: 'relative' }}>
      <Dialog.Title>{i18n.t('app.payment.transactions.title')}</Dialog.Title>

      <Dialog.ScrollArea style={{ paddingHorizontal: 0, maxHeight: 300 }}>
        <ScrollView contentContainerStyle={{ margin: 0, paddingHorizontal: 0 }}>
          {last50.map((transaction) => (
            <TransactionItem
              transaction={transaction}
              key={transaction._id}
              timestamp={timestamp}
              style={{ paddingVertical: 16, paddingHorizontal: 22 }}
            />
          ))}
        </ScrollView>
      </Dialog.ScrollArea>
    </View>
  );
}

export function TransactionItem({
  transaction,
  timestamp,
  style,
}: {
  transaction: TypedTransaction;
  timestamp: number;
  style: StyleProp<ViewStyle>;
}) {
  const value =
    transaction.type === 'withdrawal'
      ? -1 * transaction.value
      : transaction.value;
  const { display, symbol } = useCurrencyDisplay(value);

  const {
    colors: { surface: onLabel, text: label, primary },
  } = useTheme<MD2Theme>();

  const onPrimary = new Color(primary).isDark() ? '#FFF' : '#000';

  return (
    <View style={style}>
      <View style={{ flexDirection: 'row' }}>
        <View
          style={{
            backgroundColor: value > 0 ? primary : label,
            borderRadius: 4,
            paddingVertical: 2.5,
            paddingHorizontal: 4,
            marginRight: 4,
            opacity: value === 0 ? 0.6 : 1,
            position: 'relative',
            top: Platform.select({ web: -1, default: 1 }),
          }}
        >
          <Text
            style={{
              color: value > 0 ? onPrimary : onLabel,
              includeFontPadding: false,
              fontSize: 12,
              fontWeight: '500',
            }}
          >
            {symbol} {value > 0 ? '+' : ''}
            {display}
          </Text>
        </View>
        <Text>{transaction.name}</Text>
      </View>

      {transaction.unix ? (
        <Text style={{ fontSize: 12, opacity: 0.8, marginTop: 2 }}>
          {localeSmartTimeString(
            new Date(transaction.unix),
            timestamp,
            undefined,
            true,
            true
          )}
        </Text>
      ) : null}
    </View>
  );
}
