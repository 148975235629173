import { TactileSponsor } from '@introcloud/api-client';
import {
  EventListItem,
  eventTagIconFor,
  i18n,
  Spacer,
  TextButton,
  useLocale,
} from '@introcloud/blocks';
import { useBlockData } from '@introcloud/blocks-interface';
import { localize } from '@introcloud/blocks/dist/useLocale';
import { useIsMobileView } from '@introcloud/page/dist/utils/useIsMobileView';
import Color from 'color';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import {
  Platform,
  SectionList,
  SectionListData,
  StyleSheet,
  TextInput,
  View,
} from 'react-native';
import {
  List,
  MD2Theme,
  Searchbar,
  Surface,
  Text,
  useTheme,
} from 'react-native-paper';
import { localeDateString } from 'react-native-time-helpers';
import { BlockProvision } from '../core/BlockProvision';
import { FilterMenu } from '../core/FilterMenu';
import { Header } from '../core/Header';
import { STATIC_SPONSORS_ENABLED } from '../features';
import { PreparedEvent, useEvents } from '../hooks/useEvents';
import { useSponsors } from '../hooks/useSponsors';
import { extractEventData } from '../utils';
import { RemoteSponsor, StaticSponsor } from './Sponsor';

export function EventsScreen({
  asTab,
  title,
}: {
  asTab?: boolean;
  title?: string;
}) {
  return (
    <BlockProvision screen="EventsScreen">
      <View
        style={{
          flex: 1,
          overflow: 'hidden',
          maxHeight: Platform.select({
            web: '100vh',
            default: '100%',
          }),
        }}
      >
        <Header
          title={title || i18n.t('app.calendar.title')}
          subTitle={undefined}
          hideBack={asTab}
          showTranslate
        />
        <Calendar />
      </View>
    </BlockProvision>
  );
}

const EMPTY: readonly PreparedEvent[] = [];
const EMPTY_SPONSORS: readonly Omit<TactileSponsor, 'pageRef'>[] = [];

function Calendar() {
  // TODO get events directly from BlockProvision?
  const { data: events } = useEvents();
  const { data: sponsors } = useSponsors();
  const locale = useLocale();

  const [isPast, togglePast] = useReducer((prev) => !prev, false);
  const [searchActive, setSearchActive] = useState(false);
  const [queriedEvents, setQueriedEvents] = useState(() => events || []);

  const sections = useEventSections(
    queriedEvents || EMPTY,
    sponsors || EMPTY_SPONSORS,
    isPast
  );

  const onQueryChanged = useCallback(
    (query: string, activeTag: string) => {
      if (!events) {
        return [];
      }

      const parts = query
        .toLocaleLowerCase()
        .split(' ')
        .filter((item) => item.trim().length);

      const taggedEvents = activeTag
        ? events.filter((item) => {
            const tags = localize(item.nameLocalized?.tag, item.name.tag) || [];
            return tags.includes(activeTag);
          })
        : events;

      setQueriedEvents(
        parts.length > 0
          ? taggedEvents.filter((item) => {
              const names = [
                item.name.full,
                localize(item.nameLocalized?.full, ''),
              ]
                .filter(Boolean)
                .join(' ')
                .toLocaleLowerCase();
              return parts.every((p) => names.includes(p));
            })
          : taggedEvents
      );
    },
    [events, locale]
  );

  const Header = (
    <Fragment>
      <CalendarSearch
        active={searchActive}
        events={events || EMPTY}
        onQueryChanged={onQueryChanged}
        onChangeActive={setSearchActive}
      />
      <View style={{ marginBottom: 16 }}>
        <TextButton onPress={togglePast} style={{ marginLeft: 'auto' }}>
          {isPast
            ? i18n.t('app.calendar.actions.future')
            : i18n.t('app.calendar.actions.past')}
        </TextButton>
      </View>
    </Fragment>
  );

  return (
    <SectionList
      nativeID="scroller"
      style={{
        flex: 1,
        maxHeight: '100%',
      }}
      sections={sections}
      keyExtractor={extractKey}
      ListHeaderComponent={Header}
      initialNumToRender={15}
      renderSectionHeader={renderHeader}
      renderItem={renderItem}
      renderSectionFooter={renderFooter}
      contentContainerStyle={{
        maxWidth: 720,
        alignSelf: 'center',
        width: '100%',
      }}
    />
  );
}

function useUnixCalendarBoundary() {
  return useMemo(() => {
    const date = new Date();
    date.setUTCHours(0, 0, 0, 0);
    return date.getTime() - 1000 * 60 * 60 * 24;
  }, []);
}

function CalendarSearch({
  onQueryChanged,
  onChangeActive,
  events,
  active,
}: {
  onQueryChanged(query: string, tag: string | null): void;
  onChangeActive(next: boolean | ((prev: boolean) => boolean)): void;
  active: boolean;
  events: readonly PreparedEvent[];
}) {
  useLocale();

  const [query, setQuery] = useState('');
  const [tag, setTag] = useState<string | null>(null);

  const searchBar =
    useRef<
      Pick<
        TextInput,
        'blur' | 'focus' | 'setNativeProps' | 'isFocused' | 'clear'
      >
    >(null);

  const doToggleActive = useCallback(
    () => onChangeActive((prev) => !prev),
    [onChangeActive]
  );
  const setActive = useCallback(() => onChangeActive(true), [onChangeActive]);

  // Focus when it becomes active
  useEffect(() => {
    if (active && searchBar.current) {
      searchBar.current.focus();
    }
  }, [active, searchBar]);

  // Update query with debounce
  useEffect(() => {
    const debounceTimer = setTimeout(() => onQueryChanged(query, tag), 220);
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [events, query, tag, active]);

  // Get the list of tags
  const tags = useMemo(
    () =>
      events
        .reduce((final, item) => {
          const tags = localize(item.nameLocalized?.tag, item.name.tag) || [];
          final.push(...tags);
          return final;
        }, [] as string[])
        .filter(Boolean)
        .filter((tag, index, self) => self.indexOf(tag) === index)
        .sort(),
    [events]
  );

  const showFilter = tags.length >= 1 && !active;
  const isMobileView = useIsMobileView();

  return (
    <View
      style={[
        {
          zIndex: 3,
          position: 'relative',
          alignSelf: 'center',
          width: '100%',
          maxWidth: 720,
          marginVertical: 16,
        },
      ]}
    >
      <Searchbar
        selectTextOnFocus
        icon={active ? 'arrow-left' : 'text-box-search'}
        onIconPress={doToggleActive}
        onFocus={setActive}
        placeholder={i18n.t('app.calendar.search')}
        onChangeText={setQuery}
        value={query}
        style={[
          { marginHorizontal: isMobileView ? 8 : 0 },
          {
            elevation: 1,
            zIndex: 0,
          },
        ]}
      />

      {showFilter ? (
        <View
          style={{
            position: 'absolute',
            right: 8,
            top: 6,
            backgroundColor: 'transparent',
            elevation: 4,
            maxWidth: 170,
          }}
        >
          <FilterMenu
            onSelect={setTag}
            tags={tags}
            selected={tag}
            defaultIcon="calendar-blank"
            tagToIcon={eventTagIconFor}
          />
        </View>
      ) : null}
    </View>
  );
}

type Item =
  | { type: 'event'; event: PreparedEvent }
  | { type: 'sponsor'; day: string; sponsorId?: string };

function extractKey(item: Item, index: number) {
  switch (item.type) {
    case 'event': {
      return item.event._id + '-' + index;
    }
    case 'sponsor': {
      return item.day;
    }
  }
}

function renderItem({ item }: { item: Item }) {
  switch (item.type) {
    case 'event': {
      return <EventItem event={item.event} key={item.event._id} />;
    }
    case 'sponsor': {
      if (STATIC_SPONSORS_ENABLED) {
        return <StaticSponsor key={item.day} day={item.day} />;
      }

      if (!item.sponsorId) {
        return null;
      }

      return (
        <RemoteSponsor
          key={item.day}
          day={item.day}
          sponsorId={item.sponsorId}
        />
      );
    }
  }
}

function EventItem({ event }: { event: PreparedEvent }) {
  const { getImageUrl } = useBlockData();
  const locale = useLocale();

  return (
    <Surface style={{ elevation: 1, width: '100%' }}>
      <EventListItem
        source={
          extractEventData(
            event,
            { isLast: true, loading: false },
            { getImageUrl },
            locale
          ) || event._id
        }
        isLast
      />
    </Surface>
  );
}

function renderHeader({ section }: { section: SectionListData<Item> }) {
  const date = new Date(`${section.date}T11:00:00`);
  return <SectionHeader date={date} />;
}

function SectionHeader({ date }: { date: Date }) {
  const {
    colors: { accent },
  } = useTheme<MD2Theme>();

  const color = new Color(accent);

  const isDark = color.isDark();
  const textColor = isDark ? '#fff' : '#121212';

  return (
    <Surface
      style={{ elevation: Platform.OS === 'ios' ? 2 : 1 }}
      theme={{ colors: { surface: accent } }}
    >
      <List.Subheader style={styles.header}>
        <Text style={[styles.headerText, { color: textColor }]}>
          {localeDateString(date)}
        </Text>
      </List.Subheader>
    </Surface>
  );
}

function renderFooter() {
  return <Spacer space={2} />;
}

function useEventSections(
  events: readonly PreparedEvent[],
  sponsors: readonly Omit<TactileSponsor, 'pageRef'>[],
  isPast: boolean
) {
  const unixCalendarBoundary = useUnixCalendarBoundary();

  const showEvents = useMemo(
    () => events.filter((event) => event.hierarchy.showInCalendar) || [],
    [events]
  );

  const boundaryEvents = useMemo(
    () =>
      showEvents.filter((event) =>
        isPast
          ? event.duration.end.unix < unixCalendarBoundary
          : event.duration.end.unix >= unixCalendarBoundary
      ),
    [isPast, unixCalendarBoundary, showEvents]
  );

  const showSponsors = useMemo(
    () =>
      sponsors.filter(
        (sponsor) => sponsor.kind === 'event' && sponsor.weight > 0
      ),
    [sponsors]
  );

  const sections = useMemo(() => {
    let lastDate: number | null = null;

    const [first, last] = boundaryEvents.reduce(
      (results, event) => {
        if (event.hierarchy.isMain) {
          return results;
        }

        if (results[0] === undefined) {
          return [event.duration.start.unix, event.duration.end.unix];
        }

        const nextFirst = Math.min(results[0], event.duration.start.unix);
        const nextLast = Math.max(results[1], event.duration.end.unix);
        return [nextFirst, nextLast];
      },
      [undefined, undefined] as [number, number] | [undefined, undefined]
    );

    const sections = boundaryEvents.reduce((days, event) => {
      const date = new Date(event.duration.start.unix);
      date.setHours(12);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);

      if (lastDate !== date.getTime()) {
        days.push({ date: date.toISOString().split('T').shift()!, data: [] });
        lastDate = date.getTime();
      }

      const { data } = days[days.length - 1];
      data.push({ type: 'event', event });

      return days;
    }, [] as { date: string; data: Item[] }[]);

    return sections.map((section) => {
      return {
        ...section,
        data: [
          ...section.data,
          { type: 'sponsor' as const, day: section.date },
        ],
      };
    });
  }, [boundaryEvents]);

  return useMemo(() => {
    if (showSponsors.length === 0) {
      return sections;
    }

    let unpicked = showSponsors.reduce((result, sponsor) => {
      const repeated = new Array(sponsor.weight).fill(sponsor) as typeof result;
      return result.concat(repeated);
    }, [] as typeof showSponsors);

    return sections.map((section) => {
      // Nothing left to pick
      if (unpicked.length === 0) {
        return section;
      }

      // Weighted pick
      const picked = unpicked[Math.floor(Math.random() * unpicked.length)];
      unpicked = unpicked.filter((u) => u._id !== picked._id);

      section.data.splice(section.data.length - 1, 1, {
        type: 'sponsor' as const,
        day: section.date,
        sponsorId: picked._id,
      });
      return section;
    });
  }, [sections, showSponsors]);
}

const styles = StyleSheet.create({
  header: {
    width: '100%',
    justifyContent: 'space-around',
  },
  headerText: {
    fontSize: Platform.OS === 'ios' ? 16 : 18,
  },
});

/*
  const sponsorOptions = sponsorsRef.current || (await reloadSponsors());

      if (!sponsorOptions || sponsorOptions.length === 0) {
        throw new Error('No sponsors to show');
      }

      const kindly = sponsorOptions.filter(
        (sponsor) => sponsor.kind === kind && sponsor.weight !== 0
      );
      const length = kindly.reduce(
        (result, sponsor) => result + sponsor.weight,
        0
      );
      if (length === 0) {
        throw new Error('No sponsors to show');
      }

      const splashPrevious = randomizerRef.current
        ? randomizerRef.current[kind]
        : undefined;

      const previous =
        typeof splashPrevious === 'undefined'
          ? Math.floor(Math.random() * length)
          : splashPrevious;
      const now = (previous + 1) % length;

      setRandomizer((prev) => merge({}, prev || {}, { [kind]: now }));

      const items: TactileSponsor[] = [];
      kindly.forEach((o) => {
        items.push(...Array(o.weight).fill(o));
      });

      const item = items[now];
      if (item) {
        return item;
      }

      throw new Error('Something went wrong grabbing a sponsor');
      */
