import { ComponentType, PropsWithChildren } from 'react';
import { MD2DarkTheme, MD2Theme, ThemeProvider } from 'react-native-paper';

export const DarkTheme = MD2DarkTheme;

const FixedThemeProvider: ComponentType<
  PropsWithChildren<{ theme: MD2Theme }>
> = ThemeProvider as any;

export { FixedThemeProvider as ThemeProvider };
