import { AccentButton } from '@introcloud/blocks';
import { VideoEmbedBlockOptions } from '@introcloud/page';
import { prepare } from '@introcloud/page/dist/blocks/VideoEmbedBlock';
import { useNavigation, useRoute } from '@react-navigation/native';
/*
import {
  getOrientationAsync,
  getOrientationLockAsync,
  lockAsync,
  Orientation,
  OrientationLock,
  supportsOrientationLockAsync,
} from 'expo-screen-orientation';
*/
import React, { Fragment } from 'react';
import { Platform, StatusBar, StyleSheet } from 'react-native';
import { useTheme } from 'react-native-paper';
import WebView from 'react-native-webview';

// let SENTINEL_LOCK = Promise.resolve();

export function EmbedScreen() {
  const { goBack, canGoBack } = useNavigation();
  const { params } = useRoute();

  /*
  const revertOrientationRef = useRef(Orientation.PORTRAIT_UP);
  const revertLockRef = useRef(OrientationLock.PORTRAIT);

  useEffect(() => {
    getOrientationAsync().then((orientation) => {
      revertOrientationRef.current = orientation;
    });

    SENTINEL_LOCK = SENTINEL_LOCK.then(() => getOrientationLockAsync())
      .then((orientation) => {
        revertLockRef.current = orientation;
      })
      .then(() => supportsOrientationLockAsync(OrientationLock.LANDSCAPE))
      .then((support) => {
        support && lockAsync(OrientationLock.LANDSCAPE);
      });

    return () => {
      SENTINEL_LOCK.then(() => lockAsync(revertLockRef.current));
    };
  }, [revertOrientationRef, revertLockRef]);
*/

  const {
    colors: { primary },
  } = useTheme();
  const block = (params as any).block as VideoEmbedBlockOptions;

  const uri = prepare(
    block.value.src,
    block.value.type,
    primary,
    Platform.select({
      web: false,
      android: false,
      ios: true,
      default: false,
    }),
    true
  );

  return (
    <Fragment>
      <StatusBar hidden />

      <WebView
        allowsFullscreenVideo
        source={{ uri }}
        style={[StyleSheet.absoluteFill]}
        cacheEnabled
        mediaPlaybackRequiresUserAction={false}
        allowsInlineMediaPlayback
        javaScriptEnabled
      />

      {canGoBack() ? (
        <AccentButton
          icon="arrow-left"
          style={{ position: 'absolute', top: 14, left: 10 }}
          compact
          onPress={goBack}
        >
          &nbsp;
        </AccentButton>
      ) : null}
    </Fragment>
  );
}
