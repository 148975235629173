import {
  defineTranslations,
  i18n,
  PrimaryButton,
  TextButton,
} from '@introcloud/blocks';
import {
  getPathFromState,
  NavigationContainerRef,
} from '@react-navigation/native';
import { applicationId } from 'expo-application';
import { createURL, openURL } from 'expo-linking';
import * as Notifications from 'expo-notifications';
import {
  addNotificationReceivedListener,
  NotificationContent,
} from 'expo-notifications';
import {
  StoredMemoryValue,
  useMutableMemoryValue,
} from 'expo-use-memory-value';
import { PubNubProvider, usePubNub } from 'pubnub-react';
import React, {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Platform, ScrollView } from 'react-native';
import {
  Card,
  Dialog,
  Paragraph,
  Portal,
  Snackbar,
  useTheme,
} from 'react-native-paper';
import {
  useAddChannel,
  useSelfMessageListener,
} from '../chats/ProvideInAppChats';
import { useChatUserInfo } from '../chats/useChatUserInfo';
import { INTERNAL_PREFIXES } from '../config';
import { SelfMessage } from '../hooks/useChats';
import { useUser } from '../hooks/useUser';
import { openExternalUrl } from '../open';
import { linkTo as gotoInternal, navigate } from './RootNavigation';
import { configureNotifications } from '../notifications';

configureNotifications();

Notifications.setNotificationHandler({
  handleError: console.error,
  handleSuccess: (id) => {},
  handleNotification: async (notification) => {
    return {
      shouldShowAlert: false,
      shouldPlaySound: notification.request.content.sound !== null,
      shouldSetBadge: typeof notification.request.content.badge !== 'number',
    };
  },
});

defineTranslations({
  en: {
    app: {
      notifications: {
        close: 'Close',
        view: 'View',
      },
    },
  },
  nl: {
    app: {
      notifications: {
        close: 'Sluit',
        view: 'Bekijk',
      },
    },
  },
});

export function sendLocalNotification(title: string, message: string) {
  Notifications.scheduleNotificationAsync({
    content: { title, body: message },
    trigger: null,
  });
}

export function InAppNotifications({
  navigationRef,
}: {
  navigationRef: undefined | RefObject<NavigationContainerRef<any>>;
}) {
  const pubnub = usePubNub();
  return (
    <Portal>
      <PubNubProvider client={pubnub}>
        <InAppNotifications_ navigationRef={navigationRef} />
        <ChatSubscriptionsNotifier />
      </PubNubProvider>
    </Portal>
  );
}

const FINAL_PREFIXES = [createURL('/')]
  .concat(INTERNAL_PREFIXES)
  .concat(__DEV__ ? ['http://localhost:19006'] : [])
  .map((domain) => domain.toLocaleUpperCase());

const LAST_NOTIFICATION_ID = new StoredMemoryValue<string | null>(
  `${applicationId}.last-notification-id.in-app`,
  true,
  undefined
);

function InAppNotifications_({
  navigationRef,
}: {
  navigationRef: undefined | RefObject<NavigationContainerRef<any>>;
}) {
  const pubnub = usePubNub();
  const {
    colors: { primary },
  } = useTheme();

  const [routePath, setRoutePath] = useState('');

  const [lastDismissedNotification, setLastDismissedNotification] =
    useMutableMemoryValue(LAST_NOTIFICATION_ID);
  const [lastNotificationReceived, setLastNotificationReceived] = useState<
    Notifications.Notification | undefined
  >();
  const lastNotificationTapped = Notifications.useLastNotificationResponse();

  const notification = [
    lastNotificationReceived,
    lastNotificationTapped?.notification,
  ]
    .filter((item): item is Notifications.Notification => Boolean(item))
    .sort((a, b) => b.date - a.date)[0]?.request;

  useEffect(() => {
    const onReceived = (notification: Notifications.Notification) => {
      setLastNotificationReceived(notification);
    };
    const subscription = addNotificationReceivedListener(onReceived);
    return () => {
      subscription.remove();
    };
  }, []);

  useEffect(() => {
    if (!navigationRef) {
      return;
    }

    const { current } = navigationRef;
    if (!current) {
      return;
    }

    return current.addListener('state' as any, (event) => {
      if (!event.data?.state) {
        return;
      }

      setRoutePath(getPathFromState(event.data!.state!));
    });
  }, [navigationRef]);

  // Figure out if this should skip a notification
  const isChatRoute = (routePath || '').toLocaleUpperCase().includes('/CHAT');
  const url = notification?.content.data?.url as string | undefined;
  const isChatNotification =
    notification?.content.data?.kind === 'chat-notification' ||
    String(url || '').includes('/chat/');
  const shouldSkipNotification = isChatRoute && isChatNotification;

  const addChatChannel = useAddChannel(pubnub);

  useEffect(() => {
    if (!isChatNotification || !url) {
      return;
    }

    const [, id] = url.split('chat/');

    addChatChannel(id);
  }, [isChatNotification, url]);

  const identifier = notification?.identifier || null;
  const deeplink = (notification?.content.data?.url as string) || null;

  const doHideNotification = () => {
    setLastDismissedNotification(notification?.identifier);
  };

  const internalPath = useMemo(() => {
    if (!deeplink) {
      return null;
    }

    const internalPrefix = FINAL_PREFIXES.find(
      (prefix) => deeplink.toLocaleUpperCase().indexOf(prefix) === 0
    );

    return internalPrefix ? deeplink.substring(internalPrefix.length) : null;
  }, [deeplink]);

  return (
    <PushNotificationDialog
      identifier={identifier || undefined}
      push={notification?.content}
      visible={Boolean(
        notification &&
          lastDismissedNotification !== identifier &&
          !shouldSkipNotification
      )}
      onClose={doHideNotification}
      onFollow={() => {
        doHideNotification();

        if (deeplink) {
          if (internalPath || deeplink[0] === '/') {
            try {
              gotoInternal(internalPath || deeplink) ||
                openExternalUrl(deeplink, primary);
            } catch (_) {
              openExternalUrl(deeplink, primary);
            }
          } else {
            openExternalUrl(deeplink, primary);
          }
        } else {
          const { screen, params } = notification?.content.data || {};
          if (screen) {
            (navigate as any)(screen as string, (params as any) || {});
          }
        }
      }}
    />
  );
}

function PushNotificationDialog({
  identifier,
  push,
  visible,
  onClose: doClose,
  onFollow: doFollow,
}: {
  identifier: string | undefined;
  push: NotificationContent | undefined;
  visible: boolean;
  onClose(): void;
  onFollow(): void;
}) {
  const title = (push?.data?.title || push?.title || '') as string;
  const body = (push?.data?.body || push?.body || '') as string;
  const imageUrl = (push?.data?.image || null) as string | null;
  const action = (push?.data?.action || 'Go') as string;
  const canActivate = !!(
    (push?.data && (push.data.screen || push.data.url)) ||
    false
  );

  return (
    <Dialog
      key={identifier}
      visible={visible}
      onDismiss={doClose}
      style={{
        alignSelf: 'center',
        maxWidth: 720,
        maxHeight: '40%',
        width: '90%',
      }}
    >
      <Card contentStyle={{ flexGrow: 1 }}>
        <Card.Title title={title} />
        {(imageUrl && <Card.Cover source={{ uri: imageUrl }} />) || null}
        <Dialog.ScrollArea style={{ paddingHorizontal: 0 }}>
          <ScrollView
            contentContainerStyle={{ margin: 0, paddingHorizontal: 16 }}
          >
            <Paragraph style={{ paddingVertical: 16 }}>{body}</Paragraph>
          </ScrollView>
        </Dialog.ScrollArea>
        <Card.Actions>
          <TextButton onPress={doClose} style={{ marginLeft: 'auto' }}>
            {i18n.t('app.notifications.close')}
          </TextButton>
          {(canActivate && (
            <PrimaryButton
              onPress={doFollow}
              style={{ marginLeft: 8 }}
              mode="contained"
            >
              {action}
            </PrimaryButton>
          )) ||
            null}
        </Card.Actions>
      </Card>
    </Dialog>
  );
}

function ChatSubscriptionsNotifier() {
  const { data: user, reload } = useUser();
  const [subscriptions, setSubscriptions] = useState<SelfMessage[]>([]);

  const uuid = user?._id;

  useSelfMessageListener(
    useCallback(
      (message) =>
        message.t === 'subscribe' &&
        message.s !== uuid &&
        setSubscriptions((prev) => prev.concat([message])),
      [setSubscriptions, uuid]
    )
  );

  const [first] = subscriptions;

  const markAsDone = useCallback(
    () =>
      setSubscriptions((prev) =>
        prev.filter((m) => m.t !== first.t || m.c !== first.c)
      ),
    [first]
  );

  if (subscriptions.length === 0 || !uuid) {
    return null;
  }

  return <NewChatSubscriptionSnackbar trigger={first} onDone={markAsDone} />;
}

function NewChatSubscriptionSnackbar({
  trigger,
  onDone,
}: {
  trigger: SelfMessage;
  onDone(): void;
}) {
  const [shouldGoAway, setShouldGoAway] = useState(false);
  const userId = trigger.t === 'subscribe' && trigger.s;
  const info = useChatUserInfo(userId ? { id: userId } : null);

  // const {} = useBlockNavigation()

  const onGotoChat = useCallback(() => {
    try {
      gotoInternal(`/chat/${trigger.c}`) ||
        openURL(createURL(`/chat/${trigger.c}`));
    } catch (_) {
      openURL(createURL(`/chat/${trigger.c}`));
    }
  }, [trigger.c, gotoInternal]);

  const action = useMemo(
    () => ({ label: i18n.t('app.notifications.view'), onPress: onGotoChat }),
    [onGotoChat]
  );

  const markAsDone = useCallback(() => setShouldGoAway(true), []);

  // Auto dismiss if userId was not passed
  useEffect(() => {
    if (userId || !onDone) {
      return;
    }

    onDone();
  }, [userId, onDone]);

  // Dismiss
  useEffect(() => {
    if (!shouldGoAway) {
      return;
    }

    const timeout = setTimeout(() => {
      onDone();
      setShouldGoAway(false);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [shouldGoAway]);

  const title = i18n.t('app.chats.notification_new', {
    name: info?.name.full || i18n.t('app.chats.anonymous'),
  });

  return (
    <Snackbar
      visible={!shouldGoAway}
      onDismiss={markAsDone}
      action={action}
      duration={Snackbar.DURATION_LONG}
    >
      {title}
    </Snackbar>
  );
}
