import {
  fetchApplicationVideoIndex,
  TactileVideo,
  TactileVideoIndex,
} from '@introcloud/api-client';
import { useIsFocused } from '@react-navigation/core';
import { FetchMediaError } from 'fetch-media';
import { useCallback } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { useIsMounted } from 'use-is-mounted';
import { VIDEO_CACHE } from '../core/Cache';
import { NotReady } from '../core/errors/NotReady';
import { StoredMemoryValue, useMutableMemoryValue } from '../storage';
import { merge, SHOULD_DEBUG_FETCH } from '../utils';
import {
  runOnLogout,
  useEndpoint,
  useSafeAuthorization,
} from './useAuthentication';

const VIDEOS = new StoredMemoryValue<readonly PreparedVideo[]>(
  'application.videos.v1'
);

runOnLogout(() => {
  VIDEOS.emit(null);
});

export type PreparedVideo = TactileVideoIndex[number];

export function useVideos({
  enabled = true,
  ...options
}: UseQueryOptions<
  readonly Omit<TactileVideo, 'url'>[] | null | undefined,
  FetchMediaError | Error
> = {}) {
  const isFocused = useIsFocused();
  const endpoint = useEndpoint();
  const authorization = useSafeAuthorization();
  const [storedVideos, setStoredVideos] = useMutableMemoryValue(VIDEOS);
  const isMountedRef = useIsMounted();

  const fetcher = useCallback(
    async ({ signal }: { signal?: AbortSignal }) => {
      if (!endpoint || !authorization) {
        throw new NotReady();
      }

      const result = await fetchApplicationVideoIndex(
        endpoint,
        authorization!,
        signal,
        SHOULD_DEBUG_FETCH
      );

      const cached = await cacheVideos(result);

      isMountedRef.current && setStoredVideos(cached);

      return cached;
    },
    [endpoint, authorization, setStoredVideos]
  );

  const {
    data: videos,
    error,
    ...others
  } = useQuery<
    readonly Omit<TactileVideo, 'url'>[] | null | undefined,
    FetchMediaError | Error
  >([endpoint, 'application', 'videos'], fetcher, {
    placeholderData: storedVideos,
    enabled: enabled && !!(endpoint && authorization) && isFocused,
    staleTime: 30 * 1000,
    ...options,
  });

  const loading = !error && !videos;
  const reload = others.refetch;

  return {
    data: videos,
    loading,
    error,
    reload,
    refreshing: others.isFetching && !others.isLoading,
  };
}

function cacheVideos(result: readonly PreparedVideo[]) {
  const currentValue = VIDEO_CACHE.current;
  const nextValue = toMap(result);

  Object.keys(currentValue).forEach((key) => {
    if (!nextValue[key]) {
      delete currentValue[key];
    }
  });

  merge(currentValue, nextValue);
  return result;
}

function toMap<T extends { _id: string }>(
  items: readonly T[]
): Record<string, T> {
  return items.reduce((result, item) => {
    result[item._id] = item;
    return result;
  }, {} as Record<string, T>);
}
