import React, {
  createContext,
  createElement,
  useContext,
  useState,
} from 'react';
import { usePermits } from './Authentication';

const NavigationReadyContext = createContext([
  false as boolean,
  (next: boolean) => {},
] as const);

export function NavigationReadyProvider({
  children,
}: React.PropsWithChildren<object>) {
  const value = useState(false);
  return createElement(NavigationReadyContext.Provider, { value }, children);
}
export function useNavigationReady() {
  const { current } = usePermits();
  const [ready, setReady] = useContext(NavigationReadyContext);
  return [ready && current?.hydrated, setReady] as const;
}
