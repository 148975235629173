import { defineTranslations } from '@introcloud/blocks';
import React from 'react';

import { MatchingScreen } from './MatchingScreen';

defineTranslations({
  en: {
    app: {
      matching: {
        title: 'Matching',
        terms_to_accept:
          'By using the matching module you accept that some personal data (name, interests, profile picture and age) is shared with other users. The data is collected only for matching you with others with similar interest. All data falls under the general Terms and Conditions & Privacy Policy.',
        introduction:
          'Okay {{user}}, write a little bit about yourself (max. 200 chars). This text will be displayed to others who are swiping.',
        explanation:
          'You will only see people with similar interests, so make sure you stand out in the 200 characters below.',
        no_matches:
          "We don't have any potential matches for you. Come back later!",
        label_about: 'About me',
        count_last: 'Last one',
        count: '{{count} to go',
        actions: {
          accept: 'I accept',
          ready: "I'm ready",
        },
      },
    },
  },

  nl: {
    app: {
      matching: {
        title: 'Matchen',
        terms_to_accept:
          'Als je gebruik maakt van deze matching module, stem je er mee in dat er persoonlijke informatie (naam, interesses, profiel afbeelding, en leeftijd) wordt gedeeld met andere gebruikers. De data wordt alleen verzameld om je te kunnen matchen met andere mensen met vergelijkbare interesses. Alle verzamelde informatie valt onder de algemene voorwarden & privacy policy.',
        introduction:
          'Okay {{user}}, schrijf eens wat over jezelf (max. 200 tekens). Anderen zullen deze beschrijving zien als ze aan het swipen zijn.',
        explanation:
          'Je zal alleen anderen zijn met vergelijkbare interesses, dus zorg ervoor dat je opvalt in de 200 tekens hieronder.',
        no_matches:
          'We hebben op dit moment geen potentiële matches voor je. Kom later nog eens terug!',
        label_about: 'Over mij',
        count_last: 'Laatste',
        count: '{{count} te gaan',
        actions: {
          accept: 'Ik ga akkoord',
          ready: 'Ik ben er klaar voor',
        },
      },
    },
  },
});

export function MatchingTab() {
  return <MatchingScreen asTab />;
}
