import {
  fetchApplicationEventGoals,
  TactileGoal,
} from '@introcloud/api-client';
import { useLocale } from '@introcloud/blocks';
import { localize } from '@introcloud/blocks/dist/useLocale';
import { useIsFocused } from '@react-navigation/core';
import { FetchMediaError } from 'fetch-media';
import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useIsMounted } from 'use-is-mounted';
import { NotReady } from '../core/errors/NotReady';
import {
  AnyMemoryValue,
  StoredMemoryValue,
  useMutableMemoryValue,
} from '../storage';
import { SHOULD_DEBUG_FETCH } from '../utils';
import {
  runOnLogout,
  useEndpoint,
  useSafeAuthorization,
} from './useAuthentication';
import { useEvents } from './useEvents';

const GOALS: AnyMemoryValue<
  | readonly {
      id: string;
      title: string;
      main: boolean;
      goals: TactileGoal[];
    }[]
  | null
> = new StoredMemoryValue('application.goals.multiple.v1');

runOnLogout(() => {
  GOALS.emit(null);
});

export function useGoals({ enabled = true }: { enabled?: boolean } = {}) {
  const isFocused = useIsFocused();
  const { data: events } = useEvents({ enabled: enabled && isFocused });
  const locale = useLocale();

  const goalEvents = useMemo(
    () =>
      events
        ? events
            .filter((event) => event.module?.goal?.active)
            .map((event) => ({
              id: event._id,
              title: localize(
                event.nameLocalized?.full,
                event.name.full,
                locale
              ),
              main: event.hierarchy.isMain,
            }))
        : [],
    [events, locale]
  );

  const authorization = useSafeAuthorization();
  const endpoint = useEndpoint();
  const isMounted = useIsMounted();
  const [storedGoals, setStoredGoals] = useMutableMemoryValue(GOALS);

  const fetcher = useCallback(
    async ({ signal }: { signal?: AbortSignal }) => {
      if (!authorization || !endpoint) {
        throw new NotReady();
      }

      const results = await Promise.all(
        goalEvents.map(({ id, title, main }) =>
          fetchApplicationEventGoals(
            id,
            endpoint,
            authorization!,
            signal,
            SHOULD_DEBUG_FETCH
          )
            .catch(() => [] as TactileGoal[])
            .then((goals) => ({
              id,
              title,
              main,
              goals: goals as TactileGoal[],
            }))
        )
      );

      const actualResults = results.filter((result) => result.goals.length > 0);

      isMounted.current && setStoredGoals(actualResults);

      return actualResults;
    },
    [authorization, endpoint, goalEvents, setStoredGoals]
  );

  const {
    data: goals,
    error,
    refetch,
    ...others
  } = useQuery<typeof storedGoals | null, FetchMediaError>(
    [endpoint, 'application', 'goals', 'multiple'],
    fetcher,
    {
      placeholderData: storedGoals,
      refetchOnWindowFocus: false,
      enabled: !!(authorization && endpoint) && enabled && isFocused,
      staleTime: 30 * 1000,
    }
  );

  return {
    data: goals,
    error,
    reload: refetch,
    loading: !goals && !error,
    refreshing: others.isFetching,
    eventIds: goalEvents,
    ...others,
  };
}
