import { fetchChatName } from '@introcloud/api-client';
import { useCallback } from 'react';
import { NotReady } from '../core/errors/NotReady';
import { useEndpoint, useSafeAuthorization } from '../hooks/useAuthentication';
import { SHOULD_DEBUG_FETCH } from '../utils';

export function useNameFetcher(modelName: 'page' | 'group' | 'user') {
  const authorization = useSafeAuthorization();
  const endpoint = useEndpoint();

  return useCallback(
    (id: string, { signal }: { signal?: AbortSignal }) => {
      if (!endpoint || !authorization) {
        throw new NotReady();
      }

      return fetchChatName(
        modelName,
        id,
        endpoint,
        authorization,
        signal,
        SHOULD_DEBUG_FETCH
      );
    },
    [authorization, endpoint, modelName]
  );
}
