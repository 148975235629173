import { TactileGroup, TactileGroupUser } from '@introcloud/api-client';
import { ContactMenu, Spacer, TextButton, i18n } from '@introcloud/blocks';
import {
  useBlockImageUrl,
  useBlockNavigation,
} from '@introcloud/blocks-interface';
import React, { Fragment, memo, useCallback, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import {
  Avatar,
  IconButton,
  List,
  Surface,
  useTheme,
} from 'react-native-paper';
import { useHasContrastOnLight } from 'use-color-luminance';
import { useCompany } from '../hooks/useCompany';
import { useUser } from '../hooks/useUser';

function GroupUser_(user: TactileGroupUser & { hideChat?: boolean }) {
  const { data: self } = useUser();

  const url = useBlockImageUrl(user.image.profile || '', 'icon_128').data;

  const {
    mobile: { countrycode, value, valid },
    hideChat,
  } = user;

  const phone =
    valid && (value?.length || 0 > 5) ? `${countrycode || ''}${value}` : '';

  const initials = [user.name.first?.[0], user.name.last?.[0]]
    .concat(user.name.full?.split(' '))
    .slice(0, 2)
    .map((l) => (l || '')[0])
    .join('');

  const {
    colors: { primary },
  } = useTheme();
  const textColor = useHasContrastOnLight(primary) ? '#fff' : '#121212';

  const [active, setActive] = useState(false);
  const toggleMenu = useCallback(() => setActive((prev) => !prev), [setActive]);
  const renderIllustration = useCallback(
    () =>
      url ? (
        <Avatar.Image
          size={40}
          style={{
            marginLeft: 8,
            marginRight: 16,
            marginTop: 4,
            marginBottom: 4,
          }}
          source={{ uri: url, width: 40, height: 40 }}
        />
      ) : (
        <Avatar.Text
          color={textColor}
          style={{
            marginLeft: 8,
            marginRight: 16,
            marginTop: 4,
            marginBottom: 4,
          }}
          size={40}
          label={initials}
        />
      ),
    [url, initials]
  );

  const renderRight = useCallback(
    (props: { color: string }) => {
      if (self?._id === user._id) {
        return null;
      }

      return (
        <View style={{ flexDirection: 'row' }}>
          <Fragment>
            <ContactMenu
              id={user._id}
              active={active}
              onDismiss={toggleMenu}
              anchor={
                <IconButton
                  size={24}
                  icon="card-account-details"
                  onPress={toggleMenu}
                />
              }
              name={user.name.first || user.name.full?.split(' ').shift() || ''}
              phone={phone}
              showChat={!hideChat}
            />
          </Fragment>
        </View>
      );
    },
    [phone, user.name.first, user.name.full, active, toggleMenu]
  );

  return (
    <List.Item
      left={renderIllustration}
      style={styles.item}
      onPress={toggleMenu}
      title={user.name.full}
      description={user.role.id === 'leader' ? 'Leaders' : ''}
      right={renderRight}
      disabled={self?._id === user._id}
    />
  );
}

export const GroupUser = memo(GroupUser_);

export function Group({
  noHeader,
  ...group
}: TactileGroup & { noHeader?: boolean }) {
  const theme = useTheme();
  const company = useCompany();

  const { gotoChat } = useBlockNavigation();
  const showChat = company?.settings.profileShowChat;

  return (
    <View style={{ width: '100%', position: 'relative' }}>
      {noHeader ? null : <List.Subheader>{group.name.full}</List.Subheader>}
      <Surface
        style={{
          elevation: 1,
          borderRadius: theme.roundness,
          marginHorizontal: 16,
        }}
      >
        {group.users
          .filter(
            (user) =>
              user.role.id === 'leader' &&
              !user.name.full.endsWith('=') &&
              (user.name.full.includes(' ') ||
                user.name.full.includes(user.name.first) ||
                user.name.first.length < 60)
          )
          .sort((a, b) => (a.name.full || '').localeCompare(b.name.full || ''))
          .map((user) => (
            <GroupUser {...user} key={user._id} />
          ))}
      </Surface>
      <Spacer space={1} />
      <Surface
        style={{
          elevation: 1,
          borderRadius: theme.roundness,
          marginHorizontal: 16,
        }}
      >
        {group.users
          .filter(
            (user) =>
              user.role.id !== 'leader' &&
              !user.name.full.endsWith('=') &&
              (user.name.full.includes(' ') ||
                user.name.full.includes(user.name.first) ||
                user.name.first.length < 60)
          )
          .sort((a, b) => (a.name.full || '').localeCompare(b.name.full || ''))
          .map((user) => (
            <GroupUser {...user} key={user._id} />
          ))}
      </Surface>
      <Spacer space={2} />
      {noHeader || !showChat ? null : (
        <TextButton
          icon="forum"
          style={{ position: 'absolute', right: 16, top: 4 }}
          onPress={() => {
            gotoChat(`:group/${JSON.stringify({ group: group._id })}`);
          }}
        >
          {i18n.t('app.actions.goto_group_chat')}
        </TextButton>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  item: {
    justifyContent: 'space-between',
    width: '100%',
  },
});
