import { i18n } from '@introcloud/blocks';
import { StoredMemoryValue } from 'expo-use-memory-value';
import { Platform } from 'react-native';
import { NATIVE_DOMAIN, USER_AGENT } from '../config';

const LAST_VISITED = new StoredMemoryValue<Record<string, string>>(
  'analytics.last-visited-dates',
  true,
  {}
);
LAST_VISITED.hydrate().then(() => trackEvent('first-load'));

export async function trackPageView(path: string | undefined) {
  if (!path) {
    return;
  }

  return track('pageview', path, 'pageview');
}

export async function trackEvent(event: string, path: string | undefined = '') {
  return track(event, path, 'event');
}

async function track(
  event: string,
  path: string | undefined = '',
  type: 'event' | 'pageview' = 'event'
) {
  if (!event) {
    return;
  }

  const todayIso = new Date().toISOString().split('T').shift()!;
  const unique =
    !LAST_VISITED.current || LAST_VISITED.current[event] !== todayIso;

  if (unique) {
    const next = { ...LAST_VISITED.current, [event]: todayIso };
    await LAST_VISITED.emit(next);
  }

  console.log(`[analytics] ${event} at ${path}`);

  const headers: Record<string, string> = {
    'Content-Type': 'text/plain', // 'application/json',
  };

  if (Platform.OS !== 'web') {
    headers['User-Agent'] = USER_AGENT;
  }

  const body: Record<string, string | null | boolean> = {
    type,
    event,
    hostname: NATIVE_DOMAIN ?? null,
    path,
    language: i18n.locale,
    unique,
  };

  if (Platform.OS !== 'web') {
    body['ua'] = USER_AGENT;
  }

  return fetch('https://queue.simpleanalyticscdn.com/events', {
    headers,
    method: 'post',
    body: JSON.stringify(body),
  })
    .then((response) =>
      response.ok
        ? response.status
        : Promise.reject(new Error('Failed with status: ' + response.status))
    )
    .catch(() => {
      /** noop */
    });
}
