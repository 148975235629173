import React from 'react';
import { Platform, StyleSheet } from 'react-native';
import { Surface } from 'react-native-paper';

export function StickyHeader({
  elevation,
  width,
  borderColor,
  border,
  children,
}: {
  border: boolean;
  borderColor: string;
  width: number;
  elevation: number;
  children: React.ReactNode;
}) {
  return (
    <Surface
      style={[
        {
          elevation,
          width,
          position: Platform.select({
            web: 'sticky',
            default: 'relative',
          }) as 'relative',
          top: 0,
          height: 64,
          zIndex: 2,
          borderTopColor: borderColor,
          borderTopWidth: border ? StyleSheet.hairlineWidth : 0,
        },
      ]}
    >
      {children}
    </Surface>
  );
}
