import { BalanceOverViewStateValid } from '@introcloud/api-client';
import { useMemo } from 'react';

export type BalanceCreditItem = BalanceOverViewStateValid['deposits'][number];
export type BalanceDebitItem = BalanceOverViewStateValid['withdrawals'][number];
export type TypedTransaction =
  | ({ type: 'withdrawal' } & BalanceDebitItem)
  | ({ type: 'deposit' } & BalanceCreditItem);

export function useTransactions(balance: BalanceOverViewStateValid | null) {
  const transactions: TypedTransaction[] = useMemo(
    () =>
      balance?.ok
        ? ([] as TypedTransaction[])
            .concat(
              balance.withdrawals.map((item) => ({
                ...item,
                type: 'withdrawal',
              }))
            )
            .concat(
              balance.deposits.map((item) => ({ ...item, type: 'deposit' }))
            )
            .sort((a, b) =>
              a.unix && b.unix ? b.unix - a.unix : b._id.localeCompare(a._id)
            )
        : [],
    [balance]
  );

  return transactions;
}
