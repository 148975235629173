import { TactileGroup, registerPushToken } from '@introcloud/api-client';
import { PrimaryButton, TextButton, i18n, useLocale } from '@introcloud/blocks';
import {
  ProvideBlockData,
  ProvideBlockNavigation,
  useBlockData,
} from '@introcloud/blocks-interface';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Color from 'color';
import {
  getStringAsync,
  getUrlAsync,
  hasStringAsync,
  hasUrlAsync,
} from 'expo-clipboard';
import * as ExpoNotifications from 'expo-notifications';
import { FetchMediaError, fetchMedia } from 'fetch-media';
import {
  ComponentProps,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { Linking, Platform, ScrollView, View } from 'react-native';
import {
  Dialog,
  HelperText,
  IconButton,
  List,
  Portal,
  Surface,
  TextInput,
  useTheme,
} from 'react-native-paper';
import { useMutation, useQueryClient } from 'react-query';
import { useIsMounted } from 'use-is-mounted';
import { EXPERIENCE_SLUG } from '../config';
import { RouteParamList } from '../core/Routes';
import { LAST_TOKEN_REF, useKnownTokens } from '../home/Notifications';
import { useEndpoint, useSafeAuthorization } from '../hooks/useAuthentication';
import { useCompany } from '../hooks/useCompany';
import { useGroups } from '../hooks/useGroup';
import { useProvideBlockNavigation } from '../hooks/useProvideBlockNavigation';
import { useUser } from '../hooks/useUser';
import { configureNotifications } from '../notifications';
import { SHOULD_ALLOW_DEBUG } from '../utils';
import { Group } from './Group';

export function ProfileCommunication() {
  useLocale();

  const enabled = useIsFocused();

  const { roundness } = useTheme();
  const { data: groups } = useGroups({ enabled });
  const company = useCompany({ enabled });

  const showGroup = Boolean(
    company?.settings.profileShowGroup && groups?.length
  );

  return (
    <View style={{ width: '100%', position: 'relative', paddingVertical: 12 }}>
      <List.Subheader>
        {i18n.t('app.profile.communication.title')}
      </List.Subheader>
      <Surface
        style={{
          width: '100%',
          borderRadius: roundness,
          overflow: 'hidden',
          marginHorizontal: 'auto',
          elevation: 1,
        }}
      >
        {Platform.OS === 'web' ? null : <NotificationsListItem />}
        {company?.settings.profileShowChat ? <ChatsListItem /> : null}
        {showGroup ? <GroupListItemsCollection groups={groups!} /> : null}
      </Surface>
    </View>
  );
}

function NotificationsListItem() {
  useLocale();

  const enabled = useIsFocused();
  const endpoint = useEndpoint();
  const authorization = useSafeAuthorization();
  const { data: currentUser } = useUser({ enabled });
  const { data: knownTokens, isFetched } = useKnownTokens(enabled);

  /*
  const IS_PREVIEW =
    IS_TEST_RELEASE ||
    (!currentUser?.email.value.includes('store') &&
      currentUser?.email.value.endsWith('@tactile.events'));
  */

  const experienceRegistered = knownTokens?.some(
    (token) =>
      token.expoSlug === EXPERIENCE_SLUG ||
      token.expoSlug === EXPERIENCE_SLUG.slice(1).replace('/', '') ||
      token.expoSlug === EXPERIENCE_SLUG.slice(1).replace('/', '--')
  );

  const title = i18n.t('app.profile.communication.notifications.title');

  const description = !isFetched
    ? i18n.t('app.profile.communication.notifications.description.unknown')
    : experienceRegistered
    ? i18n.t('app.profile.communication.notifications.description.on')
    : i18n.t('app.profile.communication.notifications.description.off');

  const icon: ComponentProps<typeof List.Icon>['icon'] = !isFetched
    ? 'timer-sand'
    : experienceRegistered
    ? 'check'
    : 'close';

  const [pushToken, setPushToken] = useState(
    LAST_TOKEN_REF.current as string | null
  );

  const queryClient = useQueryClient();
  const [state, setState] = useState({
    permissionsChecked: false,
    tokenReceived: false,
    canAskAgain: false,
    isRegistering: false,
    isGranted: false,
  });

  const { mutateAsync: register } = useMutation(
    async (registerToken: string) => {
      await queryClient.cancelQueries([
        'user',
        currentUser?._id,
        'push-notification-tokens',
      ]);

      const tokenNow = authorization;

      if (!tokenNow) {
        throw new Error('Token required');
      }

      await registerPushToken(
        endpoint,
        registerToken,
        authorization,
        EXPERIENCE_SLUG,
        currentUser?._id,
        undefined,
        SHOULD_ALLOW_DEBUG
      );
    },
    {
      onSettled: async () => {
        await queryClient.invalidateQueries([
          'user',
          currentUser?._id,
          'push-notification-tokens',
        ]);
      },
    }
  );

  const { mutateAsync: test } = useMutation(async (token: string) => {
    if (!authorization) {
      return;
    }

    const url = `${endpoint}/application/user/push-token/${token}/test`;
    await fetch(url, {
      headers: { authorization: authorization },
      method: 'POST',
    });
  }, {});

  /*
  const enableAllow =
    Boolean(currentUser) &&
    Boolean(authorization) &&
    !isLoading &&
    !state.isRegistering &&
    state.permissionsChecked;
  */

  /*
  const showAllow =
    state.permissionsChecked && (state.canAskAgain || state.isGranted);
    */

  const isMountedRef = useIsMounted();

  // Check initial state
  useEffect(() => {
    if (state.permissionsChecked) {
      return;
    }

    ExpoNotifications.getPermissionsAsync()
      .then(({ status, canAskAgain }) => {
        if (!isMountedRef.current) {
          return;
        }

        if (status === 'granted') {
          setState((prev) => ({
            ...prev,
            permissionsChecked: true,
            isGranted: true,
          }));
          return;
        }

        setState((prev) => ({
          ...prev,
          permissionsChecked: true,
          isGranted: false,
          canAskAgain,
        }));
      })
      .catch(() => {
        setState((prev) => ({
          ...prev,
          permissionsChecked: true,
          isGranted: false,
          canAskAgain: true,
        }));
      });
  }, []);

  useEffect(() => {
    if (!state.isGranted || state.tokenReceived) {
      return;
    }

    ExpoNotifications.getExpoPushTokenAsync({
      experienceId: EXPERIENCE_SLUG,
    })
      .then((token) => {
        if (!isMountedRef.current) {
          return;
        }

        LAST_TOKEN_REF.current = token.data;
        setPushToken(token.data);
        setState((prev) => ({
          ...prev,
          tokenReceived: true,
        }));
      })
      .catch((error) => {
        console.error(error);
      });
  }, [state.isGranted]);

  const allow = useCallback(() => {
    setState((prev) => ({ ...prev, isRegistering: true }));

    async function execute() {
      // If not yet granted, request now
      if (!state.isGranted) {
        const { status, canAskAgain } =
          await ExpoNotifications.requestPermissionsAsync();

        if (!isMountedRef.current) {
          return;
        }

        if (status !== 'granted') {
          setState((prev) => ({
            ...prev,
            isRegistering: false,
            isGranted: false,
            canAskAgain,
          }));
          return;
        }

        configureNotifications();

        setState((prev) => ({ ...prev, isGranted: true }));
      }

      // is granted now!
      const nextToken =
        pushToken ||
        (
          await ExpoNotifications.getExpoPushTokenAsync({
            experienceId: EXPERIENCE_SLUG,
          })
        ).data;

      LAST_TOKEN_REF.current = nextToken;
      if (!isMountedRef.current) {
        return;
      }

      await register(nextToken);

      if (!isMountedRef.current) {
        return;
      }

      await test(nextToken);

      setState((prev) => ({ ...prev, isRegistering: false }));
    }

    execute().catch((error) => {
      if (!isMountedRef.current) {
        return;
      }

      console.error(error);

      // Just ignore, for now
    });
  }, [state, pushToken]);

  return (
    <List.Item
      title={title}
      description={description}
      right={(props) => <List.Icon color={props.color} icon={icon} />}
      onPress={allow}
    />
  );
}

function ChatsListItem() {
  useLocale();

  const { navigate } = useNavigation<StackNavigationProp<RouteParamList>>();

  return (
    <List.Item
      title={i18n.t('app.actions.goto_chats')}
      description={i18n.t('app.profile.communication.chats.description')}
      onPress={() => navigate('Chats')}
    />
  );
}

function GroupListItemsCollection({
  groups,
}: {
  groups: readonly TactileGroup[];
}) {
  return (
    <Fragment>
      {groups.map((group, index) => (
        <Fragment key={index}>
          <GroupChatListItem group={group} />
          <GroupDetailsListItem group={group} />
        </Fragment>
      ))}
    </Fragment>
  );
}

function GroupChatListItem({ group }: { group: TactileGroup }) {
  useLocale();

  const { data: user } = useUser();
  const endpoint = useEndpoint();
  const queryKey = [endpoint, 'application', 'groups'];
  const queryClient = useQueryClient();

  const editable = useMemo(
    () => group.users.find((g) => g._id === user?._id)?.role.id === 'leader',
    [user?._id, group]
  );
  const available = useMemo(
    () => Boolean((group as any).whatsAppGroupJoinUrl),
    [group]
  );

  const [nextValue, setNextValue] = useState(
    (group as any).whatsAppGroupJoinUrl || ''
  );

  const title = i18n.t('app.profile.communication.groups.chat.title', {
    name: group.name.full,
  });

  const description = available
    ? i18n.t('app.profile.communication.groups.chat.description.available')
    : editable
    ? i18n.t('app.profile.communication.groups.chat.description.editable')
    : i18n.t('app.profile.communication.groups.chat.description.unavailable');

  const gotoWhatsapp = () => {
    Linking.openURL(nextValue).catch(() => {});
  };
  const [editing, toggleEdit] = useReducer((prev) => !prev, false);
  const [canPaste, setCanPaste] = useState(Platform.OS === 'web');
  const isMountedRef = useIsMounted();

  if (editing) {
    if (Platform.OS === 'ios') {
      hasUrlAsync()
        .then((nextCanPaste) => {
          if (isMountedRef.current) {
            setCanPaste(nextCanPaste);
          }
        })
        .catch(() => {});
    }

    if (Platform.OS === 'android') {
      hasStringAsync()
        .then((nextCanPaste) => {
          if (isMountedRef.current) {
            setCanPaste(nextCanPaste);
          }
        })
        .catch(() => {});
    }
  }

  const gotoPrimary = available
    ? gotoWhatsapp
    : editable
    ? toggleEdit
    : undefined;

  const disabled = !available && !editable;
  const authorization = useSafeAuthorization();

  const {
    isLoading: saving,
    mutateAsync: save,
    error,
  } = useMutation<unknown, Error | FetchMediaError, string>(
    ['group', group._id, 'whatsAppGroupJoinUrl', 'set'],
    async (whatsappValue: string) => {
      if (
        !whatsappValue
          .toLocaleLowerCase()
          .startsWith('https://chat.whatsapp.com/')
      ) {
        throw new Error('Invalid format');
      }

      queryClient.cancelQueries(queryKey);

      await fetchMedia(`${endpoint}/application/user/group/${group._id}/`, {
        method: 'PATCH',
        headers: {
          accept: 'application/json',
          contentType: 'application/json',
          authorization: `${authorization}`,
        },
        body: {
          ...group,
          whatsAppGroupJoinUrl: whatsappValue,
        },
      });
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(queryKey);
      },
    }
  );

  return (
    <Fragment>
      <List.Item
        title={title}
        description={description}
        onPress={gotoPrimary}
        disabled={disabled}
        style={{ opacity: disabled ? 0.5 : 1 }}
        left={(props) => <List.Icon icon="whatsapp" color={props.color} />}
        right={(props) => (
          <View style={{ flexDirection: 'row' }}>
            {editable ? (
              <IconButton
                icon="pencil-outline"
                onPress={toggleEdit}
                iconColor={props.color}
                style={{ marginRight: 8 }}
              />
            ) : null}
          </View>
        )}
      />
      <Portal>
        <Dialog
          visible={editing}
          onDismiss={toggleEdit}
          style={{
            maxWidth: 720,
            alignSelf: 'center',
            minWidth: 300,
            width: '90%',
          }}
        >
          <View style={{ position: 'relative' }}>
            <Dialog.Title>
              {i18n.t('app.profile.communication.groups.chat.url.title')}
            </Dialog.Title>

            <HelperText
              type="info"
              style={{ marginTop: -24, marginHorizontal: 12 }}
            >
              {i18n.t('app.profile.communication.groups.chat.url.description')}
            </HelperText>

            <View
              style={{ position: 'relative', marginTop: 12, marginBottom: 6 }}
            >
              <TextInput
                keyboardType="url"
                returnKeyType="done"
                textContentType="URL"
                onChangeText={setNextValue}
                disabled={saving}
                value={nextValue ?? ''}
                mode="outlined"
                selectTextOnFocus
                clearButtonMode="while-editing"
                placeholder="https://chat.whatsapp.com/..."
                label={i18n.t(
                  'app.profile.communication.groups.chat.url.label'
                )}
                style={{ marginHorizontal: 12 }}
                right={
                  <TextInput.Icon
                    icon="clipboard"
                    disabled={!canPaste}
                    onPress={() => {
                      Platform.select({
                        ios: getUrlAsync,
                        default: getStringAsync,
                      })()
                        .then((nextValue) => {
                          if (isMountedRef.current) {
                            setNextValue(nextValue);
                          }
                        })
                        .catch(() => {});
                    }}
                  />
                }
              />
              {error && !saving ? (
                <HelperText type="error">{error.message}</HelperText>
              ) : null}

              <Dialog.Actions>
                <PrimaryButton
                  onPress={() =>
                    save(nextValue)
                      .then(() => toggleEdit())
                      .catch(() => {})
                  }
                >
                  {i18n.t('app.actions.save')}
                </PrimaryButton>
                <TextButton onPress={toggleEdit}>
                  {i18n.t('app.actions.negative_action')}
                </TextButton>
              </Dialog.Actions>
            </View>
          </View>
        </Dialog>
      </Portal>
    </Fragment>
  );
}

function GroupDetailsListItem({ group }: { group: TactileGroup }) {
  useLocale();

  const {
    dark,
    colors: { surface },
  } = useTheme();

  const title = i18n.t('app.profile.communication.groups.contact.title', {
    name: group.name.full,
  });

  const description = i18n.t(
    'app.profile.communication.groups.contact.description'
  );
  const [showing, toggleShow] = useReducer((prev) => !prev, false);
  const navigation = useProvideBlockNavigation();
  const { getImageUrl } = useBlockData();

  return (
    <Fragment>
      <List.Item
        left={(props) => <List.Icon icon="account-group" color={props.color} />}
        title={title}
        description={description}
        onPress={toggleShow}
      />
      <Portal>
        <Dialog
          visible={showing}
          onDismiss={toggleShow}
          style={{
            alignSelf: 'center',
            maxWidth: 720,
            width: '100%',
            position: 'relative',
          }}
        >
          <Dialog.Title style={{ position: 'relative' }}>
            {group.name.full ?? '-'}
          </Dialog.Title>
          <Dialog.ScrollArea
            style={{
              paddingHorizontal: 0,
              backgroundColor: dark
                ? undefined
                : new Color(surface).darken(0.05).string(),
            }}
          >
            <ProvideBlockNavigation navigation={navigation}>
              <ProvideBlockData provider={{ getImageUrl }}>
                <ScrollView style={{ maxHeight: 480, paddingTop: 16 }}>
                  <Group {...group} noHeader />
                </ScrollView>
              </ProvideBlockData>
            </ProvideBlockNavigation>
          </Dialog.ScrollArea>
          <Dialog.Actions
            style={{ paddingHorizontal: 16, paddingBottom: 12, paddingTop: 12 }}
          >
            <TextButton icon="close" onPress={toggleShow}>
              {i18n.t('app.actions.close_dialog')}
            </TextButton>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </Fragment>
  );
}
