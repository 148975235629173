import { fetchRegisteredTimeslot } from '@introcloud/api-client';
import {
  CardTimeProgressBar,
  EventData,
  i18n,
  PrimaryButton,
  Spacer,
  useLocalization,
} from '@introcloud/blocks';
import {
  useBlockDataTimeslots,
  useBlockNavigation,
} from '@introcloud/blocks-interface';
import {
  PageDataProvider,
  VideoStreamBlock,
  VideoStreamBlockOptions,
} from '@introcloud/page';
import React, { Fragment, memo } from 'react';
import { Image, TouchableWithoutFeedback, View } from 'react-native';
import {
  Avatar,
  Card,
  List,
  Paragraph,
  Surface,
  Title,
  useTheme,
} from 'react-native-paper';
import { localeTimeString } from 'react-native-time-helpers';
import { useMediaQuery } from 'react-responsive';
import { PreparedEvent } from '../hooks/useEvents';
import { usePage } from '../hooks/usePage';
import { useGetInfoById } from '../hooks/useProvideBlockData';

export type NowEventData = EventData & {
  page: boolean;
  pageRef: PreparedEvent['pageRef'];
  largeImage: string | null;
};

function EventCard_(props: NowEventData & { isLast: boolean }) {
  if (props.page && props.pageRef.pageId) {
    return <PagedEventCard {...props} pageId={props.pageRef.pageId} />;
  }

  return <RegularEventCard {...props} />;
}

function PagedEventCard(
  event: NowEventData & { isLast: boolean; pageId: string }
) {
  const getInfoById = useGetInfoById();
  const { page } = usePage(event.pageId, getInfoById);

  const liveStreamBlock = page?.content?.find(
    (block) => block.kind === 'live'
  ) as (VideoStreamBlockOptions & { _id: string }) | undefined;

  return (
    <PageDataProvider
      value={{
        page: page || null,
        pageEvent: null,
        pageLocation: null,
        currentUser: null,
        currentGroups: null,
      }}
    >
      <RegularEventCard {...event}>
        {liveStreamBlock ? (
          <Fragment>
            <VideoStreamBlock
              id={liveStreamBlock._id}
              {...liveStreamBlock}
              options={{
                ...liveStreamBlock.options,
                round: false,
                surface: true,
              }}
            />
          </Fragment>
        ) : null}
      </RegularEventCard>
    </PageDataProvider>
  );
}

function RegularEventCard({
  children,
  ...event
}: React.PropsWithChildren<NowEventData & { isLast: boolean }>) {
  const { roundness } = useTheme();
  const { gotoEvent } = useBlockNavigation();

  // const isNow = true;

  const mobileView = useMediaQuery({ query: '(max-width: 1168px)' }); // 720 + 424 + 24;
  const smallMobileView = useMediaQuery({ query: '(max-width: 500px)' });

  return (
    <Fragment>
      <View style={{ flexDirection: 'row' }}>
        {mobileView || !event.largeImage ? null : (
          <View>
            <Card
              onPress={() => gotoEvent(event.id)}
              style={{
                marginLeft: -212,
                marginRight: 12,
                maxWidth: 200,
                maxHeight: '100%',
                overflow: 'hidden',
              }}
            >
              <Image
                source={{
                  uri: event.largeImage!,
                  width: 720,
                  height: (720 / 4) * 3,
                }}
                style={{ width: 200, height: 200 }}
              />
            </Card>
          </View>
        )}
        <View style={{ maxWidth: 720, width: '100%' }}>
          <TouchableWithoutFeedback onPress={() => gotoEvent(event.id)}>
            <View style={{ flexDirection: 'row' }}>
              {mobileView && !smallMobileView && (
                <Surface
                  style={{
                    elevation: 1,
                    borderTopLeftRadius: roundness,
                    padding: 12,
                    position: 'relative',
                  }}
                >
                  <Image
                    source={{ uri: event.illustration, width: 64, height: 64 }}
                    style={{
                      borderRadius:
                        roundness > 0
                          ? Math.max(2, Math.round(roundness * 0.7))
                          : 0,
                    }}
                  />
                </Surface>
              )}
              <View style={{ flex: 1, maxWidth: '100%', width: '100%' }}>
                <Surface
                  style={{
                    elevation: 1,
                    borderTopLeftRadius: mobileView ? 0 : roundness,
                    borderTopRightRadius: mobileView ? 0 : roundness,
                    paddingTop: 12,
                    position: 'relative',
                    flex: 1,
                  }}
                >
                  <Card.Content>
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginBottom: 10,
                      }}
                    >
                      {smallMobileView && event.illustration ? (
                        <Avatar.Image
                          size={40}
                          source={{
                            uri: event.illustration,
                            width: 128,
                            height: 128,
                          }}
                          style={{ marginRight: 10, marginLeft: -2 }}
                        />
                      ) : null}
                      <Title
                        style={{
                          marginRight: 40,
                          marginLeft:
                            smallMobileView && event.illustration ? 8 : 0,
                          lineHeight: 22,
                        }}
                      >
                        {event.title}
                      </Title>
                    </View>
                    <Fragment>
                      {event.description.split('\n').map((paragraph, index) => (
                        <Paragraph
                          key={index}
                          style={{
                            width: '100%',
                            flex: 1,
                            flexBasis: '70%',
                            lineHeight: 22,
                            marginBottom: 10,
                          }}
                        >
                          {paragraph}
                        </Paragraph>
                      ))}
                    </Fragment>
                  </Card.Content>
                </Surface>
              </View>
            </View>
          </TouchableWithoutFeedback>
          {/*<SubEvents />*/}
          {children}

          {event.hasTimeslots ? (
            <TimeslotProgressBar event={event} />
          ) : (
            <EventProgressBar event={event} />
          )}
        </View>
      </View>
      {!event.isLast && <Spacer space={1} />}
    </Fragment>
  );
}

function TimeslotProgressBar({ event }: { event: NowEventData }) {
  const { data: timeslots, loading: loadingTimeslots } = useBlockDataTimeslots(
    event.id
  );
  const timeslot = timeslots && fetchRegisteredTimeslot(timeslots.timeslots);
  const { gotoTimeslot, gotoEvent } = useBlockNavigation();
  const localizedTitle = useLocalization(
    timeslot?.nameLocalized?.full,
    timeslot?.name.full
  );

  if (timeslot) {
    const name =
      localizedTitle || timeslot.name.full || i18n.t('blocks.timeslot.name');
    const time = localeTimeString(new Date(timeslot.start.unix));

    const hasPage = timeslot.pageRef !== null && timeslot.pageRef.page;

    return (
      <Fragment>
        <Surface style={{ elevation: 1 }}>
          <List.Item
            title={i18n.t('blocks.timeslot.description', { name, time })}
            description={i18n.t('app.now.your_timeslot')}
            descriptionStyle={{ marginTop: 4 }}
            onPress={() =>
              hasPage ? gotoTimeslot(timeslot._id) : gotoEvent(event.id)
            }
            right={
              hasPage
                ? () => (
                    <PrimaryButton
                      loading={loadingTimeslots}
                      onPress={() => gotoTimeslot(timeslot._id)}
                      style={{ marginVertical: 'auto', alignSelf: 'center' }}
                    >
                      {i18n.t('app.actions.view_timeslot')}
                    </PrimaryButton>
                  )
                : undefined
            }
          />
        </Surface>
        <CardTimeProgressBar
          from={timeslot.start.unix}
          to={timeslot.end.unix}
        />
      </Fragment>
    );
  }

  return <EventProgressBar event={event} />;
}

function EventProgressBar({ event }: { event: NowEventData }) {
  return (
    <CardTimeProgressBar from={event.duration.start} to={event.duration.end} />
  );
}

export const EventCard = memo(EventCard_);
