import * as Sentry from 'sentry-expo';

const SENTRY_DSN = process.env.SENTRY_DSN || undefined;

Sentry.init({
  debug: __DEV__,
  dsn: SENTRY_DSN,
  enableInExpoDevelopment: false,
});

const Browser = Sentry.Browser;
export { Browser as Sentry };
