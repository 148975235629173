import { useBlockDataGameMap } from '@introcloud/blocks-interface';
import { BlockProps, GameMapBlockOptions } from '@introcloud/page';
import React from 'react';
import { ActivityIndicator, Card, HelperText } from 'react-native-paper';
import { GameMap } from './GameMap';

export function GameMapBlock(block: BlockProps<GameMapBlockOptions>) {
  const { data: gameMap, loading, error } = useBlockDataGameMap(block.value.id);

  if (loading) {
    return (
      <Card
        elevation={1}
        style={{
          width: '100%',
          minHeight: 320,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ActivityIndicator size="large" />
      </Card>
    );
  }

  if (error || !gameMap) {
    // TODO: error tracking
    return (
      <Card
        elevation={1}
        style={{
          width: '100%',
          minHeight: 320,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <HelperText
          type="error"
          style={{ justifyContent: 'center', margin: 'auto' }}
        >
          {error?.message}
        </HelperText>
      </Card>
    );
  }

  return <GameMap data={gameMap} />;
}
