import {
  fetchApplicationSwipePotentials,
  swipe,
  TactileAttendanceResponse,
  TactileInterviewAnswer,
  TactileInterviewQuestion,
  TactileSwipePotential,
  type TactileModuleEventInterview,
  type TactileModuleSwipe,
} from '@introcloud/api-client';
import { type PreparedEvent } from '../hooks/useEvents';
import { Keyboard, StyleSheet, useWindowDimensions, View } from 'react-native';
import {
  PanGestureHandler,
  PanGestureHandlerGestureEvent,
  TapGestureHandler,
  TapGestureHandlerGestureEvent,
} from 'react-native-gesture-handler';
import Animated, {
  runOnJS,
  useAnimatedGestureHandler,
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withSpring,
} from 'react-native-reanimated';
import { snapPoint } from 'react-native-redash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useEndpoint, useSafeAuthorization } from '../hooks/useAuthentication';
import { SHOULD_DEBUG_FETCH } from '../utils';
import { useQuery, useQueryClient } from 'react-query';
import { MemoryValue, useMutableMemoryValue } from 'expo-use-memory-value';
import { Profile } from './Profile';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  Avatar,
  Caption,
  Card,
  HelperText,
  MD2Theme,
  Paragraph,
  Text,
  TextInput,
  useTheme,
} from 'react-native-paper';
import { useBlockImageUrl } from '@introcloud/blocks-interface';
import {
  useSubmitSwipeInterview,
  useSwipeInterviewGuest,
} from '../hooks/useSwipeInterviewGuest';
import { FetchMediaError } from 'fetch-media';
import { AnimatedDialog } from './AnimatedDialog';
import { i18n, PrimaryButton, useLocale } from '@introcloud/blocks';
import { useIsColorDark } from 'use-color-luminance';
import { useUser } from '../hooks/useUser';
import { NotReady } from '../core/errors/NotReady';

export function SwipeEvent({
  _id,
  module: {
    interview,
    swipe: { card },
  },
}: PreparedEvent & {
  module: { interview: TactileModuleEventInterview; swipe: TactileModuleSwipe };
}) {
  const { data: guest, error, loading } = useSwipeInterviewGuest(_id);

  const state = useMatchingState({
    event: _id,
    interview,
    guest,
    error,
    loading,
  });

  switch (state) {
    case null: {
      return <LoadingState />;
    }

    case 'tos': {
      return (
        <TermsOfServiceState
          guest={guest}
          event={_id}
          profileQuestion={interview.question[0]}
        />
      );
    }

    case 'interview': {
      return (
        <InterviewState event={_id} interviewQuestions={interview.question} />
      );
    }

    case 'matching': {
      return <MatchingState event={_id} cardType={card?.image ?? 'big'} />;
    }
  }
}

function LoadingState() {
  return (
    <ActivityIndicator
      size="large"
      style={{ alignSelf: 'center', margin: 32, flex: 1 }}
    />
  );
}

function TermsOfServiceState({
  guest,
  event,
  profileQuestion,
}: {
  guest: undefined | TactileAttendanceResponse;
  event: string;
  profileQuestion: TactileInterviewQuestion;
}) {
  const [preAccepted, setPreAccepted] = useState(false);
  const onPreAccept = useCallback(() => setPreAccepted(true), [setPreAccepted]);

  const { mutateAsync, isLoading: mutating } = useSubmitSwipeInterview(event);

  return (
    <AnimatedDialog
      onAccept={(profileText: string) => {
        if (!profileText) {
          return;
        }

        const answers: TactileInterviewAnswer[] =
          guest?.module.interview.answer ?? [];

        // Delete old answer
        const hasCurrent = answers.findIndex(
          (answer) => answer.questionId === profileQuestion._id
        );

        if (hasCurrent !== -1) {
          answers.splice(hasCurrent, 1);
        }

        answers.unshift({
          questionId: profileQuestion._id,
          value: profileText,
        });

        //debugger;

        return mutateAsync(answers).catch(() => {});
      }}
    >
      {(accept) =>
        preAccepted ? (
          <InputDescription
            onAccept={accept}
            loading={mutating}
            defaultText={String(
              guest?.module.interview.answer.find(
                (answer) => answer.questionId === profileQuestion._id
              )?.value ?? ''
            )}
          />
        ) : (
          <Terms onAccept={onPreAccept} />
        )
      }
    </AnimatedDialog>
  );
}

function Terms({ onAccept }: { onAccept: () => void }) {
  useLocale();

  const {
    colors: { primary },
  } = useTheme<MD2Theme>();

  const color = useIsColorDark(primary) ? '#FFF' : '#000';

  return (
    <Card
      elevation={4}
      style={{ maxWidth: 400, width: '100%', alignSelf: 'center' }}
    >
      <Card.Content>
        <Paragraph style={{ lineHeight: 22 }}>
          {i18n.t('app.matching.terms_to_accept')}
        </Paragraph>
      </Card.Content>
      <Card.Actions style={{ padding: 12, justifyContent: 'center' }}>
        <PrimaryButton
          onPress={onAccept}
          buttonColor={primary}
          textColor={color}
        >
          {i18n.t('app.matching.actions.accept')}
        </PrimaryButton>
      </Card.Actions>
    </Card>
  );
}

function InputDescription({
  onAccept,
  loading,
  defaultText,
}: {
  loading: boolean;
  onAccept: (text: string) => void;
  defaultText: string;
}) {
  const [chosenText, setChosenText] = useState(defaultText);
  const { data: user } = useUser();

  useEffect(() => {
    setChosenText(defaultText);
  }, [defaultText]);

  const {
    colors: { primary, accent },
    dark,
  } = useTheme<MD2Theme>();

  const primaryIsDark = useIsColorDark(primary);
  const accentIsDark = useIsColorDark(accent);

  const contrastAccentColor =
    primaryIsDark !== dark
      ? primary
      : accentIsDark !== dark
      ? accent
      : dark
      ? '#FFF'
      : '#000';

  const contrastOnPrimaryColor = useIsColorDark(primary) ? '#FFF' : '#000';

  useLocale();

  return (
    <Card
      elevation={4}
      style={{ maxWidth: 400, width: '100%', alignSelf: 'center' }}
    >
      <Card.Content>
        <Paragraph style={{ lineHeight: 22, marginBottom: 16 }}>
          {i18n.t('app.matching.introduction', {
            user: user?.name.first || 'you',
          })}

          <Text style={{ fontWeight: 'bold' }}>
            {i18n.t('app.matching.explanation')}
          </Text>
        </Paragraph>
        <TextInput
          multiline
          label={i18n.t('app.matching.label_about')}
          style={{ height: 150 }}
          value={chosenText}
          onChangeText={setChosenText}
          activeUnderlineColor={contrastAccentColor}
          maxLength={200}
          returnKeyType="done"
          onSubmitEditing={() => {
            Keyboard.dismiss();
            chosenText && onAccept(chosenText);
          }}
        />
        <HelperText style={{ textAlign: 'right' }} type="info">
          {chosenText.length} / 200
        </HelperText>
      </Card.Content>
      <Card.Actions style={{ padding: 12, justifyContent: 'center' }}>
        <PrimaryButton
          loading={loading}
          onPress={() => {
            Keyboard.dismiss();
            onAccept(chosenText);
          }}
          buttonColor={primary}
          textColor={contrastOnPrimaryColor}
          disabled={chosenText.length === 0}
        >
          {i18n.t('app.matching.actions.ready')}
        </PrimaryButton>
      </Card.Actions>
    </Card>
  );
}

function InterviewState({
  event,
  interviewQuestions,
}: {
  event: string;
  interviewQuestions: TactileInterviewQuestion[];
}) {
  const {
    profiles,
    loading,
    error: profilesError,
    onSwipe,
  } = useInterview({ event, interviewQuestions });
  return (
    <Swipe
      loading={loading}
      profiles={profiles}
      onSwipe={onSwipe}
      cardType="small"
    />
  );
}

function MatchingState({
  event,
  cardType,
}: {
  event: string;
  cardType: 'big' | 'small' | 'cover';
}) {
  const {
    profiles,
    loading,
    error: profilesError,
    onSwipe,
  } = useProfiles({ event });
  return (
    <Swipe
      loading={loading}
      profiles={profiles}
      onSwipe={onSwipe}
      cardType={cardType}
    />
  );
}

function Swipe({
  profiles,
  onSwipe,
  cardType,
  loading,
}: {
  profiles: Profile[];
  onSwipe: (profile: Profile, kind: 'like' | 'dislike' | null) => void;
  cardType: 'big' | 'small' | 'cover';
  loading: boolean;
}) {
  const [swiped, setSwiped] = useState<Record<string, true>>({});

  const onTap = (profile: Profile) => {
    console.debug(`[match] tapped ${profile.id} (${profile.title})`);
    setActiveProfile(profile.id);
  };

  const onUntap = () => {
    setActiveProfile(null);
  };

  const onSwipeExtended = (profile: Profile, direction: 'left' | 'right') => {
    console.debug(
      `[match] swiped ${profile.id} (${profile.title}) to ${direction}`
    );
    setActiveProfile(null);
    setSwiped((prev) => {
      prev[profile.id] = true;
      return prev;
    });

    onSwipe(
      profile,
      direction === 'left' ? 'dislike' : direction === 'right' ? 'like' : null
    );
  };

  const [activeProfile, setActiveProfile] = useState<string | null>(null);
  const activeProfileExists = profiles.some(({ id }) => id === activeProfile);

  if (profiles.length === 0 && !loading) {
    return <NoOneAround />;
  }

  return (
    <View style={{ flex: 1 }}>
      {profiles.map((profile, i) => {
        if (swiped[profile.id]) {
          return null;
        }

        return (
          <SwipeCard
            active={activeProfileExists ? activeProfile === profile.id : null}
            key={profile.id}
            profile={profile}
            onSwipe={onSwipeExtended}
            onTap={onTap}
            onUntap={onUntap}
            initialDelay={i * 75}
            cardType={cardType}
          />
        );
      })}
    </View>
  );
}

const POTENTIALS_STATE = new MemoryValue(Math.random());

function useProfiles({ event }: { event: string }) {
  const [cacheState, setCacheState] = useMutableMemoryValue(POTENTIALS_STATE);

  const isEnabled = useIsFocused();
  const endpoint = useEndpoint();
  const authorization = useSafeAuthorization();
  const requiredAnswers = useRef<string[]>([]);
  const queryClient = useQueryClient();

  const queryKey = [cacheState, 'swipe.potentials'];

  const fetcher = useCallback(async ({ signal }: { signal?: AbortSignal }) => {
    await new Promise((resolve) => setTimeout(resolve, 250)); //1250));

    if (!authorization) {
      throw new Error('Expected authorization');
    }

    const potentials = await fetchApplicationSwipePotentials(
      event,
      endpoint,
      authorization,
      signal,
      SHOULD_DEBUG_FETCH
    );

    return potentials;
  }, []);

  const {
    data: potentials,
    error,
    isLoading,
  } = useQuery(queryKey, fetcher, {
    staleTime: 1000 * 60 * 60,
    enabled: isEnabled,
  });

  const loading = (!error && potentials === undefined) || isLoading;

  useEffect(() => {
    if (!potentials) {
      return;
    }

    requiredAnswers.current = potentials.map((p) => p._id);
  }, [queryKey, Boolean(potentials)]);

  const profiles = useMemo(
    (): Profile[] =>
      potentials
        ? potentials
            .map((q) => ({
              id: q._id,

              title: q.userRef.user.name.first || '',
              description:
                (q.module.interview.answer[0]?.value as string) || '',
              image: q.userRef.user.image?.profile || undefined,
              likes: q.module?.interview.answer
                .filter((a) => a.value === true)
                .map((l) => l.questionId),
              dislikes: q.module?.interview.answer
                .filter((a) => a.value === false)
                .map((l) => l.questionId),
            }))
            // Remove previously answered
            .reverse()
        : [],
    [potentials]
  );

  const onSwipe = useCallback(
    async (profile: Profile, kind: 'like' | 'dislike' | null) => {
      if (!kind) {
        throw new Error('Expected like or dislike');
      }

      if (!authorization) {
        throw new Error('Expected authorization');
      }

      const index = requiredAnswers.current.indexOf(profile.id);
      if (index !== -1) {
        requiredAnswers.current.splice(index, 1);
      }

      await swipe(
        event,
        profile.id,
        kind,
        endpoint,
        authorization,
        undefined,
        SHOULD_DEBUG_FETCH
      )
        .then(() => {
          // success
          queryClient.setQueryData<TactileSwipePotential[]>(
            queryKey,
            (data) => {
              if (!data) {
                return [];
              }

              return data.filter((p) =>
                requiredAnswers.current.includes(p._id)
              );
            }
          );
        })
        .catch(console.error);

      if (requiredAnswers.current.length === 0) {
        setCacheState(Math.random());
      }
    },
    [requiredAnswers, setCacheState]
  );

  return { loading, error, profiles, onSwipe };
}

function useInterview({
  event,
  interviewQuestions,
}: {
  event: string;
  interviewQuestions: TactileInterviewQuestion[];
}) {
  const questions = useMemo(
    () => interviewQuestions.filter((q) => q.type === 'toggle'),
    [interviewQuestions]
  );

  const { data: guest, error, isLoading } = useSwipeInterviewGuest(event);
  const { mutateAsync, error: submitError } = useSubmitSwipeInterview(event);

  const nextGuest = useRef<TactileAttendanceResponse | null>(null);
  if (!nextGuest.current || nextGuest.current._id !== guest?._id) {
    nextGuest.current = guest ?? null;
  }

  const loading = isLoading;

  const profiles: Profile[] = useMemo(() => {
    if (!guest) {
      return [];
    }

    return (
      questions
        .map((q) => ({
          id: q._id,

          title: q.description || '',
          description: q.options.helpText || '',
          image: undefined,
        }))
        // Remove previously answered
        .filter(
          (q) =>
            !guest.module.interview.answer.some((a) => a.questionId === q.id)
        )
        .reverse()
    );
  }, [questions, guest]);

  const onSwipe = useCallback(
    (profile: Profile, kind: 'like' | 'dislike' | null) => {
      if (!nextGuest.current || !kind) {
        return;
      }

      const guestAnswers = nextGuest.current.module.interview.answer;
      const hasCurrent = guestAnswers.findIndex(
        (answer) => answer.questionId === profile.id
      );

      // Replace or insert this answer
      guestAnswers.splice(
        hasCurrent === -1 ? guestAnswers.length : hasCurrent,
        hasCurrent === -1 ? 0 : 1,
        {
          questionId: profile.id,
          value: kind === 'like',
        }
      );

      // Check if done
      if (
        questions.every((q) => guestAnswers.find((a) => a.questionId === q._id))
      ) {
        mutateAsync(guestAnswers).catch(() => {});
      }
    },
    [questions, guest, nextGuest]
  );

  return { loading, error: error ?? submitError, profiles, onSwipe };
}

function useMatchingState({
  event,
  interview,
  guest,
  error,
  loading,
}: {
  event: string;
  interview: TactileModuleEventInterview;
  guest: undefined | TactileAttendanceResponse;
  error: null | NotReady | FetchMediaError;
  loading: boolean;
}) {
  if (!guest && !error) {
    return null;
  }

  const needsToAcceptTheTos =
    error && error instanceof FetchMediaError && error.response.status === 404;

  if (needsToAcceptTheTos) {
    return 'tos';
  }

  const applicableInterviewQuestions = interview.question.filter(
    (q) => q.type === 'toggle'
  );

  // Has not yet started filling in the profile
  const needsToFillInProfile =
    (!loading && !guest && error && error instanceof FetchMediaError) ||
    (guest &&
      applicableInterviewQuestions.length !== 0 &&
      guest.module.interview.answer.length === 0);

  if (needsToFillInProfile) {
    return 'tos';
  }

  // Needs to fill in at least one question
  const needsInterview =
    applicableInterviewQuestions.length - 1 >
    (guest?.module.interview.answer.length || 0);

  if (needsInterview) {
    return 'interview';
  }

  return 'matching';
}

function SwipeCard({
  active,
  cardType,
  initialDelay,
  profile,
  onSwipe,
  onTap,
  onUntap,
}: {
  active: true | false | null;
  cardType: 'small' | 'big' | 'cover';
  initialDelay: number;
  profile: Profile;
  onSwipe: (profile: Profile, direction: 'left' | 'right') => void;
  onTap: (profile: Profile) => void;
  onUntap: () => void;
}) {
  const [theta] = useState(() => Math.random() * 12 - 6);

  const x = useSharedValue(0);
  const y = useSharedValue(100);
  const opacity = useSharedValue(0);
  const rotateX = useSharedValue(30);
  const rotateZ = useSharedValue(0);
  const scale = useSharedValue(2);
  const nextDest = useSharedValue(0);

  const { width: windowWidth } = useWindowDimensions();
  const side = Math.min(150, Math.max(300, windowWidth / 2 - CARD_WIDTH / 2));
  const snapPoints = useMemo(() => [-side, 0, side], [side]);

  useEffect(() => {
    y.value = withDelay(initialDelay, withSpring(0));
    opacity.value = withDelay(initialDelay, withSpring(1));
    scale.value = withDelay(initialDelay, withSpring(1));
    rotateZ.value = withDelay(initialDelay, withSpring(theta));
  }, []);

  const onTapAnimated = () => onTap(profile);
  const onUntapAnimated = () => onUntap();
  const onSwipeAnimated = (direction: boolean) =>
    onSwipe(profile, direction ? 'right' : 'left');

  const onTapGestureEvent = useAnimatedGestureHandler<
    TapGestureHandlerGestureEvent,
    {}
  >(
    {
      onEnd: ({}) => {
        if (active !== true) {
          scale.value = withSpring(1.1);
          rotateX.value = withSpring(0);
          rotateZ.value = withSpring(0);

          runOnJS(onTapAnimated)();
        } else {
          scale.value = withSpring(1);
          rotateX.value = withSpring(30);
          rotateZ.value = withSpring(theta);

          runOnJS(onUntapAnimated)();
        }
      },
    },
    [scale, rotateX, rotateZ, active]
  );

  const onPanGestureEvent = useAnimatedGestureHandler<
    PanGestureHandlerGestureEvent,
    { x: number; y: number }
  >(
    {
      onStart: (_, ctx) => {
        ctx.x = x.value;
        ctx.y = y.value;

        // Pick up
        scale.value = withSpring(1.1);
        rotateX.value = withSpring(0);
        rotateZ.value = withSpring(0);

        nextDest.value = 0;
      },
      onActive: ({ translationX, translationY, velocityX }, ctx) => {
        const dest = snapPoint(x.value, velocityX, snapPoints);

        nextDest.value = dest;

        x.value = ctx.x + translationX;
        y.value = ctx.y + translationY;
      },
      onEnd: ({ velocityX, velocityY }) => {
        const dest = snapPoint(x.value, velocityX, snapPoints);
        const nextX = (dest > 0 ? 1 : dest < 0 ? -1 : 0) * (windowWidth + 300);

        x.value = withSpring(nextX, {
          velocity: velocityX,
        });
        y.value = withSpring(0, { velocity: velocityY });

        // Swipe it
        if (dest != 0) {
          runOnJS(onSwipeAnimated)(dest > 0);
        } else {
          runOnJS(onUntapAnimated)();
        }

        // Reset perspective
        scale.value = withSpring(1);
        rotateX.value = withSpring(30);
        rotateZ.value = withSpring(theta);
      },
    },
    [theta, snapPoints, profile, windowWidth]
  );

  const style = useAnimatedStyle(() => {
    return {
      opacity: opacity.value,
      transform: [
        { perspective: 1500 },
        { rotateX: `${rotateX.value}deg` },
        { rotateZ: `${rotateZ.value}deg` },
        { translateX: x.value },
        { translateY: y.value },
        { scale: scale.value },
      ],
    };
  }, [rotateX, rotateZ, x, y, scale, opacity]);

  const rightActionStyle = useAnimatedStyle(() => {
    return {
      opacity: withSpring(nextDest.value > 0 ? 1 : 0),
    };
  }, [nextDest]);

  const leftActionStyle = useAnimatedStyle(() => {
    return {
      opacity: withSpring(nextDest.value < 0 ? 1 : 0),
    };
  }, [nextDest]);

  const { data: image } = useBlockImageUrl(profile.image ?? null, 'icon_512');

  return (
    <View pointerEvents="box-none" style={styles.container}>
      <TapGestureHandler onGestureEvent={onTapGestureEvent}>
        <Animated.View style={style}>
          <PanGestureHandler onGestureEvent={onPanGestureEvent}>
            <Animated.View style={[{ padding: 40 }]}>
              <Card
                style={[styles.card]}
                contentStyle={{
                  flexDirection: 'column',
                  width: '100%',
                  flex: 1,
                }}
                elevation={1}
              >
                <View
                  pointerEvents="none"
                  style={[
                    styles.overlay,
                    { width: '100%', maxWidth: '100%' },
                    cardType === 'big'
                      ? { justifyContent: 'flex-end' }
                      : {
                          justifyContent: 'space-between',
                        },
                  ]}
                >
                  {image && cardType === 'big' ? (
                    <Avatar.Image
                      size={168}
                      source={{
                        uri: image || '',
                        width: 512,
                        height: 512,
                      }}
                      style={{
                        position: 'absolute',
                        alignSelf: 'center',
                        marginBottom: 'auto',
                        top: 8,
                      }}
                    />
                  ) : null}

                  <View style={styles.header}>
                    <Animated.View
                      style={[
                        {
                          borderWidth: 4,
                          borderRadius: 5,
                          padding: 8,
                          borderColor: '#6ee3b4',
                        },
                        rightActionStyle,
                      ]}
                    >
                      <Text style={styles.likeLabel}>Like</Text>
                    </Animated.View>
                    <Animated.View
                      style={[
                        {
                          borderWidth: 4,
                          borderRadius: 5,
                          padding: 8,
                          borderColor: '#ec5288',
                          opacity: 1,
                        },
                        leftActionStyle,
                      ]}
                    >
                      <Text style={styles.nopeLabel}>Nope</Text>
                    </Animated.View>
                  </View>

                  <View style={styles.footer}>
                    {profile.description ? (
                      <Caption selectable={false} numberOfLines={5}>
                        {profile.description.replace(/\n/g, '')}
                      </Caption>
                    ) : null}
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginTop: profile.image ? 4 : 0,
                      }}
                    >
                      {image && cardType === 'small' ? (
                        <Avatar.Image
                          size={36}
                          source={{
                            uri: image || '',
                            width: 128,
                            height: 128,
                          }}
                          style={{ marginRight: 8 }}
                        />
                      ) : null}
                      <Text
                        style={[
                          cardType === 'small' ? styles.nameSmall : styles.name,
                        ]}
                        numberOfLines={cardType === 'small' ? 6 : 2}
                        selectable={false}
                      >
                        {profile.title}
                      </Text>
                    </View>
                  </View>
                </View>
              </Card>
            </Animated.View>
          </PanGestureHandler>
        </Animated.View>
      </TapGestureHandler>
    </View>
  );
}

function NoOneAround() {
  useLocale();

  return (
    <AnimatedDialog onAccept={() => {}}>
      {() => (
        <Card
          elevation={4}
          style={{ maxWidth: 400, width: '100%', alignSelf: 'center' }}
        >
          <Card.Content>
            <Paragraph style={{ lineHeight: 22 }}>
              {i18n.t('app.matching.no_matches')}
            </Paragraph>
          </Card.Content>
        </Card>
      )}
    </AnimatedDialog>
  );
}

const CARD_WIDTH = 280;
const CARD_HEIGHT = 400;

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center',
  },

  card: {
    borderRadius: 10,
    width: CARD_WIDTH,
    height: CARD_HEIGHT,
    justifyContent: 'center',
    alignItems: 'center',
  },

  // Card
  overlay: {
    flex: 1,
    justifyContent: 'space-between',
    padding: 16,
    maxHeight: '100%',
    overflow: 'hidden',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  nameSmall: {
    fontSize: 24,
    maxWidth: '100%',
  },
  name: {
    fontSize: 32,
    maxWidth: '100%',
  },

  like: {
    borderWidth: 4,
    borderRadius: 5,
    padding: 8,
    borderColor: '#6ee3b4',
  },
  likeLabel: {
    fontSize: 32,
    color: '#6ee3b4',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  nope: {
    borderWidth: 4,
    borderRadius: 5,
    padding: 8,
    borderColor: '#ec5288',
  },
  nopeLabel: {
    fontSize: 32,
    color: '#ec5288',
    fontWeight: 'bold',
  },

  footer: {
    flexDirection: 'column',
  },
});
