import { i18n, OutlinedButton, useLocale } from '@introcloud/blocks';
import { Fragment, useEffect, useState } from 'react';
import { ActivityIndicator, Platform, ScrollView, View } from 'react-native';
import { Dialog, List, Menu, Paragraph } from 'react-native-paper';
import { useCurrencyDisplay } from './useCurrencyDisplay';
import { usePaymentMethods } from './usePaymentMethods';
import { useTopUp } from './useTopUp';
import { useDefaultTopupAmount, useTopupAmounts } from './useTopupAmounts';

export function TopUpDialogContent({
  visible,
  defaultAmount,
}: {
  visible: boolean;
  defaultAmount: number | null;
}) {
  useLocale();

  const [selectedMethod, setSelectedMethod] = useState<string | undefined>();
  const [selectedAmount, setSelectedAmount] = useState<number | null>(null);

  const { mutateAsync: startTopUp } = useTopUp();
  const defaults = useDefaultTopupAmount();
  const { amounts, fee } = useTopupAmounts();
  const { data } = usePaymentMethods({ enabled: visible });

  if (defaults && defaultAmount === null) {
    defaultAmount = defaults.cents;
  }

  useEffect(() => {
    if (defaultAmount === null) {
      return;
    }

    const nextIndex = amounts.findIndex(
      (amount) => amount.cents === defaultAmount
    );
    if (nextIndex === -1) {
      return;
    }

    setSelectedAmount((prev) => (prev === null ? nextIndex : prev));
  }, [defaultAmount, amounts, setSelectedAmount]);

  if (!data?.ok) {
    return (
      <Fragment>
        <Dialog.Title>{i18n.t('app.payment.top-up.title')}</Dialog.Title>
        <View style={{ justifyContent: 'center', minHeight: 200 }}>
          <ActivityIndicator />
        </View>
      </Fragment>
    );
  }

  return (
    <View style={{ position: 'relative' }}>
      <Dialog.Title>{i18n.t('app.payment.top-up.title')}</Dialog.Title>

      <View style={{ position: 'absolute', right: 12, top: 18 }}>
        <Menu
          anchor={
            <SelectableAnchor
              onPress={() => setSelectedAmount(null)}
              selected={
                selectedAmount !== null ? amounts[selectedAmount]?.cents : null
              }
            />
          }
          onDismiss={() => {
            const index = amounts.findIndex(
              ({ cents }) => cents === defaults?.cents
            );
            setSelectedAmount(index === -1 ? null : index);
          }}
          visible={selectedAmount === null}
        >
          {amounts.map((amount, index) => (
            <SelectableOption
              key={index}
              amount={amount.cents}
              onPress={() => setSelectedAmount(index)}
            />
          ))}
        </Menu>
      </View>

      <Paragraph
        style={{
          marginHorizontal: 24,
          marginTop: -10,
          marginBottom: 20,
          opacity: 0.8,
          maxWidth: Platform.select({
            web: '65ch' as unknown,
            default: 320,
          }) as number,
        }}
      >
        {i18n.t('app.payment.top-up.description')}{' '}
        {fee && fee.cents
          ? i18n.t('app.payment.top-up.service_fee_flat', { fee: fee.euro })
          : ''}
      </Paragraph>

      <Dialog.ScrollArea style={{ paddingHorizontal: 0, maxHeight: 300 }}>
        <ScrollView contentContainerStyle={{ margin: 0, paddingHorizontal: 0 }}>
          {selectedMethod ? (
            <List.Item
              left={() => <List.Icon icon="chevron-left" />}
              title={i18n.t('app.payment.top-up.change')}
              onPress={() => setSelectedMethod(undefined)}
            />
          ) : null}
          {selectedMethod
            ? (
                data.methods.find(
                  (method) => method.methodId === selectedMethod
                )?.banks || []
              ).map((method) => (
                <List.Item
                  key={method.name}
                  left={() => <List.Icon icon={{ uri: method.imageUrl }} />}
                  title={method.name}
                  onPress={() =>
                    startTopUp({
                      paymentMethod: selectedMethod!,
                      bankId: method.bankId,
                      amount: amounts[selectedAmount!].cents,
                    })
                  }
                />
              ))
            : null}
          {selectedMethod
            ? null
            : data.methods.map((method) => (
                <List.Item
                  key={method.name}
                  left={() => <List.Icon icon={{ uri: method.imageUrl }} />}
                  title={method.name}
                  titleStyle={{ includeFontPadding: false }}
                  description={
                    method.percentage
                      ? i18n.t('app.payment.top-up.service_fee_percentage', {
                          percentage: method.percentage * 100,
                        })
                      : undefined
                  }
                  descriptionStyle={{ includeFontPadding: false, marginTop: 4 }}
                  onPress={
                    method.banks.length > 0
                      ? () => setSelectedMethod(method.methodId)
                      : () =>
                          startTopUp({
                            paymentMethod: method.methodId,
                            amount: amounts[selectedAmount!].cents,
                          })
                  }
                />
              ))}
        </ScrollView>
      </Dialog.ScrollArea>
    </View>
  );
}

function SelectableAnchor({
  selected,
  onPress,
}: {
  selected: null | undefined | number;
  onPress(): void;
}) {
  const { tokens, display, symbol } = useCurrencyDisplay(selected ?? null);
  const currencyDisplay = `${symbol} ${display}`;

  return (
    <OutlinedButton onPress={onPress}>
      {selected
        ? tokens
          ? `${currencyDisplay} (€ ${(selected / 100).toFixed(2)})`
          : currencyDisplay
        : i18n.t('app.payment.top-up.label')}
    </OutlinedButton>
  );
}

function SelectableOption({
  amount,
  onPress,
}: {
  amount: number;
  onPress(): void;
}) {
  const { tokens, display, symbol } = useCurrencyDisplay(amount ?? null);
  const currencyDisplay = `${symbol} ${display}`;

  return (
    <Menu.Item
      title={
        tokens
          ? `${currencyDisplay} (€ ${(amount / 100).toFixed(2)})`
          : currencyDisplay
      }
      onPress={onPress}
    />
  );
}
