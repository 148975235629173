import {
  fetchApplicationPage,
  fetchApplicationVideo,
  TactileVideo,
} from '@introcloud/api-client';
import { CURRENT_LOCALE, i18n } from '@introcloud/blocks';
import { VideoStreamBlockOptions } from '@introcloud/page';
import { prepare } from '@introcloud/page/dist/blocks/VideoEmbedBlock';
import { KIND_VIDEO_STREAM } from '@introcloud/page/dist/Kinds';
/*import {
  lockAsync,
  OrientationLock,
  unlockAsync,
} from 'expo-screen-orientation';*/
import { localize } from '@introcloud/blocks/dist/useLocale';
import { FetchMediaError } from 'fetch-media';
import React, { useCallback, useMemo } from 'react';
import {
  Dimensions,
  EmitterSubscription,
  Keyboard,
  KeyboardEvent,
  Platform,
  ScaledSize,
  StatusBar,
} from 'react-native';
import { Card } from 'react-native-paper';
import Animated, {
  add,
  block,
  divide,
  EasingNode,
  Extrapolate,
  interpolateNode,
  min,
  multiply,
  Node,
  sub,
  Value,
} from 'react-native-reanimated';
import { withTimingTransition } from 'react-native-redash/lib/module/v1/index';
import { EdgeInsets, withSafeAreaInsets } from 'react-native-safe-area-context';
import { useQuery } from 'react-query';
import { NotReady } from '../core/errors/NotReady';
import { DarkTheme, ThemeProvider } from '../core/FixedThemeProvider';
import { navigationRef } from '../core/RootNavigation';
import { useEndpoint, useSafeAuthorization } from '../hooks/useAuthentication';
import { usePage } from '../hooks/usePage';
import { SHOULD_DEBUG_FETCH } from '../utils';
import { AnimatedVideo } from './AnimatedVideo';
import { PlayerVideo } from './PlayerContext';
import PlayerControls from './PlayerControls';
import { VideoContent } from './VideoContent';

type VideoModalProps = {
  video: PlayerVideo;
  primary: string;
  withoutKeyboardHeight: number;
};

const { width: INITIAL_WIDTH, height: INITIAL_HEIGHT } =
  Dimensions.get('window');

const MIN_HEIGHT = 64;

class VideoModal_ extends React.PureComponent<
  VideoModalProps & { insets: EdgeInsets }
> {
  width = new Value(INITIAL_WIDTH);
  height = new Value(
    INITIAL_HEIGHT +
      Platform.select({ android: StatusBar.currentHeight || 20, default: 0 })
  );
  keyboardHeight = new Value<number>(0);
  slideUpState = new Value<number>(1);
  active: boolean = true;

  midBound: Node<number>;
  upperBound: Node<number>;
  animation: Animated.Node<number>;

  change: EmitterSubscription | undefined;
  willShow: EmitterSubscription | undefined;
  willHide: EmitterSubscription | undefined;
  didShow: EmitterSubscription | undefined;
  didHide: EmitterSubscription | undefined;

  constructor(props: VideoModalProps & { insets: EdgeInsets }) {
    super(props);
    this.midBound = sub(this.height, 132);
    this.upperBound = add(this.midBound, MIN_HEIGHT);

    const window = Dimensions.get('window');

    this.width.setValue(window.width);
    this.height.setValue(
      window.height +
        Platform.select({ android: StatusBar.currentHeight || 20, default: 0 })
    );

    this.animation = withTimingTransition(this.slideUpState, {
      duration: 300,
      easing: EasingNode.inOut(EasingNode.ease),
    });
  }

  componentDidMount() {
    this.change = Dimensions.addEventListener(
      'change',
      this.onDimensionsChanged
    );

    this.willShow = Keyboard.addListener(
      'keyboardWillShow',
      this.onKeyboardShow
    );
    this.willHide = Keyboard.addListener(
      'keyboardWillHide',
      this.onKeyboardHide
    );
    this.didShow = Keyboard.addListener('keyboardDidShow', this.onKeyboardShow);
    this.didHide = Keyboard.addListener('keyboardDidHide', this.onKeyboardHide);

    // unlockAsync().catch(() => {});
  }

  componentWillUnmount() {
    this.change?.remove();

    this.willShow?.remove();
    this.willHide?.remove();
    this.didShow?.remove();
    this.didHide?.remove();

    // lockAsync(OrientationLock.PORTRAIT).catch(() => {});
  }

  onDimensionsChanged = ({ window }: { window: ScaledSize }) => {
    this.width.setValue(window.width);
    this.height.setValue(
      window.height +
        Platform.select({ android: StatusBar.currentHeight || 20, default: 0 })
    );
  };

  onKeyboardShow = ({ endCoordinates, ...p }: KeyboardEvent) => {
    const reportedHeight = endCoordinates.height;
    const calculatedDifference =
      Dimensions.get('window').height - this.props.withoutKeyboardHeight;

    this.keyboardHeight.setValue(
      Math.floor(reportedHeight + calculatedDifference)
    );
  };

  onKeyboardHide = ({ endCoordinates }: KeyboardEvent) => {
    this.keyboardHeight.setValue(0);
  };

  componentDidUpdate(prevProps: VideoModalProps) {
    if (prevProps !== this.props) {
      this.slideUp();
    }
  }

  slideUp = () => {
    this.slideUpState.setValue(1);
    this.active = true;
    // unlockAsync().catch(() => {});
  };

  slideDown = () => {
    this.slideUpState.setValue(0);
    Keyboard.dismiss();

    this.active = false;
    // lockAsync(OrientationLock.PORTRAIT).catch(() => {});
  };

  render() {
    const translateY = interpolateNode(this.animation, {
      inputRange: [0, 1],
      outputRange: [this.upperBound, 0],
    }); //add(y, offsetY2);

    const tY = block([
      interpolateNode(translateY, {
        inputRange: [0, this.midBound],
        outputRange: [0, this.midBound],
        extrapolate: Extrapolate.CLAMP,
      }),
    ]);

    const opacity = interpolateNode(translateY, {
      inputRange: [0, sub(this.midBound, 100)],
      outputRange: [1, 0],
      extrapolate: Extrapolate.CLAMP,
    });

    const statusBarHeight = interpolateNode(translateY, {
      inputRange: [0, sub(this.midBound, 100)],
      outputRange: [
        StatusBar.currentHeight || Platform.select({ web: 0, default: 20 }),
        0,
      ],
      extrapolate: Extrapolate.CLAMP,
    });

    const videoContainerWidth = interpolateNode(translateY, {
      inputRange: [0, this.midBound],
      outputRange: [this.width, sub(min(720, this.width), 16)],
      extrapolate: Extrapolate.CLAMP,
    });

    const videoWithMax = videoContainerWidth; // min(720, videoContainerWidth);

    const videoWidth = interpolateNode(translateY, {
      inputRange: [0, this.midBound, this.upperBound],
      outputRange: [
        videoWithMax,
        sub(videoWithMax, 16),
        divide(videoWithMax, 3),
      ],
      extrapolate: Extrapolate.CLAMP,
    });

    const videoHeight = interpolateNode(translateY, {
      inputRange: [0, this.midBound, this.upperBound],
      outputRange: [
        divide(videoWidth, 1.78),
        multiply(MIN_HEIGHT, 1.3),
        MIN_HEIGHT,
      ],
      extrapolate: Extrapolate.CLAMP,
    });

    const realVideoHeight = min(
      videoHeight,
      divide(min(720, videoWidth), 1.78)
    );

    const containerHeight = interpolateNode(translateY, {
      inputRange: [0, this.midBound],
      outputRange: [sub(sub(this.height, realVideoHeight), statusBarHeight), 0],
      extrapolate: Extrapolate.CLAMP,
    });

    const playerControlOpacity = interpolateNode(translateY, {
      inputRange: [this.midBound, this.upperBound],
      outputRange: [0, 1],
      extrapolate: Extrapolate.CLAMP,
    });

    return (
      <ThemeProvider theme={{ ...DarkTheme, mode: 'exact' }}>
        <NavigationListener onBack={this.slideDown} />
        <VideoResolver video={this.props.video}>
          {({ video: resolvedVideo, error, chat }) => {
            const locale = CURRENT_LOCALE.current || i18n.defaultLocale;
            const localizedName = localize(
              resolvedVideo.nameLocalized?.full,
              resolvedVideo.name?.full,
              locale
            );
            const localizedPage = localize(
              resolvedVideo.page?.nameLocalized?.full,
              resolvedVideo.page?.name?.full,
              locale
            );

            return (
              <Animated.View
                style={[
                  {
                    alignItems: 'center',
                    transform: [{ translateY: tY }],
                    maxWidth: '100%',
                    maxHeight: Platform.select({
                      web: '100vh',
                      default: '100%',
                    }),
                    height: add(
                      containerHeight,
                      realVideoHeight,
                      statusBarHeight
                    ) /*,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,*/,
                  },
                ]}
              >
                <Card
                  elevation={5}
                  style={{ justifyContent: 'center', height: '100%' }}
                  contentStyle={{ flexGrow: 1 }}
                >
                  <Animated.View
                    style={{
                      backgroundColor: 'black',
                      height: statusBarHeight,
                      maxHeight: statusBarHeight,
                    }}
                  />
                  <Animated.View
                    style={{
                      width: videoContainerWidth,
                      height: realVideoHeight,
                    }}
                  >
                    <Animated.View
                      style={{
                        opacity: playerControlOpacity,
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: videoWidth,
                      }}
                    >
                      <PlayerControls
                        title={localizedName || localizedPage || 'Loading...'}
                        onPress={this.slideUp}
                      />
                    </Animated.View>
                    <Animated.View
                      style={{
                        width: videoWidth,
                        height: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflow: 'hidden',
                      }}
                    >
                      <AnimatedVideo
                        source={prepare(
                          resolvedVideo.url || '',
                          resolvedVideo.kind || ('other' as any),
                          DarkTheme.colors.primary,
                          Platform.select({
                            web: false,
                            android: false,
                            ios: true,
                            default: false,
                          }),
                          true
                        )}
                        kind={resolvedVideo.kind || 'url'}
                        style={{
                          width: min(720, videoWidth),
                          height: '100%',
                        }}
                        shouldPlay
                      />
                    </Animated.View>
                  </Animated.View>
                  <Animated.View
                    style={{
                      width: videoContainerWidth,
                      flex: 1,
                      position: 'relative',
                      overflow: 'hidden',
                    }}
                  >
                    <Animated.View
                      style={{
                        opacity,
                        flex: 1,
                        width: '100%',
                        position: 'relative',
                      }}
                    >
                      <VideoContent
                        video={resolvedVideo}
                        chat={chat}
                        onDismiss={this.slideDown}
                        keyboardAvoidingHeight={this.keyboardHeight}
                      />
                    </Animated.View>
                  </Animated.View>
                </Card>
              </Animated.View>
            );
          }}
        </VideoResolver>
      </ThemeProvider>
    );
  }
}

export const VideoModal__ = withSafeAreaInsets(
  VideoModal_
) as unknown as React.ComponentType<VideoModalProps>;

export const VideoModal = React.memo(VideoModal__);

function VideoResolver({
  video,
  children,
}: {
  video: PlayerVideo;
  children: (next: {
    error: Error | null;
    video: PlayerVideo;
    chat: VideoStreamBlockOptions['value']['chat'] | undefined;
  }) => JSX.Element;
}) {
  const endpoint = useEndpoint();
  const authorization = useSafeAuthorization();

  const getInfoById = useCallback(
    (pageId: string) =>
      fetchApplicationPage(
        pageId,
        endpoint,
        authorization || '',
        undefined,
        SHOULD_DEBUG_FETCH
      ),
    [endpoint, authorization]
  );

  const { page: provided, error } = usePage(video.pageId, getInfoById);

  const page = provided || video.page;

  const actualVideoBlock = useMemo(
    (): VideoStreamBlockOptions | undefined =>
      page?.content?.find(
        (block) => block.kind === KIND_VIDEO_STREAM
      ) as unknown as VideoStreamBlockOptions,
    [page?.content]
  );

  const { video: actualVideo, chat } = useMemo(
    () => actualVideoBlock?.value || { video: undefined, chat: undefined },
    [actualVideoBlock]
  );

  const fetcher = useCallback(
    (videoId: string, { signal }: { signal?: AbortSignal }) => {
      if (!endpoint || !authorization) {
        throw new NotReady();
      }

      return fetchApplicationVideo(
        videoId,
        endpoint,
        authorization,
        signal,
        SHOULD_DEBUG_FETCH
      );
    },
    [endpoint, authorization]
  );

  const { error: videoError, data: videoData } = useQuery<
    TactileVideo,
    FetchMediaError
  >(
    [actualVideo?.src, 'video'],
    ({ signal }) => fetcher(actualVideo!.src, { signal }),
    {
      enabled: !!actualVideo?.src,
    }
  );

  const filledVideo: PlayerVideo = {
    page,
    ...video,
    ...videoData,
  };

  return children({
    video: filledVideo,
    chat,
    error: error || videoError,
  });
}

function NavigationListener({ onBack }: { onBack: () => void }) {
  const navigation = navigationRef.current;

  React.useEffect(() => {
    if (!navigation) {
      return;
    }

    return navigation.addListener('state', () => {
      onBack();
    });
  }, [navigation]);

  return null;
}
