import { TactileChatInfo } from '@introcloud/api-client';
import {
  TactileImageSize,
  useBlockImageUrl,
} from '@introcloud/blocks-interface';

export function useChatImage(
  info: TactileChatInfo | undefined,
  size: TactileImageSize = 'icon_32'
) {
  return useBlockImageUrl(info?.image.profile || '', size).data ?? null;
}
