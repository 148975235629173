import { TactileGameMap, TactileGameMapPlace } from '@introcloud/api-client';
import { useFocusEffect } from '@react-navigation/native';
import React, { useCallback, useMemo } from 'react';
import { View } from 'react-native';
import { useUser } from '../hooks/useUser';
import { Background } from './Background';
import {
  ProvideGameMapInteraction,
  useGameMapInteraction,
} from './GameMapInteraction';
import { GameMapOverlay } from './GameMapOverlay';
import { MapPlaces } from './MapPlaces';
import { Players } from './Players';
import { ViewPort, useViewport } from './ViewPort';

const LAST_KNOWN_LOCATION: Record<string, { x: number; y: number }> = {};

export function GameMap({ data }: { data: TactileGameMap }) {
  const user = useUser();

  const places = useMemo(
    () =>
      data.mapPlaceRef.map(
        (placeRef) =>
          placeRef as unknown as {
            mapPlace: TactileGameMapPlace;
            mapPlaceId: string;
          }
      ),
    [data.mapPlaceRef]
  );

  const blockedTiles = useMemo(() => {
    return places.reduce((blocked, { mapPlace: { kind, shape } }) => {
      if (kind === 'blocked') {
        const left = shape.x;
        const right = left + shape.width;
        const top = shape.y;
        const bottom = top + shape.height;

        for (let x = left; x < right; x++) {
          for (let y = top; y < bottom; y++) {
            blocked[data.shape.width * y + x] = true;
          }
        }
      }

      return blocked;
    }, {} as Record<number, boolean>);
  }, [data.shape.width, places]);

  const initialState = useMemo(
    () => {
      if (LAST_KNOWN_LOCATION[data._id]) {
        const last = LAST_KNOWN_LOCATION[data._id];
        if (
          last.x <= 0 ||
          last.x >= data.shape.width ||
          last.y <= 0 ||
          last.y >= data.shape.height
        ) {
          if (!data.guest || !data.guest.start) {
            return null;
          }

          return {
            ...data.guest.start,
            room: null,
          };
        }

        return {
          ...LAST_KNOWN_LOCATION[data._id],
          room: null,
        };
      }

      if (!data.guest || !data.guest.start) {
        return null;
      }

      return {
        ...data.guest.start,
        room: null,
      };
    },
    [] /* purposefully empty */
  );

  const memoryLocation = useMemo(() => {
    if (
      LAST_KNOWN_LOCATION[data._id] &&
      LAST_KNOWN_LOCATION[data._id].x > 0 &&
      LAST_KNOWN_LOCATION[data._id].y > 0
    ) {
      return LAST_KNOWN_LOCATION[data._id];
    }

    LAST_KNOWN_LOCATION[data._id] = {
      x: initialState?.x || 0,
      y: initialState?.y || 0,
    };
    return LAST_KNOWN_LOCATION[data._id];
  }, [data._id, initialState]);

  if (!user) {
    return null;
  }

  return (
    <ProvideGameMapInteraction
      id={data._id}
      initialState={initialState}
      blockedTiles={blockedTiles}
      max={data.guest.max || undefined}
      memoryLocation={memoryLocation}
    >
      <ViewPort
        shape={data.shape}
        initialCenter={initialState || data.guest.start}
        gameMap={data}
        MapOverlay={GameMapOverlay}
      >
        <Background image={data.image.background} />
        <MapPlaces items={places} />
        <StartPoint
          x={initialState?.x || data.guest.start.x}
          y={initialState?.y || data.guest.start.y}
        />
        <Players />
        <RoomTracker />
      </ViewPort>
    </ProvideGameMapInteraction>
  );
}

function StartPoint({ x, y }: { x: number; y: number }) {
  const { displayFromCoords, displayFromSize } = useViewport();

  return (
    <View
      nativeID="start-point"
      style={{
        opacity: __DEV__ ? 1 : 0,
        position: 'absolute',
        ...displayFromCoords(x, y),
        ...displayFromSize(1, 1),
        backgroundColor: __DEV__ ? 'rgba(255, 127, 127, .5)' : 'transparent',
      }}
    />
  );
}

function RoomTracker() {
  const { broadcastRoom } = useGameMapInteraction();

  useFocusEffect(
    useCallback(() => {
      // If the user is at least 2 seconds on the screen, mark them as not in
      // a room. This is necessary because the chat resolver will very quickly
      // trigger the screen.

      const timeout = setTimeout(() => {
        broadcastRoom(null);
      }, 2000);

      return () => {
        clearTimeout(timeout);
      };
    }, [broadcastRoom])
  );

  return null;
}
