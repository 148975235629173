import { fetchApplication } from '@introcloud/api-client';
import {
  getStateFromPath,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import LinkingContext from '@react-navigation/native/lib/module/LinkingContext';
import { StoredMemoryValue } from 'expo-use-memory-value';
import React, { useCallback, useContext, useEffect } from 'react';
import { View } from 'react-native';
import { ActivityIndicator, MD2Theme, useTheme } from 'react-native-paper';
import { useAuthentication } from '../hooks/useAuthentication';
import { COMPANY } from '../hooks/useCompany';
import { SHOULD_DEBUG_FETCH, openLocalDeeplink } from '../utils';
import { permitKey, suspend } from './Authentication';
import { linkTo, navigationRef } from './RootNavigation';
import { RouteNavigation, RouteProp } from './Routes';

const WAITER = new StoredMemoryValue('storage.awaiter');

export function ResolveAccount() {
  if (!navigationRef.current?.isReady()) {
    return <Loading />;
  }

  return <ResolveAccountWithNavigation />;
}

function ResolveAccountWithNavigation() {
  const {
    params: { companyId, part_1, part_2, part_3, part_4, part_5, part_6 },
  } = useRoute<RouteProp<'ResolveAccount'>>();

  const { options } = useContext(LinkingContext);
  const { reset, popToTop } =
    useNavigation<RouteNavigation<'ResolveAccount'>>();

  const path = [part_1, part_2, part_3, part_4, part_5, part_6]
    .filter(Boolean)
    .join('/');

  const { available, authentication, login, logout, resolve } =
    useAuthentication();
  const companyKey = permitKey(companyId);
  const currentKey = permitKey(authentication?.domainFull || '');

  const goto = useCallback(() => {
    const realPath = path[0] === '/' ? path.slice(1) : path;
    const state = getStateFromPath(
      '/' + realPath,
      options ? options['config'] : undefined
    ) as any;
    if (state) {
      console.log('[resolve] attempting to link to', realPath);

      if (!linkTo(realPath, options ? options['config'] : undefined)) {
        openLocalDeeplink(realPath);
      }
    } else {
      console.log('[resolve] no state, popping to top', realPath);
      popToTop();
    }
  }, [reset, path, popToTop, options]);

  useEffect(() => {
    async function call() {
      if (available.includes(companyKey)) {
        if (companyKey !== currentKey) {
          console.log('[resolve] current company is not expected company', {
            companyKey,
            currentKey,
          });

          // Logout when it can't resolve
          const next = await resolve(companyId);
          if (!next) {
            WAITER.emit(Math.random().toString(36), true, false)
              .catch(() => {})
              .then(() => logout());
            return;
          }

          console.log('[resolve] fetch then switch');

          // Fetch then switch
          fetchApplication(
            next.domainFull + '/api',
            undefined,
            SHOULD_DEBUG_FETCH
          )
            .then((company) => COMPANY.emit(company))
            .then(() => WAITER.emit(Math.random().toString(36), true, false))
            .then(() => login(next))
            .then(() => WAITER.emit(Math.random().toString(36), true, false))
            .then(
              () =>
                new Promise((resolve, reject) =>
                  setTimeout(() => {
                    goto();
                    resolve(undefined);
                  }, 1000)
                )
            )
            .catch(() => logout());
        } else {
          // Already fine
          WAITER.emit(Math.random().toString(36), true, false)
            .catch(() => {})
            .then(() => goto());
        }
      } else {
        console.log('[resolve] suspending');

        // No session available, go to login screen
        COMPANY.emit(null)
          .then(() => WAITER.emit(Math.random().toString(36), true, false))
          .catch(() => {})
          .then(() => suspend(companyId))
          .then(() => WAITER.emit(Math.random().toString(36), true, false))
          .catch(() => {})
          .then(() => goto());
      }
    }
    call();
  }, [companyId, authentication, goto]);

  return <Loading />;
}

function Loading() {
  const {
    colors: { primary, background, accent },
  } = useTheme<MD2Theme>();

  const color = primary === background ? accent : primary;

  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: background,
      }}
    >
      <ActivityIndicator size="large" color={color} />
    </View>
  );
}

//http://localhost:19006/~/elcid.tactile.events/events/60a5213a29c1df6a4c99d1f1
