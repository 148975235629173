import { TactileLocation } from '@introcloud/api-client';
import { useBlockDataLocation } from '@introcloud/blocks-interface';
import { PageData } from '@introcloud/page';
import { TabParamList } from '@introcloud/tabs';
import { useRoute } from '@react-navigation/native';
import React, { useEffect, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { BlockProvision } from '../core/BlockProvision';
import { LOCATION_CACHE } from '../core/Cache';
import { RouteProp } from '../core/Routes';
import { useCompanyTab, useCompanyTabs } from '../hooks/useCompanyTabs';
import { useGroups } from '../hooks/useGroup';
import { useUser } from '../hooks/useUser';
import { LoadingPage, Page, PageWithContext } from '../page/Page';

export function LocationScreen() {
  const route = useRoute<RouteProp<'Location'>>();
  const location = route.params?.id;

  return (
    <BlockProvision screen="LocationScreen">
      <LocationPage id={location} />
    </BlockProvision>
  );
}

function LocationPage({ id }: { id: string }) {
  const { data: location, loading, error } = useBlockDataLocation(id);
  const { values } = useCompanyTabs();

  const { tab: locationsTab } = useCompanyTab('locations', '');
  const { tab: homeTab } = useCompanyTab('home', '');

  const fallBack = useMemo(
    () => ({
      screen: 'Tabs' as const,
      params: {
        screen: locationsTab
          ? 'Locations'
          : homeTab
          ? 'Home'
          : ((values[0].tab[0].toLocaleUpperCase() +
              values[0].tab.slice(1)) as keyof TabParamList),
      },
    }),
    [values]
  );

  const actualLocation = location || LOCATION_CACHE.current[id];

  useEffect(() => {
    if (location) {
      LOCATION_CACHE.current[id] = location!;
    }
  }, [location, id]);

  if (!actualLocation) {
    // TODO loading/error
    return <LoadingPage fallBack={fallBack} />;
  }

  if (
    actualLocation &&
    actualLocation.pageRef &&
    actualLocation.pageRef.pageId
  ) {
    return <Page page={actualLocation.pageRef.pageId} fallBack={fallBack} />;
  }

  // TODO default page
  return <DefaultLocationPage location={actualLocation} fallBack={fallBack} />;
}

function DefaultLocationPage({
  location,
  fallBack,
}: {
  location: TactileLocation;
  fallBack: any;
}) {
  const { data: currentUser } = useUser();
  const { data: currentGroups } = useGroups();

  const page = useMemo((): PageData => {
    return {
      page: {
        _id: location._id,
        _v: -1,
        module: { application: { colors: { primary: '', accent: '' } } },
        name: {
          ...location.name,
          description: location.name.description || '',
        },
        nameLocalized: location.nameLocalized,
        subTitle: '',
        locationRef: [{ locationId: location._id }],
        eventRef: [],
        content: [
          {
            _id: uuid(),
            kind: 'auto',
            value: {
              auto: 'image',
            },
            options: {
              mode: 'cover',
              ratio: [16, 9],
            },
          },
          {
            _id: uuid(),
            kind: 'auto',
            value: {
              auto: 'title',
            },
            options: {},
          },
          {
            _id: uuid(),
            kind: 'auto',
            value: {
              auto: 'description',
            },
            options: {},
          },
          {
            _id: uuid(),
            kind: 'spacer',
            value: {},
            options: {
              space: 1,
            },
          },
          {
            _id: uuid(),
            kind: 'auto',
            value: {
              auto: 'directions',
              label: 'Directions',
              labelLocalized: {
                nl: 'Routebeschrijving',
              },
              icon: 'directions',
            },
            options: {},
          },
          {
            _id: uuid(),
            kind: 'spacer',
            value: {},
            options: {
              space: 1,
            },
          },
          {
            _id: uuid(),
            kind: 'auto',
            value: {
              auto: 'upcoming-events',
            },
            options: {},
          },
        ],
      },
      pageLocation: location,
      pageEvent: null,
      currentUser: currentUser || null,
      currentGroups: currentGroups || null,
    };
  }, [location._id, currentUser, currentGroups]);
  return (
    <BlockProvision screen="DefaultLocationPage (LocationScreen)" page={page}>
      <PageWithContext fallBack={fallBack} />
    </BlockProvision>
  );
}
