import {
  PaymentMethodsState,
  fetchPaymentMethods,
} from '@introcloud/api-client';
import { FetchMediaError } from 'fetch-media';
import { useQuery } from 'react-query';
import { useEndpoint, useSafeAuthorization } from '../hooks/useAuthentication';

export function usePaymentMethods({ enabled }: { enabled: boolean }) {
  const endpoint = useEndpoint();
  const authorization = useSafeAuthorization();

  return useQuery<PaymentMethodsState, FetchMediaError>(
    [endpoint, 'app', 'payment', 'methods'],
    () => fetchPaymentMethods(endpoint, authorization!),
    {
      notifyOnChangeProps: ['data'],
      enabled: Boolean(endpoint && authorization && enabled),
    }
  );
}
