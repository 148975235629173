import { i18n, PrimaryButton, TextButton, useLocale } from '@introcloud/blocks';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { fetchMedia } from 'fetch-media';
import { Fragment, useReducer, useState } from 'react';
import { View } from 'react-native';
import {
  Dialog,
  List,
  Paragraph,
  Portal,
  Surface,
  useTheme,
} from 'react-native-paper';
import { useMutation, useQueryClient } from 'react-query';
import { RouteParamList } from '../core/Routes';
import { ThemedSnackbar } from '../core/ThemedSnackbar';
import {
  useEndpoint,
  useLogout,
  useSafeAuthorization,
} from '../hooks/useAuthentication';
import { useTab } from '../hooks/useTab';
import { openExternalUrl } from '../open';
import { useUser } from '../hooks/useUser';

export function ProfileAccount() {
  useLocale();

  const enabled = useIsFocused();
  const { roundness } = useTheme();
  const { data: user } = useUser({ enabled });

  return (
    <View style={{ width: '100%', position: 'relative', paddingVertical: 12 }}>
      <List.Subheader>{i18n.t('app.profile.account.title')}</List.Subheader>
      <Surface
        style={{
          width: '100%',
          borderRadius: roundness,
          overflow: 'hidden',
          marginHorizontal: 'auto',
          elevation: 1,
        }}
      >
        <LogoutListItem />
        {user?.bracelet.state.id ? <UnlinkListItem /> : null}
        <DeleteAccountListItem />
      </Surface>
    </View>
  );
}

function PaymentListItem() {
  useLocale();

  const { navigate } = useNavigation<StackNavigationProp<RouteParamList>>();
  const tab = useTab('payment');
  const icon =
    ((!tab?.icon.family || tab?.icon.family === 'MaterialCommunityIcons') &&
      (tab?.icon.name as 'account-cash-outline')) ||
    'account-cash-outline';

  const title = i18n.t('app.actions.goto_balance');
  const description = i18n.t('app.profile.account.payment.description');

  return (
    <List.Item
      title={title}
      description={description}
      onPress={() => navigate('Payment')}
      left={(props) => <List.Icon color={props.color} icon={icon} />}
    />
  );
}

function LogoutListItem() {
  useLocale();

  const logout = useLogout();

  const title = i18n.t('app.actions.logout');
  const description = i18n.t('app.profile.account.logout.description');

  return (
    <List.Item
      title={title}
      description={description}
      onPress={logout}
      left={(props) => <List.Icon color={props.color} icon="logout" />}
    />
  );
}

function UnlinkListItem() {
  useLocale();

  const [showingConfirmation, toggleShow] = useReducer((prev) => !prev, false);

  const title = i18n.t('app.profile.account.unlink.title');
  const description = i18n.t('app.profile.account.unlink.description');
  const endpoint = useEndpoint();
  const authorization = useSafeAuthorization();
  const queryClient = useQueryClient();

  const { mutateAsync: unlink, isLoading: unlinking } = useMutation(
    ['unlink'],
    async () => {
      await queryClient.cancelQueries([
        endpoint,
        'application',
        'user',
        authorization,
      ]);

      return await fetchMedia(`${endpoint}/user/nfc/unlink`, {
        method: 'POST',
        headers: { accept: 'application/json', authorization: authorization! },
      });
    },
    {
      onSettled: () => {
        return queryClient.invalidateQueries([
          endpoint,
          'application',
          'user',
          authorization,
        ]);
      },
    }
  );

  const [active, setIsActive] = useState(false);

  // disabled={showingConfirmation}
  return (
    <Fragment>
      <List.Item
        title={title}
        description={description}
        onPress={() => toggleShow()}
        disabled={unlinking || showingConfirmation}
        left={(props) => (
          <List.Icon color={props.color} icon="link-variant-off" />
        )}
        style={{ opacity: unlinking ? 0.5 : 1 }}
      />

      <Portal>
        <Dialog
          visible={showingConfirmation}
          onDismiss={toggleShow}
          style={{ alignSelf: 'center', maxWidth: 450, width: '100%' }}
        >
          <Dialog.Title>
            {i18n.t('app.profile.account.unlink.title')}
          </Dialog.Title>
          <Dialog.Content>
            <Paragraph>
              {i18n.t('app.profile.account.unlink.description')}
            </Paragraph>
          </Dialog.Content>
          <Dialog.Actions
            style={{ paddingHorizontal: 16, paddingBottom: 12, paddingTop: 12 }}
          >
            <TextButton
              icon="link-off"
              onPress={() => {
                unlink()
                  .then(() => setIsActive(true))
                  .catch(() => {})
                  .then(() => toggleShow());
              }}
              style={{ marginRight: 'auto' }}
            >
              {i18n.t('app.actions.unlink')}
            </TextButton>
            <PrimaryButton icon="close" onPress={toggleShow}>
              {i18n.t('app.actions.negative_action')}
            </PrimaryButton>
          </Dialog.Actions>
        </Dialog>
        <ThemedSnackbar
          active={active}
          onDismiss={() => setIsActive(false)}
          action={{ label: 'OK', onPress: () => setIsActive(false) }}
        >
          Wristband disconnected
        </ThemedSnackbar>
      </Portal>
    </Fragment>
  );
}

function DeleteAccountListItem() {
  useLocale();

  const [showingConfirmation, toggleShow] = useReducer((prev) => !prev, false);
  const endpoint = useEndpoint();
  const {
    colors: { primary },
  } = useTheme();

  const title = i18n.t('app.actions.delete_account');
  const description = i18n.t('app.profile.account.delete.description');

  // /user/profile
  return (
    <Fragment>
      <List.Item
        title={title}
        description={description}
        onPress={toggleShow}
        disabled={showingConfirmation}
        left={(props) => (
          <List.Icon color={props.color} icon="trash-can-outline" />
        )}
      />

      <Portal>
        <Dialog
          visible={showingConfirmation}
          onDismiss={toggleShow}
          style={{ alignSelf: 'center', maxWidth: 450, width: '100%' }}
        >
          <Dialog.Title>{i18n.t('app.profile.delete.title')}</Dialog.Title>
          <Dialog.Content>
            <Paragraph>{i18n.t('app.profile.delete.description')}</Paragraph>
          </Dialog.Content>
          <Dialog.Actions
            style={{ paddingHorizontal: 16, paddingBottom: 12, paddingTop: 12 }}
          >
            <TextButton
              icon="delete-forever"
              onPress={() => {
                toggleShow();
                openExternalUrl(
                  `${endpoint.replace('/api', '/user/profile')}`,
                  primary,
                  true
                );
              }}
              style={{ marginRight: 'auto' }}
            >
              {i18n.t('app.actions.delete_account_portal')}
            </TextButton>
            <PrimaryButton icon="close" onPress={toggleShow}>
              {i18n.t('app.actions.delete_account_cancel')}
            </PrimaryButton>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </Fragment>
  );
}
