import { transferMoney } from '@introcloud/api-client';
import { FetchMediaError } from 'fetch-media';
import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';
import { useEndpoint, useSafeAuthorization } from '../hooks/useAuthentication';

type Params = {
  amount: number;
  recipientId: string;
};

export function useTransfer(
  options: UseMutationOptions<unknown, FetchMediaError, Params>
) {
  const queryClient = useQueryClient();
  const endpoint = useEndpoint();
  const authorization = useSafeAuthorization();

  return useMutation<unknown, FetchMediaError, Params>(
    ['app', 'payment', 'transfer'],
    async ({ amount, recipientId }) => {
      if (!authorization) {
        throw new Error('should have been authorized');
      }

      await queryClient.cancelQueries([authorization, 'app', 'balance']);

      const result = await transferMoney(endpoint, authorization, {
        recipientId,
        cents: amount,
      });

      return result;
    },
    {
      onSettled: async () => {
        return queryClient.invalidateQueries([authorization, 'app', 'balance']);
      },
      ...options,
    }
  );
}
