import { PrimaryButton } from '@introcloud/blocks';
import { useWindowWidth } from '@introcloud/blocks-interface';
import { fetchMedia } from 'fetch-media';
import { AllHtmlEntities } from 'html-entities';
import React from 'react';
import { Image } from 'react-native';
import { Card, Paragraph, Title } from 'react-native-paper';
import { useQuery } from 'react-query';
import { GelegraafItem } from './useGelegraaf';
import { useSafe } from './useSafeHtmlContent';

const entities = new AllHtmlEntities();

export interface ImportantHeadlineProps {
  article: GelegraafItem;
  onGotoArticle(): void;
}

export function ImportantHeadline({
  article,
  onGotoArticle,
}: ImportantHeadlineProps) {
  const { title, featured_media, content } = article || {};
  const safeDescription = useSafe((content || { rendered: '' }).rendered);
  const width = Math.min(720, useWindowWidth());

  if (!article) {
    return null;
  }

  return (
    <Card onPress={onGotoArticle}>
      {featured_media && (
        <ImportantHeadlineImage source={featured_media} width={width} />
      )}
      <Card.Content>
        <Title style={{ paddingTop: 16 }}>
          {entities.decode((title || { rendered: '' }).rendered)}
        </Title>
        <Paragraph>
          {safeDescription.split(' ').slice(0, 30).join(' ') + '...'}
        </Paragraph>
      </Card.Content>
      <Card.Actions>
        <PrimaryButton onPress={onGotoArticle}>Read more...</PrimaryButton>
      </Card.Actions>
    </Card>
  );
}

function ImportantHeadlineImage({
  source,
  width,
}: {
  source: GelegraafItem['featured_media'];
  width: number;
}) {
  const height = (width / 16) * 10;
  const propData =
    typeof source === 'object' && 'media_details' in source
      ? source
      : undefined;

  const { data: remoteMedia } = useQuery(
    [source?.href],
    async () => {
      const result = await fetchMedia(source!.href!, {
        headers: { accept: 'application/json' },
        disableText: true,
        disableFormData: true,
        disableFormUrlEncoded: true,
      });

      if (result && 'media_details' in result) {
        return result;
      }

      return null;
    },
    {
      enabled: Boolean(source?.href),
      staleTime: 1000 * 60 * 60,
    }
  );

  const featuredMedia = remoteMedia || propData;

  if (!featuredMedia) {
    return null;
  }

  const { media_details } =
    (featuredMedia as GelegraafItem['featured_media']) || {};
  if (!media_details) {
    return null;
  }

  const { sizes } = media_details || {};

  if (!sizes) {
    return null;
  }

  const base = sizes['blog-full'] || sizes['medium_large'];
  const image = base.source_url;

  if (!image) {
    return null;
  }

  return (
    <Image
      source={{ uri: image, width: 1140, height: 500 }}
      style={{ width, height }}
      resizeMode="cover"
    />
  );
}
