import { i18n } from '@introcloud/blocks';
import { useIsFocused } from '@react-navigation/native';
import { Image, View } from 'react-native';
import { List, Surface, useTheme } from 'react-native-paper';
import { useIsColorDark } from 'use-color-luminance';
import { useUser } from '../hooks/useUser';

export function ProfileTicket() {
  const enabled = useIsFocused();
  const { data: user } = useUser({ enabled });
  const {
    colors: { surface },
    roundness,
  } = useTheme();

  const dark = useIsColorDark(surface);

  if (!user?.security?.qr?.value) {
    return null;
  }

  return (
    <View style={{ width: '100%', position: 'relative', paddingVertical: 12 }}>
      <List.Subheader>{i18n.t('app.profile.ticket.title')}</List.Subheader>
      <Surface
        style={{
          width: '100%',
          height: 250 + 16,
          paddingBottom: 16,
          borderRadius: roundness,
          overflow: 'hidden',
          marginHorizontal: 'auto',
          elevation: 1,
          backgroundColor: dark ? '#FFF' : surface,
          alignItems: 'center',
        }}
      >
        <Image
          source={{
            uri: `https://api.tactile.events/api/image/qr/${user?.security?.qr?.value}/png`,
            width: 150,
            height: 150,
          }}
          resizeMode="contain"
          style={{ width: 250, height: 250 }}
        />
      </Surface>
    </View>
  );
}
