import {
  createNavigationContainerRef,
  getActionFromState,
  getStateFromPath,
  LinkingOptions,
  NavigationContainerRef,
} from '@react-navigation/native';
import { createRef, MutableRefObject } from 'react';
import { RouteParamList } from './Routes';

export const isReadyRef: MutableRefObject<boolean | null> = createRef();
export const navigationRef = createNavigationContainerRef<RouteParamList>();

export const navigationConfigRef: MutableRefObject<
  LinkingOptions<RouteParamList>['config'] | null
> = createRef();

type Args<RouteName extends keyof RouteParamList> = Parameters<
  NavigationContainerRef<RouteParamList>['navigate']
>;

export function navigate<RouteName extends keyof RouteParamList>(
  ...args: Args<RouteName>
) {
  if (isReadyRef.current && navigationRef.current?.isReady()) {
    // Perform navigation if the app has mounted
    navigationRef.current.navigate(...args);
  } else {
    // You can decide what to do if the app hasn't mounted
    // You can ignore this, or add these actions to a queue you can call later
  }
}

export function dispatch(
  action: NonNullable<ReturnType<typeof getActionFromState>>
) {
  navigationRef.current?.dispatch(action);
}

export function linkTo(
  path: string,
  config: Parameters<typeof getStateFromPath>[1] = undefined
) {
  if (path[0] !== '/') {
    path = `/${path}`;
  }

  const state = getStateFromPath(
    path,
    navigationConfigRef.current || undefined
  );

  if (!state) {
    return false;
  }

  const action = getActionFromState(state);
  if (action === undefined) {
    return false;
  }

  console.log('[deeplink] internal', path);
  dispatch(action);
  return true;
}
