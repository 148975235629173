import { defineTranslations } from '@introcloud/blocks';
import React from 'react';

import { ScannerScreen } from './ScannerScreen';

defineTranslations({
  en: {
    app: {
      scanner: {
        title: 'Scanner',
      },
    },
  },

  nl: {
    app: {
      scanner: {
        title: 'Scanner',
      },
    },
  },
});

export function ScannerTab() {
  return <ScannerScreen asTab />;
}
