import { TactileNewsItem, TactileNewsItems } from '@introcloud/api-client';
import { Spacer, i18n, useLocale } from '@introcloud/blocks';
import { useBlockData } from '@introcloud/blocks-interface';
import { localize } from '@introcloud/blocks/dist/useLocale';
import { useIsMobileView } from '@introcloud/page/dist/utils/useIsMobileView';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Platform, ScrollView, TextInput, View } from 'react-native';
import { Card, List, Searchbar } from 'react-native-paper';
import { BlockProvision } from '../core/BlockProvision';
import { EmptyState } from '../core/EmptyState';
import { FilterMenu } from '../core/FilterMenu';
import { Header } from '../core/Header';
import { LastNewsItem } from '../home/LatestNewsItem';
import { useCompanyTab } from '../hooks/useCompanyTabs';
import { useForceUpdate } from '../hooks/useForceUpdate';
import { useNews } from '../hooks/useNews';
import { Highlights } from './Highlights';
import { NewsListItem } from './NewsListItem';
import { NewsSponsor } from './NewsSponsor';

const EMPTY_TEXT = {
  en: `
  We will publish articles, pictures, and other media. You will always find the
  latest article on the Home page, but you can read back every article right
  here.
  `
    .trim()
    .replace(/\n/g, ''),
  nl: `
  We zullen articlen, beeldmateriaal en andere media publiceren. Je kan altijd
  het meest recente item op de Home pagina vinden, maar ook alles terug lezen op
  deze plek.
  `
    .trim()
    .replace(/\n/g, ''),
};

export function NewsScreen({ asTab }: { asTab?: boolean }) {
  const { icon, title } = useCompanyTab('news', 'newspaper');

  const { data: news, loading } = useNews();
  const hasNews = !!(news && news.length > 0);

  return (
    <BlockProvision screen="NewsScreen">
      <View
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
          maxHeight: Platform.select({ web: '100vh', default: '100%' }),
        }}
      >
        <Header
          title={title || i18n.t('app.news.title')}
          subTitle={undefined}
          hideBack={asTab}
          style={hasNews ? {} : { elevation: 2, zIndex: 2 }}
          showTranslate
        />
        {hasNews ? <NewsItems news={news!} /> : null}
        {!loading && (
          <EmptyState
            hidden={hasNews}
            icon={icon}
            title={title || i18n.t('app.news.title')}
            texts={EMPTY_TEXT}
          />
        )}
      </View>
    </BlockProvision>
  );
}

function NewsItems({ news }: { news: readonly TactileNewsItem[] }) {
  const [searchActive, setSearchActive] = useState(false);

  const regularRef = useRef<[undefined | TactileNewsItem, TactileNewsItem[]]>([
    undefined,
    [],
  ]);
  const highlightsRef = useRef<TactileNewsItem[]>([]);
  const forceUpdate = useForceUpdate();
  const locale = useLocale();

  const onQueryChanged = useCallback(
    (query: string, activeTag: string) => {
      const parts = query
        .toLocaleLowerCase()
        .split(' ')
        .filter((item) => item.trim().length);

      const taggedNews = activeTag
        ? news.filter((item) =>
            (
              localize(item.nameLocalized?.tag, item.name.tag, locale) || []
            ).includes(activeTag)
          )
        : news;

      const queriedNews =
        parts.length > 0
          ? taggedNews.filter((item) => {
              const name = [
                localize(item.nameLocalized?.full, null, locale),
                item.name.full,
              ]
                .filter(Boolean)
                .join(' ')
                .toLocaleLowerCase();
              return parts.every((p) => name.includes(p));
            })
          : taggedNews;

      const [last, ...rest] = queriedNews.filter(
        (item) => item.kind === 'other'
      );
      regularRef.current = [last, rest];
      highlightsRef.current = queriedNews.filter(
        (item) => item.kind === 'pinned'
      );

      forceUpdate();
    },
    [news, forceUpdate]
  );

  const [last, rest] = regularRef.current;

  return (
    <ScrollView
      nativeID="scroller"
      style={{ flex: 1 }}
      contentContainerStyle={{
        maxWidth: 720,
        alignSelf: 'center',
        paddingBottom: 56,
        width: '100%',
        overflow: 'visible',
      }}
    >
      <View style={{ width: '100%' }}>
        <Spacer space={1} />
        <NewsSearch
          onQueryChanged={onQueryChanged}
          news={news}
          active={searchActive}
          onChangeActive={setSearchActive}
        />

        <Spacer space={1} />
        <MaybeLastNewsItem item={last} />
        <MaybeHighlights items={highlightsRef.current} />
        <NewsSponsor minimized={searchActive} />
        <MaybeOlderNewsItems items={rest} />
      </View>
    </ScrollView>
  );
}

function NewsSearch({
  onQueryChanged,
  onChangeActive,
  news,
  active,
}: {
  onQueryChanged(query: string, tag: string | null): void;
  onChangeActive(next: boolean | ((prev: boolean) => boolean)): void;
  active: boolean;
  news: readonly TactileNewsItem[];
}) {
  const [query, setQuery] = useState('');
  const [tag, setTag] = useState<string | null>(null);
  const locale = useLocale();

  const searchBar =
    useRef<
      Pick<
        TextInput,
        'blur' | 'focus' | 'setNativeProps' | 'isFocused' | 'clear'
      >
    >(null);

  const doToggleActive = useCallback(
    () => onChangeActive((prev) => !prev),
    [onChangeActive]
  );
  const setActive = useCallback(() => onChangeActive(true), [onChangeActive]);

  // Focus when it becomes active
  useEffect(() => {
    if (active && searchBar.current) {
      searchBar.current.focus();
    }
  }, [active, searchBar]);

  // Update query with debounce
  useEffect(() => {
    const debounceTimer = setTimeout(
      () => onQueryChanged(active ? query : '', tag),
      220
    );
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [query, tag, active]);

  // Get the list of tags
  const tags = useMemo(
    () =>
      news
        .reduce((final, item) => {
          final.push(
            ...(localize(item.nameLocalized?.tag, item.name.tag, locale) || [])
          );

          return final;
        }, [] as string[])
        .filter(Boolean)
        .filter((tag, index, self) => self.indexOf(tag) === index)
        .sort(),
    [news, locale]
  );

  const showFilter = tags.length >= 1 && !active;
  const isMobileView = useIsMobileView();

  return (
    <View
      style={[
        {
          zIndex: 3,
          position: 'relative',
          alignSelf: 'center',
          width: '100%',
          maxWidth: 720,
        },
      ]}
    >
      <Searchbar
        selectTextOnFocus
        icon={active ? 'arrow-left' : 'text-box-search'}
        onIconPress={doToggleActive}
        onFocus={setActive}
        placeholder={i18n.t('app.news.search')}
        onChangeText={setQuery}
        value={query}
        style={[
          { marginHorizontal: isMobileView ? 8 : 0 },
          {
            elevation: 1,
            zIndex: 0,
          },
        ]}
      />

      {showFilter ? (
        <View
          style={{
            position: 'absolute',
            right: 8,
            top: 6,
            backgroundColor: 'transparent',
            elevation: 4,
            maxWidth: 170,
          }}
        >
          <FilterMenu
            onSelect={setTag}
            tags={tags}
            selected={tag}
            defaultIcon="newspaper-variant-outline"
          />
        </View>
      ) : null}
    </View>
  );
}

function MaybeLastNewsItem_({ item }: { item: TactileNewsItem | undefined }) {
  if (!item) {
    return null;
  }

  return (
    <Fragment>
      <List.Subheader>{i18n.t('app.news.latest_news')}</List.Subheader>
      <LastNewsItem item={item} />
      <Spacer space={1} />
    </Fragment>
  );
}

function MaybeHighlights_({ items }: { items: TactileNewsItems }) {
  if (!items || items.length === 0) {
    return null;
  }

  return <Highlights items={items} />;
}

function MaybeOlderNewsItems_({ items }: { items: TactileNewsItems }) {
  if (!items || items.length === 0) {
    return null;
  }

  return <OlderNewsItems items={items} />;
}

function OlderNewsItems({ items }: { items: TactileNewsItems }) {
  const { getImageUrl } = useBlockData();
  const locale = useLocale();

  const value = useMemo(
    () => ({
      label: 'Older news',
      items: items.map((item) => ({
        label: localize(item.nameLocalized?.full, item.name.full, locale),
        image: getImageUrl(item.image.profile || '', 'icon_128') || '',
        description: localize(
          item.nameLocalized?.description,
          item.name.description,
          locale
        ),
        destination: {
          kind: 'info',
          value: item.pageRef.pageId || (item.pageRef as any)?.page?._id,
        } as const,
      })),
    }),
    [items]
  );

  return (
    <Fragment>
      <List.Subheader>{i18n.t('app.news.older_news')}</List.Subheader>
      <Card style={{ elevation: 1 }}>
        {value.items.map((item, index) => (
          <NewsListItem key={index} {...item} />
        ))}
      </Card>
      <Spacer space={1} />
    </Fragment>
  );
}

const MaybeLastNewsItem = React.memo(MaybeLastNewsItem_);
const MaybeHighlights = React.memo(MaybeHighlights_);
const MaybeOlderNewsItems = React.memo(MaybeOlderNewsItems_);
