import { useMemo } from 'react';
import { useEvents } from './useEvents';

export function useSwipeInterview({
  enabled = true,
}: { enabled?: boolean } = {}) {
  const { data: events } = useEvents({ enabled });

  const swipeEvent = useMemo(
    () =>
      events ? events.find((event) => event.module?.swipe?.active) : undefined,
    [events]
  );

  return swipeEvent;
}
