import { TactileChatInfo } from '@introcloud/api-client';
import { useQuery } from 'react-query';
import { useChatInfoFetcher } from '../hooks/useChatInfoFetcher';
import { useChatMentorFlag } from './useChatMentorFlag';

export type TactileChatInfoExtended = TactileChatInfo & { isMentor: boolean };

export function useChatUserInfo(
  user: undefined | null | { id: string }
): TactileChatInfoExtended | undefined {
  const isMentor = useChatMentorFlag(user?.id);
  const infoFetcher = useChatInfoFetcher('user');
  const { data: info } = useQuery(
    ['chat', 'user', user?.id, 'info'],
    ({ signal }) => infoFetcher(user!.id, { signal }),
    {
      enabled: !!user?.id,
      staleTime: 15 * 1000 * 60,
    }
  );

  if (info && info.name && typeof info.name === 'string') {
    const compatibility: TactileChatInfo = {
      name: { full: info.name as unknown as string },
      image: { profile: '' },
    };

    return { ...compatibility, isMentor };
  }

  if (!info) {
    return undefined;
  }

  return { ...info, isMentor };
}
