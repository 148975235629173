import { Platform } from 'react-native';
import {
  DOMAIN_INTROCLOUD,
  DOMAIN_TACTILE,
  DOMAIN_WHITELABEL,
  EXPO_SLUG,
} from './config';

export * from 'expo-use-memory-value';

if (Platform.OS === 'web') {
  require('expo-use-memory-value/storage.web').setLocalForageInstance(
    require('localforage').createInstance({
      name: `${
        DOMAIN_TACTILE || DOMAIN_INTROCLOUD || DOMAIN_WHITELABEL || EXPO_SLUG
      }.2023`,
    })
  );
}
