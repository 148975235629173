import { i18n } from '@introcloud/blocks';
import React from 'react';
import { View } from 'react-native';
import { Caption, Card, Divider } from 'react-native-paper';
import { StoredChatMessage } from './useChatHistory';
import { useChatMessageTime } from './useChatMessageTime';

export function useChatTimeline(
  item: StoredChatMessage,
  previousItem: StoredChatMessage | undefined
) {
  const messageTime = useChatMessageTime(item.timetoken);
  const previousMessageTime = useChatMessageTime(previousItem?.timetoken);

  const isNewDay =
    !previousMessageTime ||
    messageTime.toLocaleDateString() !==
      previousMessageTime.toLocaleDateString();
  const isLongAgo =
    isNewDay ||
    (messageTime &&
      previousMessageTime &&
      messageTime.getTime() - previousMessageTime.getTime() > 1000 * 60 * 15);
  const isSame =
    !isLongAgo && !!(previousItem && previousItem.publisher === item.publisher);

  return { isNewDay, isSame, messageTime };
}

export function DayDivider({ day }: { day: Date }) {
  const today = new Date();

  const todayAsText = today.toLocaleDateString();
  const dayAsText = day.toLocaleDateString();

  const isToday = todayAsText === dayAsText;
  const isYesterday =
    !isToday && today.getTime() - day.getTime() < 24 * 60 * 60 * 1000;

  return (
    <View
      style={{
        width: '100%',
        position: 'relative',
        marginTop: 32,
        marginBottom: 12,
      }}
    >
      <Divider style={{ height: 0.5 }} />
      <Card
        style={{
          minWidth: 100,
          height: 24,
          marginHorizontal: 'auto',
          marginTop: -12,
          marginBottom: -6,
          alignSelf: 'center',
        }}
      >
        <Caption
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            textAlignVertical: 'center',
            includeFontPadding: false,
            lineHeight: 22,
          }}
        >
          {isToday
            ? i18n.t('app.time.today')
            : isYesterday
            ? i18n.t('app.time.yesterday')
            : dayAsText}
        </Caption>
      </Card>
    </View>
  );
}
