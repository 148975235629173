import { i18n, useLocale } from '@introcloud/blocks';
import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { MD2Theme, useTheme } from 'react-native-paper';
import { useCompany } from '../hooks/useCompany';
import { EmbedScreen } from './EmbedScreen';
import { PageScreen } from './PageScreen';

const Stack = createStackNavigator<{
  Page: { id: string };
  Embed: { id: string; blockId: string };
}>();

export function PageStack() {
  const {
    colors: { primary },
  } = useTheme<MD2Theme>();
  const company = useCompany();

  useLocale();

  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        presentation: 'modal',
        // stackAnimation: 'fade',
      }}
    >
      <Stack.Screen
        name="Page"
        component={PageScreen}
        options={{
          title: `${i18n.t('app.info.title')} · ${
            company?.name.full ?? 'Tactile'
          }`,
        }}
      />
      <Stack.Screen
        name="Embed"
        component={EmbedScreen}
        options={{
          cardStyle: { backgroundColor: primary },
        }}
      />
    </Stack.Navigator>
  );
}
