import { TextButton, defineTranslations, i18n } from '@introcloud/blocks';
import { useURL } from 'expo-linking';
import { Platform } from 'react-native';
import { AppbarProps, Surface, useTheme } from 'react-native-paper';
import { useIsColorDark } from 'use-color-luminance';
import { resetToPath } from '../utils';

defineTranslations({
  en: {
    app: {
      actions: {
        deeplink: 'Open the requested page',
        deeplink_title: 'Page: {{title}}',
        open: 'Open',
      },
    },
  },
  nl: {
    app: {
      actions: {
        deeplink: 'Open de opgevraagde pagina',
        deeplink_title: 'Pagina: {{title}}',
        open: 'Open',
      },
    },
  },
});

export function AppBarDeeplink({
  color,
  style,
}: {
  color?: string;
  style: AppbarProps['style'];
}) {
  const url = useURL();
  const theme = useTheme();
  const contrast = useIsColorDark(color ?? theme.colors.primary)
    ? '#FFF'
    : '#000';

  if (Platform.OS === 'web') {
    return null;
  }

  if (!url) {
    return null;
  }

  const path = url
    ? url
        .replace('http://', '')
        .replace('https://', '')
        .split('/')
        .slice(1)
        .join('/')
    : '';

  if (!path) {
    return null;
  }

  return (
    <Surface
      style={[
        {
          maxWidth: '100%',
          width: '100%',
          backgroundColor: color,
          elevation: 2,
          minHeight: 44,
          zIndex: 2,
        },
      ]}
    >
      <TextButton
        onPress={() => resetToPath(path)}
        style={{ width: '100%', borderRadius: 0 }}
        contentStyle={{ minHeight: 44 }}
        icon={'link'}
        textColor={contrast}
      >
        {i18n.t('app.actions.deeplink')}
      </TextButton>
    </Surface>
  );
}
