import { fetchApplication } from '@introcloud/api-client';
import { i18n } from '@introcloud/blocks';
import * as React from 'react';
import { Button, Card, Dialog, Portal } from 'react-native-paper';
import { login, logout } from '../core/Authentication';
import { useCurrentDomain } from '../hooks/useAuthentication';
import { COMPANY, useRemoteCompany } from '../hooks/useCompany';
import { SHOULD_DEBUG_FETCH } from '../utils';
import { RegisterWebView } from './RegisterWebView';

export function MaybeRegister({ domain }: { domain?: string }) {
  const baseDomain = useCurrentDomain();
  const finalDomain = domain ?? baseDomain;
  const { data: company } = useRemoteCompany(finalDomain);

  if (
    !company ||
    finalDomain === 'https://api.tactile.events' ||
    finalDomain === 'https://app.tactile.events'
  ) {
    return null;
  }

  const signUpUrl = company.settings.application.signUpUrl;

  if (!signUpUrl) {
    return null;
  }

  return <Register url={signUpUrl} domainFull={finalDomain} />;
}

function Register({ url, domainFull }: { url: string; domainFull: string }) {
  const [showRegister, toggleRegister] = React.useReducer(
    (prev) => !prev,
    false
  );

  return (
    <React.Fragment>
      <Button
        mode="contained"
        buttonColor="#777"
        style={{ marginBottom: 8 }}
        onPress={() => toggleRegister()}
      >
        {i18n.t('app.login.register_action')}
      </Button>

      <Portal>
        <Dialog
          visible={showRegister}
          onDismiss={() => toggleRegister()}
          style={{
            maxWidth: 540,
            alignSelf: 'center',
            minWidth: 300,
            width: '100%',
            overflow: 'hidden',
            minHeight: '40%',
            height: '80%',
            maxHeight: 540,
          }}
        >
          <Card
            contentStyle={{ flexGrow: 1 }}
            style={{ width: '100%', height: '100%' }}
          >
            <RegisterWebView
              url={url}
              onLogin={(permit) => {
                toggleRegister();
                setTimeout(() => {
                  fetchApplication(
                    domainFull + '/api',
                    undefined,
                    SHOULD_DEBUG_FETCH
                  )
                    .then((company) => COMPANY.emit(company))
                    .then(() => login({ ...permit, domainFull }))
                    .catch(() => logout());
                }, 1);
              }}
            />
          </Card>
        </Dialog>
      </Portal>
    </React.Fragment>
  );
}
