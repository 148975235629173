import { useWindowWidth } from '@introcloud/blocks-interface';
import { LinearGradient } from 'expo-linear-gradient';
import { fetchMedia } from 'fetch-media';
import { AllHtmlEntities } from 'html-entities';
import React from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import { Card, useTheme } from 'react-native-paper';
import { useQuery } from 'react-query';
import { GelegraafItem } from './useGelegraaf';

const entities = new AllHtmlEntities();

const styles = StyleSheet.create({
  // text-shadow: 1px 2px 0 rgba(0,0,0,0.47);
  headlineWrapper: {
    position: 'absolute',
    left: 8,
    right: 8,
    bottom: 8,
  },

  headline: {
    fontSize: 16,
    lineHeight: 22,
    fontWeight: 'bold',
    color: 'white',
    textShadowColor: 'rgba(0, 0, 0, 0.47)',
    textShadowOffset: { width: 1, height: 2 },
    textShadowRadius: 2,
  },

  scrim: {
    position: 'absolute',
    left: 0,
    top: 0,
    backgroundColor: 'rgba(0, 0, 0, .25)',
    right: 0,
    bottom: 0,
    borderRadius: 4,
  },

  image: {
    width: '100%',
    height: '100%',
  },

  touch: {
    margin: 0,
    padding: 0,
    position: 'relative',
    borderRadius: 4,
  },
});

export interface FeaturedHeadlineProps {
  article: GelegraafItem;
  even: boolean;
  onGotoArticle(): void;
}

export function FeaturedHeadline({
  article,
  onGotoArticle,
}: FeaturedHeadlineProps) {
  const { title, featured_media } = article || {};

  const size = (Math.min(720, useWindowWidth()) - 16) / 2 - 16;

  if (!article) {
    return null;
  }

  return (
    <Card
      onPress={onGotoArticle}
      style={{ width: size, height: size, marginHorizontal: 8 }}
    >
      <FeaturedHeadlineImage source={featured_media} />
      <LinearGradient
        colors={['rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, .32)']}
        style={StyleSheet.absoluteFill}
      />

      <View pointerEvents="none" style={styles.headlineWrapper}>
        <Text style={styles.headline}>
          {entities.decode((title || { rendered: '' }).rendered)}
        </Text>
      </View>
    </Card>
  );
}

function FeaturedHeadlineImage({
  source,
}: {
  source: GelegraafItem['featured_media'];
}) {
  const { roundness } = useTheme();
  const propData =
    typeof source === 'object' && 'media_details' in source
      ? source
      : undefined;

  const { data: remoteMedia } = useQuery(
    [source?.href],
    async () => {
      const result = await fetchMedia(source!.href!, {
        headers: { accept: 'application/json' },
        disableText: true,
        disableFormData: true,
        disableFormUrlEncoded: true,
      });

      if (result && 'media_details' in result) {
        return result;
      }

      return null;
    },
    {
      enabled: Boolean(source?.href),
      staleTime: 1000 * 60 * 60,
    }
  );

  const featuredMedia = remoteMedia || propData;

  if (!featuredMedia) {
    return null;
  }

  const { media_details } =
    (featuredMedia as GelegraafItem['featured_media']) || {};
  if (!media_details) {
    return (
      <Image
        style={[styles.image, { borderRadius: roundness, overflow: 'hidden' }]}
        source={{
          uri: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA',
          width: 458,
          height: 458,
        }}
      />
    );
  }

  const { sizes } = media_details || {};

  if (!sizes) {
    return (
      <Image
        style={[styles.image, { borderRadius: roundness, overflow: 'hidden' }]}
        source={{
          uri: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA',
          width: 458,
          height: 458,
        }}
      />
    );
  }

  const base = sizes['speaker-thumb'] || sizes['medium'];
  const image = base.source_url;

  if (!image) {
    return (
      <Image
        style={[styles.image, { borderRadius: roundness, overflow: 'hidden' }]}
        source={{
          uri: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA',
          width: 458,
          height: 458,
        }}
      />
    );
  }

  return (
    <Image
      style={[styles.image, { borderRadius: roundness, overflow: 'hidden' }]}
      source={{ uri: image, width: 458, height: 458 }}
    />
  );
}
