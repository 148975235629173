import { TactileSponsor } from '@introcloud/api-client';
import merge from 'lodash.merge';
import { useEffect, useMemo, useState } from 'react';
import { useRandomizerRef } from './useProvideBlockData';
import { SPONSORS } from './useSponsors';

const EMPTY: TactileSponsor[] = [];
const listeners: ((v: any) => void)[] = [];

SPONSORS.subscribe((v) => listeners.forEach((listener) => listener(v)));

export function useSplash() {
  const { randomizerRef, setRandomizer, version } = useRandomizerRef();
  const [data, setData] = useState(SPONSORS.current);

  useEffect(() => {
    SPONSORS.subscribe(setData);
    setData(SPONSORS.current);
    return () => SPONSORS.unsubscribe(setData);
  }, []);

  const [splash, setSplash] = useState<
    | {
        url?: string;
        image?: { profile?: string | null };
        color?: { hex: string };
      }
    | null
    | undefined
  >(undefined);

  const splashes = useMemo(
    () =>
      data?.filter(
        (sponsor) => sponsor.kind === 'splash' && sponsor.weight !== 0
      ) || EMPTY,
    [data]
  );

  const length = splashes.reduce(
    (result, sponsor) => result + sponsor.weight,
    0
  );

  useEffect(() => {
    if (randomizerRef.current === undefined) {
      return;
    }

    if (length === 0) {
      if (data !== undefined) {
        setSplash(null);
      }
      return;
    }

    const kind = 'splash';

    const splashPrevious = randomizerRef.current
      ? randomizerRef.current[kind]
      : undefined;

    const previous =
      typeof splashPrevious === 'undefined'
        ? Math.floor(Math.random() * length)
        : splashPrevious;
    const now = (previous + 1) % length;

    setRandomizer((prev) => merge({}, prev || {}, { [kind]: now }));

    const items: TactileSponsor[] = [];
    splashes.forEach((o) => {
      items.push(...Array(o.weight).fill(o));
    });

    const item = items[now];
    if (item) {
      setSplash(item);
      return;
    }

    setSplash(null);
  }, [length, version]);

  if (data === undefined || splash === undefined) {
    return undefined;
  }

  if (data === null || data.length === 0 || splash === null) {
    return null;
  }

  if (splash === null || !splash?.image?.profile) {
    return null;
  }

  return splash;
}
