import { CURRENT_LOCALE, i18n } from '@introcloud/blocks';
import color from 'color';
import * as SplashScreen from 'expo-splash-screen';
import { useMemoryValue } from 'expo-use-memory-value';
import * as WebBrowser from 'expo-web-browser';
import { setDefaultHeaders } from 'fetch-media';
import React, { useReducer } from 'react';
import { Platform, StatusBar, StyleSheet, View } from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { ActivityIndicator, PaperProvider, Provider } from 'react-native-paper';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { USER_AGENT } from './config';
import { AuthenticatedApp } from './core/AuthenticatedApp';
import { Connectivity } from './core/Connectivity';
import { NavigationReadyProvider } from './core/NavigationReadyContext';
import { OtaUpdater } from './core/OtaUpdater';
import { QueryCacheProvider, queryClient } from './core/QueryCache';
import {
  AuthenticationProvider,
  runOnLogout,
  useSafeAuthorization,
} from './hooks/useAuthentication';
import {
  useCachedResources,
  useSplashResources,
} from './hooks/useCachedResources';
import { useCompany } from './hooks/useCompany';
import { LoginScreen } from './login';
import { SplashOrganisation } from './splash/Splasher';
import { SnackbarProvider } from './core/SnackbarProvider';

setDefaultHeaders({
  DNT: '1',
  'User-Agent': USER_AGENT,
});

i18n.onChange((next) => {
  setDefaultHeaders({
    'Accept-Language': `${next.locale}, ${next.availableLocales
      .map((value) => `${value}; q=0.5`)
      .join(', ')}`,
    DNT: '1',
    'User-Agent': USER_AGENT,
  });
});

WebBrowser.maybeCompleteAuthSession({
  skipRedirectCheck: true,
});

runOnLogout(() => {
  queryClient.clear();
});

let hiddenSplash = false;

export function Root() {
  const [isBlockingSplashPointerEvents, markBlockPointerEvents] = useReducer(
    () => true,
    false
  );
  const [isSplashReady, markReady] = useReducer(() => true, false);
  const [isSplashDone, markDone] = useReducer(() => true, false);

  const canSplashShow = useSplashResources();
  const isLoadingComplete = useCachedResources();

  useMemoryValue(CURRENT_LOCALE);

  if (!isLoadingComplete && !canSplashShow) {
    return Platform.OS === 'web' ? (
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <ActivityIndicator />
      </View>
    ) : null;
  }

  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <AuthenticationProvider>
        <QueryCacheProvider>
          <View style={{ position: 'relative', flex: 1 }}>
            {isLoadingComplete && isSplashReady ? <App /> : null}
            {!isSplashDone && canSplashShow && (
              <View
                pointerEvents={isBlockingSplashPointerEvents ? 'none' : 'auto'}
                style={StyleSheet.absoluteFillObject}
              >
                <SplashOrganisation
                  onReady={markReady}
                  onDone={markDone}
                  onAlmostDone={markBlockPointerEvents}
                  onLoaded={() => {
                    if (hiddenSplash) {
                      return;
                    }

                    SplashScreen.hideAsync().then(
                      () => (hiddenSplash = true),
                      () => (hiddenSplash = true)
                    );
                  }}
                />
              </View>
            )}
          </View>
        </QueryCacheProvider>
      </AuthenticationProvider>
    </GestureHandlerRootView>
  );
}

function App() {
  return (
    <SafeAreaProvider>
      <SnackbarProvider>
        <NavigationReadyProvider>
          <PreloadedApp />
        </NavigationReadyProvider>
      </SnackbarProvider>
    </SafeAreaProvider>
  );
}

function PreloadedApp() {
  // const domain = useCurrentDomain();
  const company = useCompany();
  const authorization = useSafeAuthorization();
  // const authDomain = useAuthentication().authentication?.domainFull;
  // const lastNotification = useLastNotificationResponse();

  if (!company) {
    return null;
  }

  // console.log({ authDomain, name: company.name.full, domain, d: company });

  const { dark, light, default: chosen } = company.application.themes;
  // TODO switching logic
  const theme = chosen === 'dark' ? dark : light;

  const primaryIsDark = color(theme.colors.primary).isDark();
  return (
    <PaperProvider theme={{ ...theme }}>
      <StatusBar
        barStyle={
          Platform.OS === 'android' ||
          primaryIsDark ||
          (theme.dark && theme.mode !== 'exact')
            ? 'light-content'
            : 'dark-content'
        }
        translucent
        backgroundColor="#00000080"
      />
      {authorization ? <AuthenticatedApp /> : <LoginScreen />}
      <Connectivity />
      {Platform.OS !== 'web' ? <OtaUpdater /> : null}
    </PaperProvider>
  );
}
