import { PrimaryButton } from '@introcloud/blocks';
import { useNavigation, useRoute } from '@react-navigation/native';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { Image, Platform, ScrollView, View } from 'react-native';
import { Caption, Card, List, Paragraph, Title } from 'react-native-paper';
import { localeDateString } from 'react-native-time-helpers';
import { useTimestamp } from 'react-native-use-timestamp';
import { Header } from '../core/Header';
import { RouteProp } from '../core/Routes';
import { GELEGRAAF } from '../features';
import { FeaturedHeadline } from './FeaturedHeadline';
import { ImportantHeadline } from './ImportantHeadline';
import { RegularHeadline } from './RegularHeadline';
import { GelegraafItem, useGelegraaf } from './useGelegraaf';

const FEATURED_TYPES = ['gallery', 'featured'];

export interface EditionProps {
  edition: string;
  isLive: boolean;
  isCurrent: boolean;
  onGotoArticle(article: GelegraafItem): void;
  onGotoEdition(edition?: string): void;
}

export function GelegraafEditionScreen() {
  const {
    params: { day: edition },
  } = useRoute<RouteProp<'GelegraafEdition'>>();
  const { navigate } = useNavigation();

  const doGotoArticle = useCallback(
    (article: GelegraafItem) =>
      navigate('GelegraafArticle', { id: article.id, day: edition }),
    [navigate, edition]
  );

  const doGotoEdition = useCallback(
    (nextEdition?: string) =>
      navigate('GelegraafEdition', { day: nextEdition }),
    [navigate]
  );

  return (
    <View
      style={{
        width: '100%',
        flex: 1,
        height: Platform.select({ web: '100vh', default: '100%' }),
        maxHeight: Platform.select({ web: '100vh', default: '100%' }),
      }}
    >
      <Header title="Intree Magazine" subTitle={edition} />

      <Edition
        isLive
        isCurrent
        edition={edition}
        onGotoArticle={doGotoArticle}
        onGotoEdition={doGotoEdition}
      />
    </View>
  );
}

export function Edition({
  isLive,
  isCurrent,
  edition,
  onGotoArticle: doGotoArticle,
  onGotoEdition,
}: EditionProps) {
  const doGotoEdition = useCallback(
    (edition: string) => {
      onGotoEdition(edition);
    },
    [onGotoEdition]
  );

  return (
    <ScrollView
      nativeID="scroller"
      style={{
        flex: 1,
        maxHeight: '100%',
      }}
      contentContainerStyle={{
        maxWidth: 720,
        alignSelf: 'center',
        paddingBottom: 56,
        width: '100%',
      }}
    >
      {isLive ? (
        <LiveEdition edition={edition} onGotoArticle={doGotoArticle} />
      ) : (
        <DateGate edition={edition} />
      )}
      {isCurrent ? (
        <PreviousEditions edition={edition} onGotoEdition={doGotoEdition} />
      ) : null}
    </ScrollView>
  );
}

function DateGate({ edition }: { edition: string }) {
  const date = new Date(`${edition}T07:00:00Z`);

  return (
    <Card
      style={{
        alignItems: 'center',
        paddingBottom: 16,
        marginTop: 16,
        marginHorizontal: 16,
      }}
    >
      <Image
        style={{ width: 150, height: 150, alignSelf: 'center', margin: 'auto' }}
        source={require('../../assets/news.png')}
      />
      <Title
        key="description"
        style={{
          textAlign: 'center',
          alignSelf: 'center',
          paddingHorizontal: 16,
          paddingVertical: 0,
          lineHeight: 24,
        }}
      >
        Intree Magazine: today’s issue coming soon!
      </Title>
      <Paragraph
        key="check-back"
        style={{
          textAlign: 'center',
          alignSelf: 'center',
          marginHorizontal: 16,
          marginTop: 8,
          marginBottom: 0,
        }}
      >
        During Intreeweek we publish articles and photos in this digital edition
        of the Intree Magazine, our daily digital magazine.{' '}
        <Caption>
          Check back on {localeDateString(date)}, after 09:00, to read this
          issue.
        </Caption>
      </Paragraph>
    </Card>
  );
}

function LoadingIndicator({
  onForceRefresh: doForceRefresh,
}: {
  onForceRefresh(): void;
}) {
  const timestamp = useTimestamp({ every: 7500 });
  const renderCountRef = useRef(0);

  useEffect(() => {
    renderCountRef.current += 1;
  }, [timestamp]);

  return (
    <PrimaryButton
      theme={{ colors: { primary: '#b9a9cb' } }}
      mode="contained"
      onPress={doForceRefresh}
      disabled={renderCountRef.current < 1}
      loading={renderCountRef.current < 1}
      style={{ margin: 16 }}
    >
      Refresh
    </PrimaryButton>
  );
}

function LiveEdition({
  edition,
  onGotoArticle: doGotoArticle,
}: {
  edition: string;
  onGotoArticle(article: GelegraafItem): void;
}) {
  const { data: items, error, refetch } = useGelegraaf(edition);

  const featuredItems = useMemo(
    () =>
      (items || []).filter(
        (item) => FEATURED_TYPES.indexOf(item.listing_type) !== -1
      ),
    [items]
  );
  const [primaryItem, regularItems] = useMemo(() => {
    const [primary, ...regular] = (items || []).filter(
      (item) => FEATURED_TYPES.indexOf(item.listing_type) === -1
    );
    return [primary, regular];
  }, [items]);

  if (!items || items.length === 0) {
    return <LoadingIndicator onForceRefresh={() => refetch()} />;
  }

  return (
    <Fragment>
      <PrimarySection item={primaryItem} onGotoArticle={doGotoArticle} />
      <FeaturedSection items={featuredItems} onGotoArticle={doGotoArticle} />
      <RegularSection items={regularItems} onGotoArticle={doGotoArticle} />
    </Fragment>
  );
}

const FIRST_EDITION = new Date(GELEGRAAF?.first?.timestamp || '');

function PreviousEditions({
  edition,
  onGotoEdition,
}: {
  edition: string;
  onGotoEdition(edition: string): void;
}) {
  const boundary = FIRST_EDITION.getTime();
  const editionLinks = useMemo(() => {
    const links = [];
    let current = new Date(edition).getTime();

    while (current > boundary + 10) {
      current = current - 60 * 60 * 24 * 1000;

      const currentDate = new Date(current);
      const editionName = localeDateString(currentDate);
      const edition = currentDate.toISOString().split('T')[0];
      links.push(
        <List.Item
          left={(props) => <List.Icon icon="newspaper" {...props} />}
          right={(props) => <List.Icon icon="chevron-right" {...props} />}
          key={edition}
          title={editionName}
          onPress={() => onGotoEdition(edition)}
        />
      );
    }

    return links;
  }, [edition, boundary]);

  if (editionLinks.length === 0) {
    return null;
  }

  return (
    <List.Section
      title="Older editions"
      style={{
        marginTop: 16,
      }}
    >
      <Card>{editionLinks}</Card>
    </List.Section>
  );
}

function PrimarySection({
  item,
  onGotoArticle,
}: {
  item: GelegraafItem;
  onGotoArticle(item: GelegraafItem): void;
}) {
  return (
    <ImportantHeadline
      article={item}
      onGotoArticle={() => onGotoArticle(item)}
    />
  );
}

function RegularSection({
  items,
  onGotoArticle,
}: {
  items: readonly GelegraafItem[];
  onGotoArticle(item: GelegraafItem): void;
}) {
  if (items.length === 0) {
    return null;
  }

  return (
    <Card style={{ marginVertical: 16 }}>
      {items.map((item) => (
        <RegularHeadline
          key={item.id}
          article={item}
          onGotoArticle={() => onGotoArticle(item)}
        />
      ))}
    </Card>
  );
}

function FeaturedSection({
  items,
  onGotoArticle,
}: {
  items: readonly GelegraafItem[];
  onGotoArticle(item: GelegraafItem): void;
}) {
  if (items.length === 0) {
    return null;
  }

  return (
    <View
      style={{
        marginTop: 16,
        paddingVertical: 0,
        paddingHorizontal: 8,
        flexDirection: 'row',
      }}
    >
      {items.map((item, index) => (
        <FeaturedHeadline
          even={index % 2 === 0}
          key={item.id}
          article={item}
          onGotoArticle={() => onGotoArticle(item)}
        />
      ))}
    </View>
  );
}
