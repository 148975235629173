import { TactileConversation } from '@introcloud/api-client';
import { useCallback } from 'react';
import { NotReady } from '../core/errors/NotReady';
import { SHOULD_DEBUG_FETCH } from '../utils';
import { useEndpoint, useSafeAuthorization } from './useAuthentication';

export function useChannelOneFetcher(
  fetcher: (
    id: string,
    authorization: string,
    endpoint: string,
    signal?: AbortSignal,
    debug?: boolean
  ) => Promise<TactileConversation>
) {
  const authorization = useSafeAuthorization();
  const endpoint = useEndpoint();

  return useCallback(
    (id: string, { signal }: { signal?: AbortSignal }) => {
      if (!endpoint || !authorization) {
        throw new NotReady();
      }

      return fetcher(id, endpoint, authorization, signal, SHOULD_DEBUG_FETCH);
    },
    [authorization, endpoint]
  );
}

export function useChannelTwoFetcher(
  fetcher: (
    id: string,
    id2: string,
    authorization: string,
    endpoint: string,
    signal?: AbortSignal,
    debug?: boolean
  ) => Promise<TactileConversation>
) {
  const authorization = useSafeAuthorization();
  const endpoint = useEndpoint();

  return useCallback(
    async (id: string, id2: string, { signal }: { signal?: AbortSignal }) => {
      if (!endpoint || !authorization) {
        throw new NotReady();
      }

      return fetcher(
        id,
        id2,
        endpoint,
        authorization,
        signal,
        SHOULD_DEBUG_FETCH
      );
    },
    [authorization, endpoint]
  );
}
