import { Divider } from '@introcloud/blocks';
import { BlockOptions } from '@introcloud/page';
import React from 'react';
import { PixelRatio, StyleSheet } from 'react-native';
import { Paragraph, Surface, Text } from 'react-native-paper';
import { DarkTheme, ThemeProvider } from '../core/FixedThemeProvider';

export function EmptyBlock({ ...props }: BlockOptions) {
  return (
    <ThemeProvider theme={DarkTheme}>
      <Divider
        style={{
          height: PixelRatio.roundToNearestPixel(StyleSheet.hairlineWidth),
        }}
      />
      <Surface
        style={{
          elevation: 1,
          backgroundColor: '#100e17',
          paddingHorizontal: 18,
          paddingVertical: 12,
          top: 0,
          width: '100%',
        }}
      >
        <Paragraph style={{ marginBottom: 8, lineHeight: 24 }}>
          This <Text style={{ fontWeight: 'bold' }}>{props.kind}</Text> block is
          completely empty. In production, this block will be hidden.
        </Paragraph>
      </Surface>
    </ThemeProvider>
  );
}
