import { ResponsiveIframe } from '@introcloud/blocks';
import { BlockOptions, BlockProps, PureBlock } from '@introcloud/page';
import {
  hasSurfaceAtBottom,
  hasSurfaceAtTop,
} from '@introcloud/page/dist/utils/hasSurface';
import React, { ErrorInfo } from 'react';
import { View } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import {
  BLOCK_CUSTOM_EMBED,
  BLOCK_GELEGRAAF,
  BLOCK_INFO_MARKET,
  BLOCK_RIJKSMUSEUM,
  BLOCK_ZOO_AUDIO_TOUR,
} from '../features';
import { GelegraafBlock } from '../gelegraaf/GelegraafBlock';
import { Sentry } from '../sentry';
import { DevelopingBlock } from './DevelopingBlock';
import { ErrorBlock } from './ErrorBlock';

const SoundBites = React.lazy(() =>
  BLOCK_ZOO_AUDIO_TOUR
    ? import('../custom/SoundBites').then(({ SoundBites }) => ({
        default: SoundBites,
      }))
    : Promise.resolve({ default: View as any })
);

const InfoMarket = React.lazy(() =>
  BLOCK_INFO_MARKET
    ? import('../custom/InfoMarket').then(({ InfoMarket }) => ({
        default: InfoMarket,
      }))
    : Promise.resolve({ default: View as any })
);

export class PureCustomBlock extends React.PureComponent<
  BlockProps<BlockOptions> & { kind: 'custom' },
  { hasReportedError: boolean; error?: Error }
> {
  constructor(props: BlockProps<BlockOptions> & { kind: 'custom' }) {
    super(props);

    this.state = {
      error: undefined,
      hasReportedError: false,
    };

    this.retry = this.retry.bind(this);
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  // eslint-disable-next-line handle-callback-err
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (this.state.hasReportedError) {
      return;
    }

    this.setState((prev) => ({ ...prev, hasReportedError: true }));

    Sentry.withScope((scope) => {
      scope.clear();
      scope.setExtras({ errorInfo, block: this.props });
      scope.setTag('block', this.props.kind);
      Sentry.captureException(error);
    });
  }

  retry() {
    this.setState({ error: undefined, hasReportedError: false });
  }

  render() {
    const block = this.props;
    const hasError = !!this.state.error;

    // Handle errors or rethrow
    if (hasError) {
      return <ErrorBlock error={this.state.error!} {...block} />;
    }

    const bottomIsSurface = hasSurfaceAtTop(block.next);
    const topIsSurface = hasSurfaceAtBottom(block.previous);
    /*
    if (bottomIsSurface && topIsSurface) {
      return (
        <View style={{ width: '100%', overflow: 'hidden' }}>
          <CustomBlock {...block} />
        </View>
      );
    }
*/
    return <CustomBlock {...block} />;
  }
}

export function CustomBlock(
  block: BlockProps<BlockOptions> & { kind: 'custom' }
) {
  switch (block.value.custom) {
    case 'nl.intree.app://gelegraaf': {
      if (!BLOCK_GELEGRAAF) {
        throw new Error(
          `Custom block "${block.value.custom}" is not available or allowed`
        );
      }

      return <GelegraafBlock {...block} />;
    }

    case 'nl.intree.app://zoo-audio-tour': {
      if (!BLOCK_ZOO_AUDIO_TOUR) {
        throw new Error(
          `Custom block "${block.value.custom}" is not available or allowed`
        );
      }
      return <ZooAudioTourBlock {...block} />;
    }

    case 'nl.topweek.app://info-market': {
      if (!BLOCK_INFO_MARKET) {
        throw new Error(
          `Custom block "${block.value.custom}" is not available or allowed`
        );
      }

      return (
        <InformationMarketBlock {...block} eventId="5ee2374526cef046c48cb914" />
      );
    }

    case 'nl.intree.app://info-market': {
      if (!BLOCK_INFO_MARKET) {
        throw new Error(
          `Custom block "${block.value.custom}" is not available or allowed`
        );
      }

      // TODO: intree specific
      return null;
    }

    case 'nl.intree.app://rijksmuseum': {
      if (!BLOCK_RIJKSMUSEUM) {
        throw new Error(
          `Custom block "${block.value.custom}" is not available or allowed`
        );
      }

      return <ExperienceBlock {...block} src="ontdek-meesterwerken" />;
    }

    case 'nl.elcidweek.app://custom-embed': {
      if (!BLOCK_CUSTOM_EMBED) {
        throw new Error(
          `Custom block "${block.value.custom}" is not available or allowed`
        );
      }

      return (
        <CustomEmbedBlock
          src="https://projects.ivorystudio.net/embed/projects/y5nui_WNhnX1"
          {...block}
        />
      );
    }

    case 'development': {
      if (!__DEV__) {
        return null;
      }

      return (
        <DevelopingBlock
          src="https://images.pexels.com/photos/760710/pexels-photo-760710.jpeg?cs=srgb&dl=pexels-bich-tran-760710.jpg&fm=jpg"
          ratio={1}
          mode="cover"
          zoomable
        />
      );
    }
  }

  throw new Error(`Custom block ${block.value.custom} not recognized`);
}

function ZooAudioTourBlock(
  block: BlockProps<BlockOptions> & { kind: 'custom' }
) {
  return (
    <React.Suspense
      fallback={
        <View
          style={{
            height: 480 + 16,
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ActivityIndicator size="large" />
        </View>
      }
    >
      <SoundBites />
    </React.Suspense>
  );
}

function InformationMarketBlock(
  block: BlockProps<BlockOptions> & { kind: 'custom'; eventId: string }
) {
  return (
    <React.Suspense
      fallback={
        <View
          style={{
            height: 480 + 16,
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ActivityIndicator size="large" />
        </View>
      }
    >
      <InfoMarket eventId={block.eventId} />
    </React.Suspense>
  );
}

function ExperienceBlock(
  block: BlockProps<BlockOptions> & { kind: 'custom'; src: string }
) {
  return (
    <PureBlock
      {...block}
      id={block.id}
      kind="action"
      value={{
        destination: {
          kind: 'internal',
          value: ['experience', block.src].join('/'),
        },
        label: 'Take the tour or Play',
        icon: 'virtual-reality',
      }}
      options={{
        mode: 'accent',
        spacing: 1,
        round: true,
      }}
    />
  );
}

function CustomEmbedBlock(
  block: BlockProps<BlockOptions> & { kind: 'custom'; src: string }
) {
  return <ResponsiveIframe ratio={1920 / 1080} src={block.src} />;
}
