import React, { useCallback, useEffect, useState } from 'react';
import { KeyboardAvoidingView, Platform, ScrollView, View } from 'react-native';
import { useTheme } from 'react-native-paper';
import Animated, {
  EasingNode,
  Extrapolate,
  SpringUtils,
  interpolateNode,
} from 'react-native-reanimated';
import {
  useSpringTransition,
  useTimingTransition,
} from 'react-native-redash/lib/module/v1/index';
import { useIsMounted } from 'use-is-mounted';

export function AnimatedDialog<T = unknown>({
  onAccept,
  children,
}: {
  onAccept(arg: T): void;
  children: (accept: (arg: T) => void) => React.ReactNode;
}) {
  const isMounted = useIsMounted();
  const accept = useCallback(
    (args: T) => {
      if (!isMounted.current) {
        return;
      }
      setShowing(false);

      setTimeout(() => {
        isMounted.current && onAccept(args);
      }, 500);
    },
    [onAccept]
  );

  const [showing, setShowing] = useState(true);
  const [initialRender, setInitialRender] = useState(true);
  const animation = useTimingTransition(showing, {
    duration: 320,
    easing: EasingNode.inOut(EasingNode.ease),
  });

  const opacity = interpolateNode(animation, {
    inputRange: [0, 0.5],
    outputRange: [0, 1],
    extrapolateRight: Extrapolate.CLAMP,
  });

  const scaleAnimation = useSpringTransition(showing, {
    ...SpringUtils.makeDefaultConfig(),
    mass: 0.3,
  });

  const scale = interpolateNode(scaleAnimation, {
    inputRange: [0, 1],
    outputRange: [0, 1],
  });

  useEffect(() => {
    setTimeout(() => isMounted.current && setInitialRender(false), 0);
  }, []);

  const {
    colors: { primary },
  } = useTheme();
  return (
    <View
      style={{
        opacity: initialRender ? 0 : 1,
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '100%',
      }}
    >
      <Animated.View
        style={{
          flex: 1,
          backgroundColor: primary,
          opacity,
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      />
      <Animated.View
        style={{
          transform: [{ scale }],
          position: 'relative',
          maxWidth: '100%',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : undefined}
        >
          <ScrollView
            style={{ width: '100%', height: '100%' }}
            contentContainerStyle={{
              padding: 16,
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '100%',
              maxWidth: '100%',
            }}
          >
            {children(accept)}
          </ScrollView>
        </KeyboardAvoidingView>
      </Animated.View>
    </View>
  );
}
