import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { GELEGRAAF } from '../features';

const LIST_ENDPOINT = GELEGRAAF?.endpoint;

export interface GelegraafItem {
  id: string;
  title?: { rendered: string };
  content?: { rendered: string };
  featured_media?: {
    href?: string;
    media_details?: {
      sizes?: {
        'blog-full'?: { source_url: string };
        'speaker-thumb'?: { source_url: string };
        medium: { source_url: string };
        medium_large: { source_url: string };
        large: { source_url: string };
      };
    };
  };
  date_gmt: string;
  listing_type: string;
  meta: any;
}

function sliceGraafje(item: any): GelegraafItem | null {
  try {
    return {
      id: item.id,
      title: item.title,
      content: item.content,
      featured_media: (item._embedded['wp:featuredmedia'] || [])[0] || {},
      date_gmt: item.date_gmt,
      listing_type: item.meta['_intree-gg-listing-type'] || 'regular',
      meta: item.meta,
    };
  } catch (_) {
    return null;
  }
}

async function getGelegraaf(
  issueDate: string,
  { signal }: { signal?: AbortSignal }
): Promise<readonly NonNullable<ReturnType<typeof sliceGraafje>>[]> {
  const issueStartDate = issueDate + 'T00:00:00Z';
  const issueEndDate = issueDate + 'T23:59:59Z';

  const endpoint = `${LIST_ENDPOINT}&after=${issueStartDate}&before=${issueEndDate}`;
  const headers: Record<string, string> = {};

  /*
  if (__DEV__) {
    // release at issueDate + 'T07:00:00Z'
    endpoint += '&status=any';
    headers['Authorization'] = `Basic ${PASSWORD}`;
  }
  */

  return fetch(endpoint, {
    method: 'get',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers,
    },
    signal,
  })
    .then((response) => response.json() || [])
    .then((graafjes) => graafjes.map(sliceGraafje).filter(Boolean))
    .catch((err) => {
      console.warn(err);
      return [];
    });
}

export function useGelegraaf(
  issueDate = GELEGRAAF?.first?.edition,
  { enabled = true } = { enabled: true }
) {
  const fetcher = useCallback(
    ({ signal }) => getGelegraaf(issueDate!, { signal }),
    [issueDate]
  );

  return useQuery([issueDate, 'gelegraaf'], fetcher, {
    enabled: !!issueDate && enabled,
  });
}
