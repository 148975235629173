import { fetchRegisteredTimeslot } from '@introcloud/api-client';
import { eventTagIconFor, i18n, useLocale } from '@introcloud/blocks';
import {
  ProvideBlockNavigation,
  ProvideBlockSettings,
  useBlockData,
  useBlockDataLocation,
  useBlockDataPage,
  useBlockDataTimeslots,
  useBlockImageUrl,
  useBlockNavigation,
  useBlockSettings,
  useWindowWidth,
} from '@introcloud/blocks-interface';
import { localize, useLocalization } from '@introcloud/blocks/dist/useLocale';
import { useIsMobileView } from '@introcloud/page/dist/utils/useIsMobileView';
import Color from 'color';
import { LinearGradient } from 'expo-linear-gradient';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Image,
  Platform,
  SectionListData,
  StyleSheet,
  TextInput,
  View,
} from 'react-native';
import {
  Caption,
  Card,
  Divider,
  HelperText,
  IconButton,
  MD2Theme,
  Searchbar,
  Surface,
  Text,
  Title,
  overlay,
  useTheme,
} from 'react-native-paper';
import Animated, {
  SharedValue,
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated';
import { localeDateString, localeTimeString } from 'react-native-time-helpers';
import { useInaccurateTimestamp } from 'react-native-use-timestamp';
import { BlockProvision } from '../core/BlockProvision';
import { FilterMenu } from '../core/FilterMenu';
import { Header } from '../core/Header';
import { useCompany } from '../hooks/useCompany';
import { useCompanyEventsGrid } from '../hooks/useCompanyEventsGrid';
import {
  getDayUnixBoundaries,
  getMidDayId,
  useDayBoundaries,
} from '../hooks/useDayBoundaries';
import { PreparedEvent, useEvents } from '../hooks/useEvents';
import { useForceUpdateCount } from '../hooks/useForceUpdate';
import { PreparedLocation, useLocations } from '../hooks/useLocations';
import { useProvideBlockNavigation } from '../hooks/useProvideBlockNavigation';
import { useTabTitle } from '../hooks/useTab';
import { extractEventData } from '../utils';
import { ScrollableGrid } from './ScrollableGrid';
import { StickyHeader } from './StickyHeader';
import { StickyRow } from './StickyRow';
import { useConfiguredDays } from './useConfiguredDays';

export function EventsGridScreen({ asTab }: { asTab?: boolean }) {
  const grid = useCompanyEventsGrid();
  const navigation = useProvideBlockNavigation();

  if (!grid) {
    return <HelperText type="error">Events grid is not configured</HelperText>;
  }
  return (
    <ProvideBlockNavigation navigation={navigation}>
      <EventsGrid configuration={grid} hideBack={asTab} />
    </ProvideBlockNavigation>
  );
}

function EventsGrid({
  configuration,
  hideBack,
}: {
  configuration: NonNullable<ReturnType<typeof useCompanyEventsGrid>>;
  hideBack?: boolean;
}) {
  const title = useTabTitle('events-grid') ?? i18n.t('app.calendar.title');

  return (
    <BlockProvision screen="EventsGridScreen">
      <View
        style={{
          flex: 1,
          overflow: 'hidden',
          maxHeight: Platform.select({
            web: '100vh',
            default: '100%',
          }),
        }}
      >
        <Header
          title={title || i18n.t('app.calendar.title')}
          subTitle={undefined}
          hideBack={hideBack}
          showTranslate
        />
        <Calendar configuration={configuration} />
      </View>
    </BlockProvision>
  );
}

const EMPTY: readonly PreparedEvent[] = [];
const EMPTY_LOCATIONS: readonly PreparedLocation[] = [];
const DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;
const DEFAULT_HOUR_WIDTH = 350;
const MINIMUM_HOUR_WIDTH = 275;
const EVENT_HEIGHT = 84;
const MOBILE_HEADER_HEIGHT = 128;
const DEFAULT_HEADER_HEIGHT = 128; // 200;
const NO_IMAGES_HEADER_HEIGHT = 72;
const HIDE_SECONDARY_LOCATIONS = true;

const DISABLE_EVENT_IMAGES = true;

function Calendar({
  configuration,
}: {
  configuration: NonNullable<ReturnType<typeof useCompanyEventsGrid>>;
}) {
  const [updateId, forceUpdate] = useForceUpdateCount();

  const currentDay = useMemo(() => {
    // These durations are stored on midnight-ish
    const {
      duration: {
        start: { unix: startUnix },
        end: { unix: endUnix },
      },
    } = configuration;

    if (startUnix > endUnix) {
      return getMidDayId(startUnix);
    }

    const now = new Date().getTime();

    if (now < startUnix) {
      return getMidDayId(startUnix);
    }

    if (now > endUnix) {
      return getMidDayId(endUnix);
    }

    let dayNow = startUnix;
    while (dayNow < endUnix) {
      const dayId = getMidDayId(dayNow);
      const [from, to] = getDayUnixBoundaries(dayId);
      if (dayNow < from) {
        return dayId;
      }

      if (dayNow < to) {
        return dayId;
      }

      dayNow = dayNow + DAY_IN_MILLISECONDS;
    }

    return getMidDayId(dayNow);
  }, [configuration, updateId]);

  const [overrideDay, setOverrideDay] = useState(currentDay);

  return (
    <CalendarDay
      key={overrideDay}
      day={overrideDay}
      setDay={setOverrideDay}
      configuration={configuration}
    />
  );
}

function CalendarDay({
  day,
  setDay,
  configuration,
}: {
  day: string;
  setDay: (next: string) => void;
  configuration: NonNullable<ReturnType<typeof useCompanyEventsGrid>>;
}) {
  const { data: events } = useEvents();
  const { data: locations } = useLocations();
  const locale = useLocale();

  let [from, to] = useDayBoundaries(day);

  // console.log({ from, to, overrideDay });

  from = Math.max(from, configuration.duration.start.unix);
  to = Math.min(to, configuration.duration.end.unix);

  const dayEvents = useMemo(() => {
    const result = events?.filter(
      (event) =>
        event.hierarchy.showInCalendar &&
        event.duration.end.unix >= from &&
        event.duration.start.unix <= to
    );

    if (!result) {
      return [];
    }

    result.sort((a, b) => {
      const comp = a.duration.start.unix - b.duration.end.unix;
      if (comp !== 0) {
        return comp;
      }

      return a.duration.end.unix - b.duration.end.unix;
    });

    return result;
  }, [events, from, to]);

  const [searchActive, setSearchActive] = useState(false);
  const [queriedEvents, setQueriedEvents] = useState(
    () => (dayEvents as readonly PreparedEvent[]) || []
  );

  const sections = useEventSections(
    queriedEvents || EMPTY,
    locations || EMPTY_LOCATIONS
  );

  // console.log({ queriedEvents, dayEvents });

  const onQueryChanged = useCallback(
    (query: string, activeTag: string) => {
      if (!dayEvents) {
        return [];
      }

      const parts = query
        .toLocaleLowerCase()
        .split(' ')
        .filter((item) => item.trim().length);

      const taggedEvents = activeTag
        ? dayEvents.filter((item) => {
            const tags = localize(item.nameLocalized?.tag, item.name.tag) || [];
            return tags.includes(activeTag);
          })
        : dayEvents.slice();

      setQueriedEvents(
        parts.length > 0
          ? taggedEvents.filter((item) => {
              const names = [
                item.name.full,
                localize(item.nameLocalized?.full, ''),
              ]
                .filter(Boolean)
                .join(' ')
                .toLocaleLowerCase();
              return parts.every((p) => names.includes(p));
            })
          : taggedEvents
      );
    },
    [dayEvents, locale]
  );

  const start = Math.max(
    from,
    sections.reduce(
      (min, section) =>
        Math.min(
          min,
          ...section.data.map((datum) =>
            datum.events.reduce(
              (min, event) => Math.min(min, event.duration.start.unix),
              Number.MAX_SAFE_INTEGER
            )
          )
        ),
      Number.MAX_SAFE_INTEGER
    )
  );

  const end = Math.min(
    to,
    sections.reduce(
      (max, section) =>
        Math.max(
          max,
          ...section.data.map((datum) =>
            datum.events.reduce(
              (max, event) => Math.max(max, event.duration.end.unix),
              Number.MIN_SAFE_INTEGER
            )
          )
        ),
      Number.MIN_SAFE_INTEGER
    )
  );

  /*
  console.log({
    from: new Date(from),
    start: new Date(start),
    first: new Date(
      sections.reduce(
        (min, section) =>
          Math.min(
            min,
            ...section.data.map((datum) =>
              datum.events.reduce(
                (min, event) => Math.min(min, event.duration.start.unix),
                Number.MAX_SAFE_INTEGER
              )
            )
          ),
        Number.MAX_SAFE_INTEGER
      )
    ),
    to,
    end,
    sections,
  });
  */

  const width = useWindowWidth();
  const ratio =
    width < DEFAULT_HOUR_WIDTH ? MINIMUM_HOUR_WIDTH : DEFAULT_HOUR_WIDTH; // * configuration.duration.step.minutes;
  const useEventColors = configuration.useEventColours;

  // console.log(configuration.duration);

  const renderItem = useMemo(
    () => makeRenderItem(start, end, ratio, useEventColors),
    [start, end, ratio]
  );
  const rowWidth = durationToPixels(end - start, ratio);
  const useLocationColors = configuration.useLocationColours;
  const useLocationImages = configuration.useLocationImages;

  const settings = useBlockSettings();

  const x = useSharedValueX({ start, end, ratio });
  const y = useSharedValue(0);

  return (
    <ProvideBlockSettings
      settings={{
        EventListItem: {
          ...settings['EventListItem'],
          type: 'designed',
          lines: 2,
        },
      }}
    >
      <CalendarSearch
        key={day}
        active={searchActive}
        events={events || EMPTY}
        onQueryChanged={onQueryChanged}
        onChangeActive={setSearchActive}
      />

      <ScrollableGrid
        width={rowWidth}
        x={x}
        y={y}
        enabled={!!(sections && sections.length > 0)}
      >
        <CalendarDates
          start={start}
          end={end}
          rowWidth={rowWidth}
          ratio={ratio}
          step={configuration.duration.step.seconds * 1000}
          y={y}
        />
        {sections.map((section) => {
          return (
            <View
              key={section.location?._id || '-'}
              style={{
                position: 'relative',
                width: rowWidth,
                marginBottom: 64,
              }}
            >
              {renderHeader({
                x,
                section,
                useLocationColors,
                useLocationImages,
              })}
              {section.data.map((item) =>
                renderItem({
                  item,
                  id: `${section.location?._id || '-'}.${
                    item.location?._id || '-'
                  }`,
                })
              )}
            </View>
          );
        })}
        <NowLine
          start={start}
          end={end}
          rowWidth={rowWidth}
          ratio={ratio}
          y={y}
        />
      </ScrollableGrid>

      <CalendarTabs
        onChange={setDay}
        currentDay={day}
        days={useConfiguredDays({
          start: configuration.duration.start.unix,
          end: configuration.duration.end.unix,
        })}
        enabled={configuration.showDayTabs}
      />
    </ProvideBlockSettings>
  );
}

function CalendarTabs({
  enabled,
  onChange,
  currentDay,
  days,
}: {
  onChange: (next: string) => void;
  currentDay: string;
  days: { day: string; date: number }[];
  enabled: boolean;
}) {
  const company = useCompany();
  const isNeutral = company?.application.tabs.neutral;
  const {
    dark: isDarkTheme,
    mode,
    colors: { surface, primary },
  } = useTheme();

  if (!enabled) {
    return null;
  }

  const elevation = 4;

  const backgroundColor = isNeutral
    ? isDarkTheme && mode === 'adaptive'
      ? overlay(elevation, surface)
      : surface
    : primary;

  const color = new Color(backgroundColor).isDark() ? '#FFF' : '#000';
  const index = days.findIndex((v) => getMidDayId(v.day) === currentDay);

  if (index === -1) {
    // console.warn({ days, currentDay });
    return null;
  }

  return (
    <Surface
      style={{
        backgroundColor,
        elevation: 4,
        height: 48,
        width: '100%',
        position: 'relative',
      }}
    >
      <View
        style={{
          height: '100%',
          width: '100%',
          maxWidth: 720,
          paddingHorizontal: 8,
          marginHorizontal: 'auto',
          flexDirection: 'row',
        }}
      >
        {index > 0 ? (
          <IconButton
            iconColor={color}
            icon="calendar-arrow-left"
            onPress={() => onChange(getMidDayId(days[index - 1].day))}
          />
        ) : (
          <View style={{ width: 48 }} />
        )}
        <Caption
          style={{
            color,
            flex: 1,
            textAlign: 'center',
            marginVertical: 'auto',
            textAlignVertical: 'center',
            height: Platform.select({ web: undefined, default: '100%' }),
            fontStyle: 'italic',
          }}
        >
          {localeDateString(new Date(currentDay), true, false)}
        </Caption>
        {index < days.length - 1 ? (
          <IconButton
            iconColor={color}
            icon="calendar-arrow-right"
            onPress={() => onChange(getMidDayId(days[index + 1].day))}
          />
        ) : (
          <View style={{ width: 48 }} />
        )}
      </View>
    </Surface>
  );
}

const dividerColor = new Color('#000000').alpha(0.12).rgb().string();

function useSharedValueX({
  start,
  end,
  ratio,
}: {
  start: number;
  end: number;
  ratio: number;
}) {
  const initial = useMemo(() => {
    const now =
      (__DEV__ ? start + 1000 * 8239 : new Date().getTime()) - 1000 * 60 * 15;

    if (now - start > end || now < start) {
      return 0;
    }

    return -durationToPixels(now - start, ratio);
  }, [start, end]);

  return useSharedValue(initial);
}

function CalendarDates({
  start,
  end,
  rowWidth,
  ratio,
  step,
  y,
}: {
  start: number;
  end: number;
  rowWidth: number;
  ratio: number;
  step: number;
  y: SharedValue<number>;
}) {
  const timestamps: Date[] = useMemo(() => {
    const results: Date[] = [];

    for (let i = start; i < end; i += step) {
      results.push(new Date(i));
      // console.log(new Date(i));
    }

    return results;
  }, [start, end]);

  const isMobileView = useIsMobileView();

  return (
    <StickyHeader
      elevation={isMobileView ? 2 : 1}
      borderColor={dividerColor}
      width={rowWidth}
      border={isMobileView}
      y={y}
    >
      {timestamps.map((timestamp) => (
        <View
          key={timestamp.getTime().toString()}
          style={{
            position: 'absolute',
            left: durationToPixels(timestamp.getTime() - start, ratio),
            borderLeftColor: dividerColor,
            borderLeftWidth: 1,
            paddingLeft: 4,
            paddingVertical: 8,
            marginTop: 26,
          }}
        >
          <Text>{localeTimeString(timestamp)}</Text>
        </View>
      ))}
      {Platform.OS === 'web' ? (
        <NowLineWeb ratio={ratio} start={start} end={end} />
      ) : null}
    </StickyHeader>
  );
}

function useNow({ start }: { start: number }) {
  const now = useInaccurateTimestamp({ every: 1000 * 30 });

  if (__DEV__) {
    return start + 1000 * 8239;
  }

  return now;
}

function NowLine({
  start,
  end,
  ratio,
  y,
}: {
  start: number;
  end: number;
  rowWidth: number;
  ratio: number;
  y: SharedValue<number>;
}) {
  const {
    colors: { accent },
  } = useTheme<MD2Theme>();
  const nowText = useLocalization({ en: 'Now', nl: 'Nu' }, 'Now');
  const now = useNow({ start });
  const animatedStyle = useAnimatedStyle(
    () => ({ transform: [{ translateY: -y.value }] }),
    [y]
  );

  if (now - start > end || now - start < 0 || Platform.OS === 'web') {
    return null;
  }

  const color = accent === '#000000' ? '#FF22AA' : accent;
  const left = durationToPixels(now - start, ratio);

  return (
    <Fragment>
      <Divider
        style={{
          height: '100%',
          width: 1,
          backgroundColor: color,
          top: 0,
          position: 'absolute',
          left: left + 1,
          zIndex: 0,
          opacity: 0.5,
        }}
      />

      <Animated.View
        style={[
          animatedStyle,
          {
            position: 'absolute',
            top: 0,
            zIndex: 2,
            left,
          },
        ]}
      >
        <Divider
          style={{
            height: 64,
            width: 3,
            backgroundColor: color,
            opacity: 0.8,
            zIndex: 4,
            top: 0,
            position: 'absolute',
            left: 0,
          }}
        />
        <Caption
          style={{
            color,
            top: 4,
            position: 'relative',
            paddingHorizontal: 8,
          }}
        >
          {nowText}
        </Caption>
      </Animated.View>
    </Fragment>
  );
}

function NowLineWeb({
  start,
  end,
  ratio,
}: {
  start: number;
  end: number;
  ratio: number;
}) {
  const nowText = useLocalization({ en: 'Now', nl: 'Nu' }, 'Now');
  const now = useNow({ start });

  const {
    colors: { accent },
  } = useTheme<MD2Theme>();

  if (now - start > end || now - start < 0 || Platform.OS !== 'web') {
    return null;
  }

  const color = accent === '#000000' ? '#FF22AA' : accent;
  const left = durationToPixels(now - start, ratio);

  return (
    <Fragment>
      <Divider
        style={{
          height: 64,
          width: 3,
          backgroundColor: color,
          opacity: 0.8,
          zIndex: 4,
          top: 0,
          position: 'absolute',
          left,
        }}
      />
      <Caption
        key="now"
        style={{
          left,
          color,
          marginTop: 4,
          position: 'relative',
          paddingHorizontal: 8,
        }}
      >
        {nowText}
      </Caption>
    </Fragment>
  );
}

function CalendarSearch({
  onQueryChanged,
  onChangeActive,
  events,
  active,
}: {
  onQueryChanged(query: string, tag: string | null): void;
  onChangeActive(next: boolean | ((prev: boolean) => boolean)): void;
  active: boolean;
  events: readonly PreparedEvent[];
}) {
  useLocale();

  const [query, setQuery] = useState('');
  const [tag, setTag] = useState<string | null>(null);

  const searchBar =
    useRef<
      Pick<
        TextInput,
        'blur' | 'focus' | 'setNativeProps' | 'isFocused' | 'clear'
      >
    >(null);

  const doToggleActive = useCallback(
    () => onChangeActive((prev) => !prev),
    [onChangeActive]
  );
  const setActive = useCallback(() => onChangeActive(true), [onChangeActive]);

  // Focus when it becomes active
  useEffect(() => {
    if (active && searchBar.current) {
      searchBar.current.focus();
    }
  }, [active, searchBar]);

  // Update query with debounce
  useEffect(() => {
    const debounceTimer = setTimeout(() => onQueryChanged(query, tag), 220);
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [events, query, tag, active]);

  // Get the list of tags
  const tags = useMemo(
    () =>
      events
        .reduce((final, item) => {
          const tags = localize(item.nameLocalized?.tag, item.name.tag) || [];
          final.push(...tags);
          return final;
        }, [] as string[])
        .filter(Boolean)
        .filter((tag, index, self) => self.indexOf(tag) === index)
        .sort(),
    [events]
  );

  const showFilter = tags.length >= 1 && !active;
  const isMobileView = useIsMobileView();

  return (
    <View
      style={[
        {
          position: 'relative',
          alignSelf: 'center',
          width: '100%',
          maxWidth: 720,
          zIndex: 2,
        },
      ]}
    >
      <Searchbar
        selectTextOnFocus
        icon={active ? 'arrow-left' : 'text-box-search'}
        onIconPress={doToggleActive}
        onFocus={setActive}
        placeholder={i18n.t('app.calendar.search')}
        onChangeText={setQuery}
        value={query}
        style={[
          { marginHorizontal: isMobileView ? 0 : 0 },
          {
            elevation: 1,
            zIndex: 0,
          },
          isMobileView ? { borderRadius: 0 } : { marginVertical: 4 },
        ]}
      />

      {showFilter ? (
        <View
          style={{
            position: 'absolute',
            right: 8,
            top: 6,
            backgroundColor: 'transparent',
            elevation: 4,
            maxWidth: 170,
          }}
        >
          <FilterMenu
            onSelect={setTag}
            tags={tags}
            selected={tag}
            defaultIcon="calendar-blank"
            tagToIcon={eventTagIconFor}
          />
        </View>
      ) : null}
    </View>
  );
}

type SectionData = {
  events: PreparedEvent[];
  location: PreparedLocation | null;
};
type Item = SectionData;
type Entry = { location: PreparedLocation | null; data: Item[] };
type Section = SectionListData<Item, Entry>;

function makeRenderItem(
  start: number,
  end: number,
  ratio: number,
  useEventColors: boolean
) {
  const rowWidth = durationToPixels(end - start, ratio);
  return function renderItem({ item, id }: { item: Item; id: string }) {
    return (
      <View
        key={id}
        style={{
          position: 'relative',
          height: EVENT_HEIGHT + 4,
          width: rowWidth,
        }}
      >
        {item.events.map((event, index) => {
          const drawStart = Math.max(start, event.duration.start.unix);
          const drawEnd = Math.min(end, event.duration.end.unix);

          const left = durationToPixels(drawStart - start, ratio) + 1;
          const width = durationToPixels(drawEnd - drawStart, ratio) - 2;

          return (
            <View
              key={event._id}
              style={{
                position: 'absolute',
                width,
                top: 0,
                left,
                height: EVENT_HEIGHT,
                backgroundColor: 'white',
              }}
            >
              <EventItem event={event} useEventColors={useEventColors} />
              {/*<Text>
                {event.name.full} [
                {unixHours(event.duration.end.unix - event.duration.start.unix)}
                ] {event.duration.start.unix} - {event.duration.end.unix}
              </Text>*/}
            </View>
          );
        })}
      </View>
    );
  };
}

function unixHours(duration: number) {
  return duration / 1000 / 60 / 60;
}

function durationToPixels(duration: number, ratio: number) {
  return unixHours(duration) * ratio;
}

function EventItem({
  event,
  useEventColors,
}: {
  event: PreparedEvent;
  useEventColors: boolean;
}) {
  const { getImageUrl } = useBlockData();
  const locale = useLocale();
  const { gotoEvent } = useBlockNavigation();

  const {
    dark: isDarkTheme,
    mode,
    colors: { surface },
  } = useTheme();

  const page = useBlockDataPage(event.pageRef.pageId || '', {
    enabled: !!event.pageRef.pageId,
  });
  const primaryColor = useEventColors
    ? page.data?.module.application.colors.primary
    : undefined;

  const source = extractEventData(
    event,
    { isLast: true, loading: false },
    { getImageUrl },
    locale
  );

  if (!source) {
    return null;
  }

  const elevation = 1;
  const imageId = event.image
    ? event.image.profile || event.image.banner
    : null;
  const illustration = imageId ? getImageUrl(imageId, 'icon_256') : null;
  const backgroundColor =
    primaryColor || (isDarkTheme && mode === 'adaptive')
      ? overlay(elevation, surface)
      : surface;

  const secondaryLocation = source.locationIds[1];

  return (
    <Card
      elevation={elevation}
      style={{
        backgroundColor,
        width: '100%',
        height: '100%',
        maxHeight: '100%',
      }}
      onPress={() => gotoEvent(source.id)}
    >
      <View
        style={{
          overflow: 'hidden',
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        {illustration ? (
          <BackgroundImage
            src={illustration}
            backgroundColor={backgroundColor}
          />
        ) : null}

        <View
          style={{
            flex: 1,
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            maxWidth: 600,
            padding: 8,
          }}
        >
          <Text
            style={{
              marginTop: 2,
              lineHeight: 20,
              marginBottom: 4,
              fontSize: 14,
              fontWeight: 'bold',
              paddingRight: 8,
            }}
            numberOfLines={2}
          >
            {source.title}
          </Text>

          <View
            style={{
              paddingRight: 8,
              flexDirection: 'column',
              flex: 1,
            }}
          >
            {source.hasTimeslots ? (
              <TimeslotTime
                fallbackStart={source.duration.start}
                fallbackEnd={source.duration.end}
                id={source.id}
              />
            ) : (
              <EventTime
                start={source.duration.start}
                end={source.duration.end}
              />
            )}

            {secondaryLocation ? (
              <SecondaryLocationMeta locationId={secondaryLocation} />
            ) : null}
          </View>
        </View>
      </View>
    </Card>
  );
}

function SecondaryLocationMeta({ locationId }: { locationId: string }) {
  const { data } = useBlockDataLocation(locationId);
  const name = useLocalization(data?.nameLocalized?.full, data?.name.full);

  if (HIDE_SECONDARY_LOCATIONS) {
    return null;
  }

  return (
    <View style={{ marginTop: 'auto' }}>
      <Caption>{name}</Caption>
    </View>
  );
}

function TimeslotTime({
  id,
  fallbackStart,
  fallbackEnd,
}: {
  id: string;
  fallbackStart: number;
  fallbackEnd: number;
}) {
  const { data, error } = useBlockDataTimeslots(id);

  const timeslot = data?.timeslots
    ? fetchRegisteredTimeslot(data?.timeslots)
    : undefined;

  if (error || !timeslot) {
    return <EventTime start={fallbackStart} end={fallbackEnd} />;
  }

  return <EventTime start={timeslot.start.unix} end={timeslot.end.unix} />;
}

function EventTime({ start, end }: { start: number; end: number }) {
  return (
    <Text>
      {localeTimeString(new Date(start))} - {localeTimeString(new Date(end))}
    </Text>
  );
}

function BackgroundImage({
  src,
  backgroundColor,
}: {
  src: string;
  backgroundColor: string;
}) {
  if (DISABLE_EVENT_IMAGES) {
    return null;
  }

  return (
    <View style={{ height: '100%', width: 256, marginLeft: 'auto' }}>
      <Image
        source={{ uri: src, width: 256, height: 256 }}
        resizeMode="cover"
        style={{ width: '100%', height: '100%' }}
      />
      <LinearGradient
        colors={[
          backgroundColor,
          new Color(backgroundColor).alpha(0.2).toString(),
          new Color(backgroundColor).alpha(0).toString(),
        ]}
        locations={[0.4, 0.5, 0.9]}
        start={{ x: 0, y: 0.5 }}
        end={{ x: 1, y: 0.5 }}
        style={StyleSheet.absoluteFill}
      />
    </View>
  );
}

function renderHeader({
  x,
  section,
  useLocationColors,
  useLocationImages,
}: {
  x: SharedValue<number>;
  section: Section;
  useLocationColors: boolean;
  useLocationImages: boolean;
}) {
  if (!section.location) {
    return null;
  }

  return (
    <LocationHeader
      x={x}
      location={section.location}
      useLocationColors={useLocationColors}
      useLocationImages={useLocationImages}
    />
  );
}

function LocationHeader({
  x,
  location,
  useLocationColors,
  useLocationImages,
}: {
  x: SharedValue<number>;
  location: PreparedLocation;
  useLocationColors: boolean;
  useLocationImages: boolean;
}) {
  const { data } = useBlockDataPage(location.pageRef.pageId || '', {
    enabled: useLocationColors && !!location.pageRef.pageId,
  });
  const {
    roundness,
    colors: { primary, background },
  } = useTheme();

  const locationPrimary = data?.module.application.colors.primary || primary;
  const locationName = useLocalization(
    location.nameLocalized?.full,
    location.name.full
  );
  const backgroundColor = useLocationColors ? locationPrimary : 'transparent';
  const color = new Color(
    useLocationColors ? locationPrimary : background
  ).isDark()
    ? '#FFF'
    : '#222';

  const protection =
    color === '#FFF' ? 'rgba(0, 0, 0, .12)' : 'rgba(255, 255, 255, .72)';

  const isMobileView = useIsMobileView();

  const height = useLocationImages
    ? isMobileView
      ? MOBILE_HEADER_HEIGHT
      : DEFAULT_HEADER_HEIGHT
    : NO_IMAGES_HEADER_HEIGHT;
  const width = useWindowWidth();
  const { gotoLocation } = useBlockNavigation();

  return (
    <Card
      elevation={useLocationColors ? 1 : 0}
      theme={{ roundness: 0 }}
      style={{
        width: '100%',
        height,
        backgroundColor,
        marginBottom: 4,
        zIndex: 1,
      }}
      onPress={() => gotoLocation(location._id)}
    >
      <StickyRow height={height} x={x}>
        <View
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            paddingHorizontal: 8,
          }}
        >
          {useLocationImages ? <LocationImage location={location} /> : null}
          <Title
            style={{
              color,
              backgroundColor: useLocationImages ? protection : 'transparent',
              marginLeft: useLocationImages ? 6 : -8,
              marginTop: 'auto',
              borderRadius: Math.max(0, roundness - 1),
              position: 'relative',
              marginBottom: isMobileView ? 4 : 4,
              paddingVertical: 0,
              paddingHorizontal: 8,
              maxWidth: width - 24 - 16,
            }}
            numberOfLines={2}
          >
            {locationName}
          </Title>
        </View>
      </StickyRow>
    </Card>
  );
}

function LocationImage({ location }: { location: PreparedLocation }) {
  const illustration = location.image.profile;
  const { roundness } = useTheme();
  const { data: src, isLoading } = useBlockImageUrl(
    illustration || null,
    'icon_512'
  );

  const isMobileView = useIsMobileView();

  if (!src && !isLoading) {
    return null;
  }

  return (
    <Card
      elevation={2}
      style={{
        marginLeft: 0,
        marginVertical: 8,
        marginRight: 24,
        position: 'absolute',
        top: 0,
        left: 8,
      }}
    >
      <Image
        source={
          isLoading
            ? {
                uri: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
                width: 1,
                height: 1,
              }
            : { uri: src!, width: 512, height: 512 }
        }
        resizeMode="cover"
        style={{
          borderRadius: roundness,

          height: isMobileView
            ? MOBILE_HEADER_HEIGHT - 16
            : DEFAULT_HEADER_HEIGHT - 16,
          width: DEFAULT_HEADER_HEIGHT - 16,
        }}
      />
    </Card>
  );
}

function useEventSections(
  events: readonly PreparedEvent[],
  locations: readonly PreparedLocation[]
): Section[] {
  const locale = useLocale();

  const showLocations = useMemo(
    () =>
      locations
        .filter(
          (l) =>
            __DEV__ ||
            !Object.prototype.hasOwnProperty.call(
              l.module.application,
              'showInTimetable'
            ) ||
            !!(l.module.application as any).showInTimetable
        )
        .sort((a, b) =>
          (
            localize(a.nameLocalized?.full, a.name.full, locale) || ''
          ).localeCompare(
            localize(b.nameLocalized?.full, b.name.full, locale) || ''
          )
        ),
    [locations]
  );

  return useMemo(() => {
    const groups = events.reduce<
      Record<string, Record<string, PreparedEvent[]>>
    >((result, event) => {
      if (event.locationRef.length === 0) {
        result['-'] = result['-'] || { '-': [] };
        result['-']['-'].push(event);

        return result;
      }

      const id =
        event.locationRef.length === 1 ? '-' : event.locationRef[1].locationId;
      const key = event.locationRef[0].locationId;

      if (!key || !id) {
        return result;
      }

      result[key] = result[key] || { '-': [] };
      result[key][id] = result[key][id] || [];
      result[key][id].push(event);

      return result;
    }, {});

    const sections: {
      location: PreparedLocation | null;
      data: {
        id: string;
        events: PreparedEvent[];
        location: PreparedLocation | null;
      }[];
    }[] = [];

    showLocations.forEach((showLocation) => {
      if (!groups[showLocation._id]) {
        return;
      }

      const section: (typeof sections)[number] = {
        location: showLocation,
        data: [],
      };

      Object.keys(groups[showLocation._id]).forEach((key) => {
        const locationEvents = groups[showLocation._id][key];

        if (!locationEvents || locationEvents.length === 0) {
          return;
        }

        section.data.push({
          id: key,
          location:
            showLocations.find(
              (nestedLocation) => nestedLocation._id === key
            ) || null,
          events: locationEvents,
        });
      });

      if (section.data.length > 0) {
        // debugger;

        section.data.forEach((d) =>
          d.events.sort((a, b) => a.duration.start.unix - b.duration.start.unix)
        );

        section.data.sort((a, b) => {
          if (!a.events[0] || !b.events[0]) {
            return 0;
          }

          const timeComp =
            a.events[0].duration.start.unix - b.events[0].duration.start.unix;

          if (timeComp !== 0) {
            return timeComp;
          }

          return (a.events[0].locationRef[1].locationId || '-').localeCompare(
            b.events[0].locationRef[1].locationId || '-'
          );
        });

        sections.push(section);
      }
    });

    console.log({ sections });

    return sections;
  }, [events, locations, locale]);
}
