/* eslint-disable import/order */
//
import './src/core/screens';
import './src/hotfix';
import './src/localize';
import './src/polyfills/promise';
import { Sentry } from './src/sentry';
import './src/storage';
import './src/warnings';

//
import { defineTranslations, i18n } from '@introcloud/blocks';
import { StatusBar } from 'expo-status-bar';
import React, { Component, ErrorInfo } from 'react';
import { View } from 'react-native';
import { Text, Title } from 'react-native-paper';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Root } from './src/Root';
import { EXPO_NAME } from './src/config';

defineTranslations({
  en: {
    app: {
      loading: {
        title: EXPO_NAME,
        body: 'Loading & connecting to our service.',

        error: {
          title: EXPO_NAME,
          body: 'Cannot start the experience right now.',
        },
      },

      errors: {
        connect:
          'Failed to connect. Are you connected to the internet? We may be performing maintenance.',
      },

      error: {
        title: 'Something went wrong',
        body: 'This failure was unexpected, but we have been notified. You can try again by force closing this app. If the problem persists, it will keep showing up in our notifications.',
      },
    },
  },

  nl: {
    app: {
      loading: {
        title: EXPO_NAME,
        body: 'Laden & verbinden met onze service.',

        error: {
          title: EXPO_NAME,
          body: 'Kan de omgeving nu niet starten.',
        },
      },

      errors: {
        connect:
          'Verbinding maken mislukt. Ben je verbonden met het internet? Het kan zijn dat wij momenteel onderhoud plegen.',
      },

      error: {
        title: 'Er is iets misgegaan',
        body: 'Deze fout is onverwacht, maar we zijn op de hoogte gesteld. Je kan het opnieuw proberen door deze app helemaal af te sluiten. Als het probleem zich blijft voordoen, blijft het ook zichtbaar in onze notificaties.',
      },
    },
  },
});

export default function App() {
  return (
    <RootErrorBoundary>
      <Root />
    </RootErrorBoundary>
  );
}

type RootErrorBoundaryState = {
  error?: Error;
};

class RootErrorBoundary extends Component<
  { children: React.ReactNode },
  RootErrorBoundaryState
> {
  static getDerivedStateFromError(
    error: Error
  ): Partial<RootErrorBoundaryState> {
    return {
      error,
    };
  }

  constructor(props: { children: React.ReactNode }) {
    super(props);

    this.state = {
      error: undefined,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    Sentry.captureException(error, {
      level: 'error',
      extra: {
        componentStack: errorInfo.componentStack,
      },
    });
  }

  render() {
    if (this.state.error) {
      return <ErrorPanel error={this.state.error} />;
    }

    return this.props.children;
  }
}

function ErrorPanel({
  title,
  body,
  error,
}: {
  title?: string;
  body?: string;
  error: unknown;
}) {
  const message =
    typeof error === 'object'
      ? error === null
        ? 'No information on the error'
        : error instanceof Error
        ? `${error.name}: ${error.message}`
        : 'message' in error
        ? (error.message as string)
        : 'No information on the error'
      : String(error);

  return (
    <SafeAreaProvider>
      <StatusBar translucent style="dark" backgroundColor="#FFFFFF00" />
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          width: '100%',
          backgroundColor: '#222222',
        }}
      >
        <Title
          style={{
            textAlign: 'center',
            paddingHorizontal: 16,
            marginHorizontal: 'auto',
            color: '#FFFFFF',
            display: 'flex',
          }}
        >
          {title ?? i18n.t('app.error.title')}
        </Title>
        <Text
          style={{
            textAlign: 'center',
            paddingHorizontal: 16,
            marginHorizontal: 'auto',
            color: '#FFFFFFAA',
            marginTop: 12,
            maxWidth: 600,
            display: 'flex',
          }}
        >
          {body ?? i18n.t('app.error.body')}
        </Text>
        <Text
          style={{
            color: '#FFFFFFAA',
            textAlign: 'center',
            marginTop: 24,
            minHeight: 200,
            paddingHorizontal: 16,
            marginHorizontal: 'auto',
            maxWidth: 400,
            display: 'flex',
          }}
        >
          {humanizeError(message)}
        </Text>
      </View>
    </SafeAreaProvider>
  );
}

function humanizeError(message: string): string {
  if (message.includes('Failed to fetch')) {
    return i18n.t('app.errors.connect');
  }

  return message;
}
