import { i18n, PrimaryButton, useLocale } from '@introcloud/blocks';
import { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import {
  ActivityIndicator,
  Avatar,
  List,
  Surface,
  useTheme,
} from 'react-native-paper';
import { useHasContrastOnLight } from 'use-color-luminance';
import { Permit, permitKey, suspend } from '../core/Authentication';
import { MULTI_COMPANY_ENABLED } from '../features';
import {
  AuthenticationPortal,
  useAuthentication,
} from '../hooks/useAuthentication';
import { useRemoteCompany } from '../hooks/useCompany';
import { useUser } from '../hooks/useUser';

export function ProfileSessions() {
  useLocale();

  const { available, login, resolve, authentication } = useAuthentication();
  const theme = useTheme();

  if (!MULTI_COMPANY_ENABLED) {
    return null;
  }

  return (
    <View style={{ width: '100%', position: 'relative', paddingVertical: 12 }}>
      <List.Subheader>{i18n.t('app.profile.switcher.title')}</List.Subheader>
      <Surface
        style={{
          elevation: 1,
          borderRadius: theme.roundness,
        }}
      >
        {available.map((key) => {
          return (
            <ResolveAvailableSessionItem
              key={key}
              resolveKey={permitKey(key)}
              resolve={resolve}
              login={login}
              current={
                permitKey(authentication?.domainFull || '') === permitKey(key)
              }
            />
          );
        })}
      </Surface>
      <PrimaryButton
        onPress={() => suspend(undefined)}
        style={{
          alignSelf: 'flex-start',
          position: 'absolute',
          right: 8,
          top: 16,
        }}
      >
        {i18n.t('app.actions.sessions_add')}
      </PrimaryButton>
    </View>
  );
}

function ResolveAvailableSessionItem({
  resolveKey,
  resolve,
  login,
  current,
}: {
  resolveKey: string;
  resolve(key: string): Promise<Permit | null>;
  login(permit: Permit): Promise<unknown>;
  current: boolean;
}) {
  const [resolved, setResolved] = useState<Permit | undefined | null>();

  useEffect(() => {
    let mounted = true;
    resolve(resolveKey).then((resolved) => {
      if (!mounted) {
        return;
      }

      setResolved(resolved);
    });

    return () => {
      mounted = false;
    };
  }, [resolveKey]);

  if (!resolved) {
    return null;
  }

  return (
    <AuthenticationPortal key={resolveKey} permit={resolved}>
      <ActiveSessionItem
        key={resolveKey}
        {...resolved}
        current={current}
        set={() => login(resolved)}
      />
    </AuthenticationPortal>
  );
}

function ActiveSessionItem({
  current,
  set,
  ...permit
}: { current: boolean; set(): void } & Permit) {
  const { data: company, isLoading } = useRemoteCompany(permit.domainFull);
  const { data: user } = useUser();

  const initials = (company?.name.full?.split(' ') || [])
    .slice(0, 2)
    .map((l) => (l || '')[0])
    .join('');

  const logo = company?.image?.profile
    ? [
        permit.domainFull,
        'api',
        'image',
        company?.image?.profile,
        'icon_64',
      ].join('/')
    : null;

  const {
    colors: { primary },
  } = useTheme();
  const textColor = useHasContrastOnLight(primary) ? '#fff' : '#121212';

  const renderIllustration = useCallback(
    () =>
      logo ? (
        <Avatar.Image
          size={40}
          style={{
            marginLeft: 8,
            marginRight: 16,
            marginTop: 8,
            marginBottom: 4,
            backgroundColor: 'transparent',
          }}
          source={{ uri: logo, width: 40, height: 40 }}
        />
      ) : (
        <Avatar.Text
          color={textColor}
          style={{
            marginLeft: 8,
            marginRight: 16,
            marginTop: 8,
            marginBottom: 4,
          }}
          size={40}
          label={initials}
        />
      ),
    [logo, initials, textColor]
  );

  return (
    <List.Item
      title={company?.name.full}
      description={user?.email.value}
      onPress={current ? undefined : set}
      left={
        isLoading
          ? () => (
              <ActivityIndicator
                style={{
                  marginLeft: 8,
                  marginRight: 16,
                  marginTop: 8,
                  marginBottom: 4,
                  width: 40,
                  height: 40,
                }}
              />
            )
          : renderIllustration
      }
      right={current ? () => <List.Icon icon="check" /> : undefined}
    />
  );
}
