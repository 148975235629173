import {
  TactileEvent,
  TactileLocation,
  TactilePage,
} from '@introcloud/api-client';
import { useBlockData } from '@introcloud/blocks-interface';
import { PageData } from '@introcloud/page';
import { FetchMediaError } from 'fetch-media';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { EVENT_CACHE, LOCATION_CACHE, PAGE_CACHE } from '../core/Cache';
import { useGroupsDetached } from './useGroup';
import { usePage } from './usePage';
import { useUser } from './useUser';

export type ProvidedPageData = {
  data: PageData;
  loading: boolean;
  error: Error | undefined;
};

export function useProvidePageData(
  pageId: string | null,
  { enabled = true }: { enabled?: boolean } = {}
): ProvidedPageData {
  const { data: user } = useUser({ enabled });
  const { data: groups } = useGroupsDetached({ enabled });

  const { getInfoById, getEventById, getLocationById } = useBlockData();

  const { page, error: pageError } = usePage(pageId, getInfoById, {
    enabled,
    onSuccess: (page) => {
      if (page) {
        PAGE_CACHE.set(page._id!, page);
      }
    },
  });

  const eventId = page?.eventRef?.[0]?.eventId;
  const locationId = page?.locationRef?.[0]?.locationId;

  const { data: event } = useQuery<TactileEvent | null, FetchMediaError>(
    ['application', 'event', eventId],
    ({ signal }) => getEventById(eventId!, { signal }),
    {
      onSuccess: (event) => {
        if (event) {
          EVENT_CACHE.current[event._id] = event;
        }
      },

      placeholderData: eventId ? EVENT_CACHE.current[eventId] : null,
      enabled: enabled && !!eventId,
      staleTime: 30 * 1000,
    }
  );

  const { data: location } = useQuery<TactileLocation | null, FetchMediaError>(
    ['application', 'location', locationId],
    ({ signal }) => getLocationById(locationId!, { signal }),
    {
      onSuccess: (location) => {
        if (location) {
          LOCATION_CACHE.current[location._id] = location;
        }
      },

      placeholderData: locationId ? LOCATION_CACHE.current[locationId] : null,
      enabled: enabled && !!locationId,
      staleTime: 30 * 1000,
    }
  );

  const data = useMemo((): PageData => {
    return {
      page: (page as TactilePage) || null, // TODO loading/error state
      pageEvent: event || null,
      pageLocation: location || null,
      currentUser: user || null,
      currentGroups: groups || null,
    };
  }, [page, event, location, user, groups]);

  const loading = !page && !pageError;

  return useMemo(() => {
    return {
      data,
      loading,
      error: pageError || undefined,
    };
  }, [data, loading, pageError]);
}
