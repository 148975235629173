import {
  fetchApplicationSponsors,
  TactileSponsor,
} from '@introcloud/api-client';
import { useIsFocused } from '@react-navigation/core';
import { FetchMediaError } from 'fetch-media';
import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useIsMounted } from 'use-is-mounted';
import { NotReady } from '../core/errors/NotReady';
import {
  AnyMemoryValue,
  StoredMemoryValue,
  useMemoryValue,
  useMutableMemoryValue,
} from '../storage';
import { SHOULD_DEBUG_FETCH } from '../utils';
import { useEndpoint, useSafeAuthorization } from './useAuthentication';

export const SPONSORS: AnyMemoryValue<
  readonly Omit<TactileSponsor, 'pageRef'>[] | null
> = new StoredMemoryValue<readonly Omit<TactileSponsor, 'pageRef'>[]>(
  'application.sponsors.v1'
);

export const SPONSOR_RANDOMIZER: StoredMemoryValue<Record<
  string,
  number
> | null> = new StoredMemoryValue('application.sponsors.randomizer.v1');

export function useSponsors({ enabled = true }: { enabled?: boolean } = {}) {
  const isFocused = useIsFocused();
  const authorization = useSafeAuthorization();
  const endpoint = useEndpoint();
  const isMounted = useIsMounted();
  const [storedSponsors, setStoredSponsors] = useMutableMemoryValue(SPONSORS);

  const fetcher = useCallback(
    async ({ signal }: { signal?: AbortSignal }) => {
      if (!authorization || !endpoint) {
        throw new NotReady();
      }

      const result = await fetchApplicationSponsors(
        endpoint,
        authorization!,
        signal,
        SHOULD_DEBUG_FETCH
      );

      isMounted.current && setStoredSponsors(result);

      return result;
    },
    [authorization, endpoint, setStoredSponsors]
  );

  const {
    data: sponsors,
    error,
    ...others
  } = useQuery<readonly TactileSponsor[] | null, FetchMediaError | NotReady>(
    [endpoint, 'application', 'sponsor'],
    fetcher,
    {
      placeholderData: storedSponsors as readonly TactileSponsor[],
      enabled: enabled && isFocused && !!(authorization && endpoint),
      staleTime: 5 * 60 * 1000,
    }
  );

  return {
    data: sponsors,
    error,
    reload: others.refetch,
    loading: others.isLoading,
    refreshing: others.isFetching && !others.isLoading,
    ...others,
  };
}

export function useStoredSponsors() {
  return useMemoryValue(SPONSORS);
}
