import { TactileContentBlock } from '@introcloud/api-client';
import { useLocalization } from '@introcloud/blocks';
import {
  BlockOptions,
  BlockProps,
  ErrorHandlerProvider,
  PureBlock,
  usePageData,
} from '@introcloud/page';
import { ErrorHandler } from '@introcloud/page/dist/ErrorHandler';
import { useIsFocused } from '@react-navigation/native';
import React, { ErrorInfo, useCallback, useMemo, useRef } from 'react';
import { FlatList, ListRenderItemInfo, Platform, View } from 'react-native';
import { v4 as generateUUID } from 'uuid';
import { FallbackRoute, Header, ValidRoutes } from '../core/Header';
import { GameMapBlock } from '../gamemap/GameMapBlock';
import { Contacts } from '../home/Contacts';
import { LatestNewsItem } from '../home/LatestNewsItem';
import { NotificationsConsent } from '../home/Notifications';
import { PeekEvents } from '../home/PeekEvents';
import { BalanceBlock } from '../payment/BalanceBlock';
import { Sentry } from '../sentry';
import { SHOULD_ALLOW_DEBUG } from '../utils';
import { CompanySwitcherBlock } from './CompanySwitcherBlock';
import { PureCustomBlock } from './CustomBlock';
import { EmptyBlock } from './EmptyBlock';
import { ErrorBlock } from './ErrorBlock';
import { FakeVideoEmbedBlock } from './FakeVideoEmbedBlock';
import { YouTubeVideoEmbedBlock } from './YouTubeVideoEmbedBlock';

export interface PageViewerProps<T extends ValidRoutes> {
  hideBack?: boolean;
  hadToLoad?: boolean;
  fallBack?: FallbackRoute<T>;
  colorOverride?: string;
  showLogo?: boolean;
}

export function PageViewer<T extends ValidRoutes>({
  hideBack,
  hadToLoad,
  fallBack,
  colorOverride,
  children,
  showLogo,
}: React.PropsWithChildren<PageViewerProps<T>>) {
  const { page } = usePageData();
  const focussed = useIsFocused();

  // const dataProvider = useProvideBlockData();

  // TODO: split grids and lists into individual parts that can be rendered by
  // flatlist more efficiently
  const blocks = useMemo(() => validateBlocks(page?.content || null), [page]);
  const hasTranslation = true;

  console.log({ blocks });

  const renderItem = useCallback(
    ({ item, index }: ListRenderItemInfo<(typeof blocks)[number]>) => {
      const { id, ...block } = item;
      const self = blocks;

      switch (block.kind) {
        case 'balance': {
          return (
            <PureBlock {...block} id={id}>
              {() => <BalanceBlock key={id} id={id} {...block} />}
            </PureBlock>
          );
        }

        case 'custom': {
          return (
            <PureCustomBlock
              key={id}
              id={id}
              {...block}
              next={self[index + 1]}
              previous={self[index - 1]}
            />
          );
        }

        case 'events-relevant': {
          return (
            <PureBlock {...block} id={id}>
              {() => <PeekEvents key={id} focussed={focussed} />}
            </PureBlock>
          );
        }

        case 'news': {
          return (
            <PureBlock {...block} id={id}>
              {() => <LatestNewsItem key={id} />}
            </PureBlock>
          );
        }

        case 'game-map': {
          return (
            <PureBlock {...block} id={id}>
              {() => <GameMapBlock key={id} id={id} {...block} />}
            </PureBlock>
          );
        }

        case 'image': {
          return (
            <View key={id} style={{}}>
              <PureBlock
                id={id}
                {...block}
                next={self[index + 1]}
                previous={self[index - 1]}
                {...({ focussed } as object)}
              />
            </View>
          );
        }

        case 'push-notifications': {
          return (
            <PureBlock {...block} id={id}>
              {() => <NotificationsConsent key={id} />}
            </PureBlock>
          );
        }

        case 'quick-contacts': {
          return (
            <PureBlock {...block} id={id}>
              {() => <Contacts key={id} />}
            </PureBlock>
          );
        }

        case 'switcher': {
          return (
            <PureBlock {...block} id={id}>
              {() => <CompanySwitcherBlock key={id} id={id} {...block} />}
            </PureBlock>
          );
        }

        case 'video': {
          if (block.value.src.includes('youtube') && Platform.OS !== 'web') {
            return (
              <PureBlock {...block} id={id}>
                {() => (
                  <YouTubeVideoEmbedBlock
                    key={id}
                    id={id}
                    {...block}
                    next={self[index + 1]}
                    previous={self[index - 1]}
                  />
                )}
              </PureBlock>
            );
          }

          if (
            Platform.OS !== 'web' &&
            ['youtube', 'twitch', 'vimeo', '.mp4'].some((part) =>
              block.value.src.includes(part)
            ) &&
            !block.options.forceInline
          ) {
            return (
              <PureBlock {...block} id={id}>
                {() => (
                  <FakeVideoEmbedBlock
                    key={id}
                    id={id}
                    {...block}
                    next={self[index + 1]}
                    previous={self[index - 1]}
                  />
                )}
              </PureBlock>
            );
          }

          return (
            <PureBlock
              key={id}
              id={id}
              {...block}
              next={self[index + 1]}
              previous={self[index - 1]}
              {...({ focussed } as object)}
            />
          );
        }

        default: {
          return (
            <PureBlock
              key={id}
              id={id}
              {...block}
              next={self[index + 1]}
              previous={self[index - 1]}
              {...({ focussed } as object)}
              {...(block.kind === 'text' ? { hideTranslatable: true } : {})}
            />
          );
        }
      }
    },
    [blocks, focussed]
  );

  const scrollView = useRef(null);
  const finalTitle = useLocalization(
    page?.nameLocalized?.full,
    page?.name.full
  );
  const finalDescription =
    useLocalization(page?.nameLocalized?.description, page?.name.description) ||
    page?.subTitle ||
    '';

  return (
    <ErrorHandlerProvider value={useProvideErrorHandler()}>
      <View
        style={{
          flex: 1,
          overflow: 'hidden',
          maxHeight: Platform.select({ web: '100vh', default: '100%' }),
        }}
      >
        <Header
          title={finalTitle || ' '}
          subTitle={finalDescription}
          hideBack={hideBack}
          showTranslate={hasTranslation}
          backFallback={fallBack}
          colorOverride={colorOverride}
          showLogo={showLogo}
        >
          {children}
        </Header>

        <FlatList
          ref={scrollView}
          pinchGestureEnabled={false}
          scrollEventThrottle={16}
          renderItem={renderItem}
          data={blocks}
          nativeID="scroller"
          style={{ flex: 1, maxHeight: '100%' }}
          contentContainerStyle={{
            maxWidth: 720,
            alignSelf: 'center',
            paddingBottom: 56,
            width: '100%',
            overflow: 'visible',
          }}
        />
      </View>
    </ErrorHandlerProvider>
  );
}

/*
<ScrollView
  nativeID="scroller"
  style={{ flex: 1, maxHeight: '100%' }}
  contentContainerStyle={{
    maxWidth: 720,
    alignSelf: 'center',
    paddingBottom: 56,
    width: '100%',
    overflow: 'visible',
  }}
>
  {blocks.map(({ id, ...block }, index, self) => {
    if (block.kind === 'custom') {
      return (
        <PureCustomBlock
          key={id}
          id={id}
          {...block}
          next={self[index + 1]}
          previous={self[index - 1]}
        />
      );
    }

    if (
      block.kind === 'video' &&
      Platform.OS !== 'web' &&
      ['youtube', 'twitch', 'vimeo', '.mp4'].some((part) =>
        block.value.src.includes(part)
      )
    ) {
      return (
        <VideoEmbedBlock
          key={id}
          id={id}
          {...block}
          next={self[index + 1]}
          previous={self[index - 1]}
        />
      );
    }

    return (
      <PureBlock
        key={id}
        id={id}
        {...block}
        next={self[index + 1]}
        previous={self[index - 1]}
        {...(block.kind === 'text' ? { hideTranslatable: true } : {})}
      />
    );
  })}
</ScrollView>
*/

function useProvideErrorHandler(): ErrorHandler {
  const reportError: ErrorHandler['reportError'] = useCallback(
    (block: BlockOptions, error: Error, errorInfo: ErrorInfo) => {
      return new Promise((resolve) =>
        Sentry.withScope((scope) => {
          scope.clear();
          scope.setExtras({ errorInfo, block });
          scope.setTag('block', block.kind);
          Sentry.captureException(error);
          resolve();
        })
      );
    },
    []
  );

  const renderEmpty = useCallback(
    (block: BlockOptions) =>
      SHOULD_ALLOW_DEBUG ? <EmptyBlock {...block} /> : null,
    []
  );

  const renderError = useCallback(
    (block: BlockOptions, error: Error) =>
      SHOULD_ALLOW_DEBUG ? <ErrorBlock {...block} error={error} /> : null,
    []
  );

  return {
    handleEmpty: true,
    handleError: true,
    renderEmpty,
    renderError,
    reportError,
  };
}

function validateBlocks(
  blocks: TactileContentBlock[] | null
): BlockProps<BlockOptions>[] {
  if (!blocks) {
    return [];
  }

  const ids: Record<string, boolean> = {};

  return blocks.map(({ _id, ...block }): BlockProps<BlockOptions> => {
    const id = ids[_id] ? generateUUID() : _id;
    ids[_id] = true;

    const validated = validateBlock(block);

    return {
      id,
      ...validated,
      value: validated.value || {},
      options: validated.options || {},
      marked: false,
      editing: false,
      dragging: false,
    } as BlockProps<BlockOptions>;
  });
}

function validateBlock(
  block: Omit<TactileContentBlock, '_id'>
): Omit<BlockOptions, 'id'> {
  return block as Omit<BlockOptions, 'id'>;
}
